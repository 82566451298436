import React, { Component } from 'react'
import ElementEditor from './ElementEditor'

class ModifierContainer extends Component {
    constructor(props, context) {
        super(props, context)

        this.id = this.props.e.selfIndex
        this.type = this.props.e.type
        this.app = this.props.app
        this.app.all_modifiers_closed = true
        this.app.modifier_transitions = true
        this.elementEditor = null
        this.state = {
            selected: false
        }

        this.toggleSelectModifier = (e) =>{
            if( e.target.className.includes('modifier_header')){
                this.deselectAllOtherModifiers()
                this.setState({selected: !this.state.selected})

                let self = this
                setTimeout(() => {
                    
                    self.allModifiersClosed()
                    self.forceUpdate()
                }, 100);
                return null
            }
        }

        this.deselectAllOtherModifiers = () =>{
            let mods = this.app.allModifiers
            for(let i=0; i<mods.length; i++){
                let mod = mods[i]
                if( mod ){
                    let is_self = mod.props.e.selfIndex === this.props.e.selfIndex
                    if( !is_self ){
                        if( mod.state.selected ){
                            mod.setState({selected: false})
                        }
                    }
                }
            }
            this.setState({selected: !this.state.selected})
        }

        this.isLastModifier = () =>{
            let sidx = this.props.e.selfIndex
            let mlen = this.app.allModifiers.length-1
            let is_last = mlen === sidx
            return is_last
        }

        this.allModifiersClosed = () =>{
            let closed = true
            let mods = this.app.allModifiers
            for(let i=0; i<mods.length; i++){
                let mod = mods[i]
                if( mod ){
                    if( mod.state.selected  ){
                        closed = false
                    }
                }
            }
            this.app.all_modifiers_closed = closed
            return closed
        }

        this.transitionsOff = ()=>{
            this.app.modifier_transitions = false
        }

        this.transitionsOn = ()=>{
            this.app.modifier_transitions = true
        }

        this.getAdjecentModifier = ( updown ) => {
            let adjacent_mod = false

            let i_start = this.props.e.selfIndex + updown
            let i_max = this.app.allModifiers.length
            let i_inc = updown
            
            for(let i=i_start; i>=0 && i<i_max; i+=i_inc){
                let mod = this.app.allModifiers[i]

                if( !adjacent_mod && Boolean(mod) ){
                    adjacent_mod = mod
                    i=1000
                }
            }
            return adjacent_mod
        }

        this.updateAllModifiers = () => {
            let i_start = 0
            let i_max = this.app.allModifiers.length
            let i_inc = 1
            
            for(let i=i_start; i<i_max; i+=i_inc){
                let mod = this.app.allModifiers[i]
                mod.forceUpdate()
            }
        }


        this.showPopupDeleteConfirm = ()=>{
            let self = this
            window.show_popup( 
                'Löschen bestätigen', 
                'Sind Sie sicher, dass Sie das ausgewähle Element löschen möchten?', 
                {
                    okay: {
                        name: 'Ja, Element löschen', 
                        callback: function(){
                            self.deleteElement()
                            console.log('löschen')
                        }
                    }, cancle: {
                        name: 'Nein, abbrechen', 
                        callback: function(){
                            console.log('abbruch')
                        }
                    }
                }
            )
        }


        this.minimizeElement = () =>{
            // höhe des elements zwecks animation
            let mod_container = document.getElementById( this.id )
            let box = mod_container.getBoundingClientRect()
            let h = box.height
            mod_container.style.height = h+'px'

            // animiere höhe -> 0px
            setTimeout(() => {
                mod_container.style.height = '0px'
                mod_container.style.borderTopWidth = '0px'
            }, 33) // im (über)nächsten frame

            return mod_container
        }


        this.deleteElement = () =>{
            // auswahl aufheben
            this.setState({selected: false})

            let elem = this.minimizeElement()
            

            let self = this
            // self.transitionsOff()
            setTimeout(() => {
                // eigener index
                let idx = self.props.e.selfIndex

                // self.transition = elem.style.transition
                
                self.app.deleteElement( idx )
                // elem.parentElement.remove()
                //elem.style.transition = 'none'
                elem.style.height = 'unset'
                elem.style.borderTopWidth = '4px'
                // elem.style.transition = self.transition

                //self.transitionsOn()

                self.app.forceUpdate() // wichtig zwecks re-render
                //self.updateAllModifiers()
            }, 800)

            
            /* setTimeout(() => {
                
                // self.updateAllModifiers()

                self.transitionsOn()
            }, 900) */
        }


        this.moveElementToIndex = ( offset ) =>{
            // eigener index
            let idx = this.props.e.selfIndex

            // oberstes element nicht  weiter hoch schieben
            if( (idx + offset) < 0 )
                return false
            
            this.transitionsOff()
            // nebenstehendes element
            let other = this.app.renderData.splice( idx + offset, 1 )[0]
            
            // entnommenes element wieder einsetzen
            this.app.renderData.splice( idx, 0, other )
            this.app.forceUpdate()

            // auswahl aufheben
            this.setState({selected: false})

            // nach verschiebung aushwählen
            let self = this
            setTimeout(() => {
                // nebenstehendes element finden
                let amod = self.getAdjecentModifier( offset )

                // nebenstehendes element auswählen
                amod.setState({selected: true})
                self.transitionsOn()
            }, 33)

            // scrollen
            setTimeout(() => {
                // element-box ermitteln
                let mod_container = document.getElementById( self.id + offset )
                let box = mod_container.getBoundingClientRect()

                let window_height =( window.innerHeight || document.documentElement.clientHeight )
                let over = box.top < box.height
                let under = box.top > ( window_height - window_height/4)

                // wenn verschobenes element außerhalb des viewports: scrollen
                if( over || under ){
                    let position = box.top + window.pageYOffset - window_height/3
                    window.scrollTo({ top: position, behavior: "smooth"})
                }
            }, 222)
        }

        this.moveUp = ()=>{
            this.moveElementToIndex(-1)
        }

        this.moveDown = ()=>{
            this.moveElementToIndex(1)
        }
    }

    componentDidMount(){
        console.log('ModifierContainer mount. this.id: %o this.type: %o', this.id , this.type )
        this.forceUpdate()  // ??
    }

    componentDidUpdate(){
        console.log('ModifierContainer updated. this.id: %o this.type: %o', this.id , this.type )
        this.id = this.props.e.selfIndex
    }

    render() {
        let e = this.props.e
        console.log('rendering e.type %o %o ...', e.selfIndex, e.type)
        let css_bg = this.state.selected ? { boxShadow: '0 0 12px 0 #00000057', borderColor: '#ff006f'} : {}
        let css_mh = this.state.selected ? { maxHeight: '14rem', paddingBottom: '.5rem', borderBottomColor: '#aaaaaa'} : {}

        let show_plus = this.isLastModifier() // && this.app.all_modifiers_closed
        let css_ad = this.state.selected || show_plus ? { height: '7rem'} : {}

        if( !this.app.modifier_transitions ){
            css_bg = Object.assign(css_bg, {transition: 'none'})
            css_mh = Object.assign(css_mh, {transition: 'none'})
            css_ad = Object.assign(css_ad, {transition: 'none'})
        }

        return <div>
                    <div id={ this.id } className='modifier_container' style={css_bg} ref={() => this.app.allModifiers[e.selfIndex] = this } >
                        <div className='modifier_header'>
                            <div className='modifier_header_name'>
                                Element { this.id }: { e.type } 
                            </div>
                            <div className='modifier_header_edit icomoon_general icomoon_pencil_blue' onClick={ (e) => this.toggleSelectModifier(e)}>
                                { /* this.state.selected? 'Fertig':'Bearbeiten' */}
                            </div>
                            <div className='modifier_header_edit icomoon_general icomoon_trash_blue' onClick={()=>this.showPopupDeleteConfirm()}>
                                { /* this.state.selected? 'Fertig':'Bearbeiten' */}
                            </div>
                        </div>
                        <div className='modifier_edit' style={css_mh}>
                            <div className='modifier_buttons_container' onClick={()=>this.moveUp()}>
                                <span>
                                    <span className='modifier_button_icon icomoon_general icomoon_move_up'>
                                    </span>
                                    <span className='modifier_button_name'>
                                        
                                    </span>
                                </span>
                            </div>
                            <div className='modifier_buttons_container' onClick={()=>this.moveDown()}>
                                <span>
                                    <span className='modifier_button_icon icomoon_general icomoon_move_down'>
                                    </span>
                                    <span className='modifier_button_name'>
                                        
                                    </span>
                                </span>
                            </div>
                            <div className='modifier_buttons_container' onClick={ ()=> this.elementEditor.open()}>
                                <span>
                                    <span className='modifier_button_icon icomoon_general icomoon_edit'>
                                    </span>
                                    <span className='modifier_button_name'>
                                        
                                    </span>
                                </span>
                            </div>
                            {/* <div className='modifier_buttons_container' onClick={()=>this.deleteElement()}>
                                <span>
                                    <span className='modifier_button_icon icomoon_general icomoon_delete'>
                                    </span>
                                    <span className='modifier_button_name'>
                                        Löschen
                                    </span>
                                </span>
                            </div> */}
                        </div>
                        <div className='element_container'>{ this.props.children }</div>
                    </div>
                    <div className='modifier_add_button' style={css_ad}>
                        <span className='modifier_button_icon_add icomoon_general icomoon_add'></span>
                    </div>
                    <ElementEditor parentModifier={this}/>
                </div>
    }
}

export default ModifierContainer


