

/**
 * Hilfsklasse für /exists-aufruf zum helper
 */
var server // setLoggingServer( srv )

export default class Exists {
    constructor(app) {
        this.app = app
    }

    /* 
        Exists und andere module sollen einen eigenen post-request ausführen können.
        der muss aber die richtigen option-felder enthalten, da es sonst höchstwahrscheinlich
        zu CORS problemen kommen wird, wie es der erfahrung nach sonst immer war.
        daher sollte der post in ein eigenes modul ausgelagert werden, als objekt oder als singleton,
        um von den jeweiligen komponenten geladen werden zu können.
    */
    static async post(path, data = {}) {
        var options = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow",
            referrer: "no-referrer"
        }
        
        let self = this.app
        return await new Promise(async (resolve) => {

            options.body = JSON.stringify( data )

            let post_repsonse = await fetch(self.helper + path, options)
            resolve(post_repsonse)
        })
    
    }

    static localStorage_setItem( name, value ){
        window.localStorage_setItem( name, value )
    }

    static localStorage_getItem( name ){
        return window.localStorage_getItem( name )
    }

    static setLoggingServer( srv ){
        server = srv
        window.exists = this
    }

    /**
     * fehlermeldung in konsole, popup, html-fehlerseite
     * @param {String} e - anzuzeigende fehlermeldung
     * @return {Boolean} boolean - false für aufrufende funktion
     */
    static error( e, code ){
        // server logging:
        // let server = window.server
        
        this.failed = true
        console.error(e, this.parsed)
        window.showError(e)
        this.app.blueErrorPage.display = true
        this.app.blueErrorPage.text = e
        this.app.blueErrorPage.ecode = code
        return false
    }

    /**
     * referenziert später benötigte App-instanz
     * @param {ReactComponent} app - App-instanz
     * @return {void}
     */
    static async context(app){
        this.app = app
    }


    /**
     * führt post request zum helper aus
     * @return {Boolean} boolean - true, falls erfolgreich
     */
    static async execute( app ){
        if( !app )
            return this.error('Missing app reference.', 'Exists.execute:50')

        this.app = app
        
        this.match_mode()
        
        // sotrage variable "must_refresh_exists_" wird in cdap/Startpage UND hier im Exists geprüft+gesetzt
        let refresh_checklist = this.localStorage_getItem( 'must_refresh_exists_' + this.app.pid )
            refresh_checklist = refresh_checklist /* !== undefined */ && refresh_checklist === 'true'

        let isCDAP = window.location.href.split('/').includes('cdap')
        let isSETUP = window.location.href.match(/idtoken=/)

        let stored_checklist = this.localStorage_getItem( 'checklist_' + this.app.pid )
        if( stored_checklist && !refresh_checklist && isCDAP && !isSETUP )
        {
            this.response = this.parsed = JSON.parse( stored_checklist )
            this.fromStorage = true
        }else
        {

            /* POST REQUEST */
            this.response = await this.app.post('/exists')

            this.localStorage_setItem( 'must_refresh_exists_' + this.app.pid, false )
        }
        this.executed = true

        await this.process()

        return true
    }


    /**
     * parst, validiert, extrahiert helper response body,
     * ermittel uqrc type der instanz (wd, ad, ai etc.)
     * @return {Boolean} boolean - true, falls erfolgreich
     */
    static async process(){
        if( !this.executed )
            return this.error('No response to process.', 'Exists.process:88')

        await this.parse() // parst response body zu json

        if( !this.validate()) // überprüft, ob response brauchbare daten enthält
            return false

        this.extractDataToApp() // übergibt benötigte daten an App-komponente
        this.app.updateUqrcType( null, 'Exists.process()') // ermittelt uqrc type der instanz (wd, ad, ai etc.)
        
        return true
    }


    /**
     * parst helper response zu json
     * @return {Boolean} boolean - true, falls erfolgreich
     */
    static async parse(){
        if( this.failed )
            return false
            
        if( !this.response )
            return this.error('No Response Present.', 'Exists.parse:111')

        try {
            if( !this.parsed ){
                this.parsed = await this.response.json()
            }
        } catch (error) {
            return this.error('Parse Error.', 'Exists.parse:117')
        }

        return true
    }


    /**
     * validiert helper reponse
     * @return {Boolean} boolean - true, falls erfolgreich
     */
    static validate(){
        if( !this.parsed ){
            return this.error('No parsed data.', 'Exists.validate:139')
        }

        this.failed = this.parsed.code === 500 || this.parsed.code === 404 || this.parsed.error
        if( this.failed ){
            this.app.exists_failed = this.parsed
            let err  = this.get_error_message( this.parsed )
            this.app.set_error( err )

            if( this.parsed.error && this.parsed.error.includes('Json parse error:')){
                return this.error( 'File Authentication Error.', 'Exists.validate:183' )
            }else{
                return this.error( err.full || this.parsed.error || 'Network Error.', 'Exists.validate:185' )
            }
        }

        if( !this.app.type.AI ){
            if( this.parsed && this.parsed.permid === null ){
                return this.error('Missing Authorization. ' + JSON.stringify(this.parsed), 'Exists.validate:142')
            }
        }

        if( this.parsed && this.parsed.response && this.parsed.response.success ){
            this.success = true
        }

        return true
    }


    /**
     * extrahiert benötigte felder aus response und
     * übergibt sie an die App-komponente:
     * (renderData, documents, permid, mode, title etc.)
     * @return {Boolean} - true, falls erfolgreich
     */    
    static extractDataToApp(){
        if(!this.success){
            const AI = this.app.type.AI
            if( this.parsed && ( this.parsed.renderData || AI) ){
                // this.app.mode = this.match_mode()
                /* return  */this.app.permid.PARENT = this.parsed.permid // return entfällt, weil kein exists ohne daten mehr zurückkommt
                this.app.uuid = this.parsed.uuid 
            }else
                return this.error('Missing Data. ' + JSON.stringify(this.parsed), 'Exists.extract:164')
        }

        let { last_update, timestamp } = this.parsed
        this.localStorage_setItem('last_update_' + this.app.pid, JSON.stringify({ last_update, timestamp }))
        this.localStorage_setItem('checklist_' + this.app.pid, JSON.stringify( this.parsed ) )

        this.app.renderData = this.parsed.renderData // checklisten json und andere varianten der view für das rendering
        this.app.save_checkbox_value_to_renderdata( this.app.cookiesAllowed )

        this.app.source = this.parsed.source // checklisten json und andere varianten der view für das rendering
        this.app.messages = this.parsed.messages // checklisten json und andere varianten der view für das rendering
        this.app.exists_documents = this.parsed.documents // zum objekt dazugehörige dokumente im edp
        this.app.displayTitle = this.parsed.title // titel des objekts
        this.app.last_update = this.parsed.last_update // letzte control-DATEI-aktualisierung (nicht des ordners)
        this.app.uuid = this.parsed.uuid 

        this.app.folder = { root: ''}
        if( this.parsed.childpermid ){
            let pid = this.parsed.childpermid
            this.app.folder.root = pid
            this.app.folder[ pid ] = {
                children  : this.parsed.children,
                documents : this.parsed.documents,
                permid    : this.parsed.childpermid,
                title     : this.parsed.title,
                last_update : undefined // das lu des ordners (wird vom objectinfo noch nicht mitgeschickt)
            }
        }

        this.headerid = (this.app.getRenderDataElement('header', 'id') || '').toLocaleLowerCase() // header id aus renderData json
        this.app.favicon = this.app.getRenderDataElement('header', 'favicon') // favicon aus renderData json
        this.app.title = this.app.getRenderDataElement('header', 'title') // favicon aus renderData json
        this.app.permid = this.getPermIDs() // zugehörige edp permids
        this.app.mode = this.match_mode() // ermitteln des view modes (intro, setup, checklist etc.)
        this.app.copyModeToGlobalVars() // mode auf globale variablen von App komponente übertragen (zwecks einfacherer syntax)

        /* 090322,nn: fügt dokumente des objekts ins json ein, wenn der 'files'-type in json vorhanden ist. 
        vorsicht, da im (bi) selector sonst die files des objekts auftauchen können! */
        // style=serviceSelector
        let style = this.app.getRenderDataElement('header', 'style')
        if( style && !style.toLocaleLowerCase().includes('selector') ){
            this.mergeDocumentsIntoRenderData() // verfügbare edp dokumente in renderData injecten (mergen)
        }

        return true
    }


    /**
     * ermittelt den view mode von uqrc bzw der App komponente
     * anhand von url, jwt und cookies VOR dem exists
     * @return {JSON/Object} this.mode - json objekt mit zutreffenden modes als TRUE
     */
    static match_mode_preexist(){
        let cignore = window.getURLParameter('fromcookie')  === 'ignore'
        let url_mode = window.getURLParameter('mode')

        return {
            tok     : this.app.jwt.alive(),
            cl      : url_mode && url_mode.includes('checklist'),
            constr  : window.getURLParameter('construct')   === 'true',
            estp    : window.getURLParameter('edit')        === 'setup',
            eclst   : window.getURLParameter('edit')        === 'checklist',
            dprv    : window.getURLParameter('display')     === 'preview',
            PREREG  : window.getURLParameter('show')        === 'preregistration' ,
            CDAP    : window.getTemplateName()              === 'cdap',
            DHC     : window.getTemplateName()              === 'dhc',
            intro   : window.getTemplateName().match(/intro/),
            cignore : cignore,
            lgn     : cignore ? '' : this.app.getCookie('login_' + this.app.pid, 'EXISTS' ),
            pstp    : window.location.pathname.includes('setup')
        }
    }


    /**
     * ermittelt den view mode von uqrc bzw der App komponente
     * anhand von url, jwt und cookies VOR dem exists
     * @return {JSON/Object} this.mode - json objekt mit zutreffenden modes als TRUE
     */
    static match_mode_postexists(){
        let has_protocol = Boolean(this.parsed && this.parsed.protocol)
        let has_pid = Boolean(this.parsed && this.parsed.permid)
        let doc = Boolean(this.parsed && this.parsed.documents)
        let rd = Boolean(this.parsed && this.parsed.renderData)
        let su = Boolean(this.parsed && this.parsed.setup === true )
        let einlk = this.app.getRenderDataElement('header', 'cdapmode') === "einlasskontrolle"

        return { has_protocol, has_pid, doc, rd, su, einlk }
    }


    /**
     * ermittelt den view mode von uqrc bzw der App komponente
     * anhand von url, json, jwt und cookies VOR und NACH dem exists
     * @return {JSON/Object} this.mode - json objekt mit zutreffenden modes als TRUE
     */
    static match_mode( app ){
        if( app )
            this.app = app

        if( this.failed )
            return false
            
        if( !this.app )
            return this.error('Cannot match app mode.', 'Exists.match:240')


        /* 
            für diese booleans ist der exists nötig
        */
        let { 
            has_protocol,   /* this.parsed.protocol         */
            has_pid,        /* this.parsed.permid           */
            doc,            /* this.parsed.documents        */
            rd,             /* this.parsed.renderData       */
            su,             /* this.parsed.setup === true   */
            einlk           /* cdapmode: einlasskontrolle   */
        } = this.match_mode_postexists()

        /* 
            für diese booleans ist kein exists nötig,
            sie können aus cookies und url parametern
            ermittelt werden
         */
        let { 
            tok,    /* token                            */
            cl,     /* mode=checklist                   */
            constr, /* construct=true                   */
            estp,   /* edit=setup                       */
            eclst,   /* edit=checklist                  */
            dprv,   /* display=preview                  */
            pstp,   /* pathname includes /setup/        */
            lgn,    /* cookie: login_qrid               */
            PREREG, /* show=preregistration             */
            CDAP,   /* pathname includes /cdap/         */
            DHC,    /* pathname includes /dhc/          */
            intro   /* pathname includes /...intro.../  */
        } = this.match_mode_preexist()

        const SETUP = (su && tok && !cl && !dprv) || pstp || estp 
        
        this.mode = {
            /* 
                diese modes können nur NACH einem exists ermittelt werden:
            */
            // ------>| "entweder kein token, oder wenn PREREG bei CDAP/DHC, dann ist auch mit token erlaubt"
            //VIEW: rd && (!tok || (PREREG && (CDAP || DHC))) && !cl && !dprv, (290421 nur für dhc getestet)
            VIEW: rd && (!tok || (!PREREG && !SETUP && (CDAP || DHC))) && !cl && !dprv,
            EDIT: (!su && rd && tok && !cl && !dprv) || (!su && rd && tok && !cl && !dprv && estp),
            RENDER_DATA_IN_EXISTS: rd,
            SHOW_DOCUMENTS: doc && !estp,
            NO_RIGHTS : !dprv && (this.parsed && this.parsed.permid === null),
            EXISTS : has_pid,
            PROTOCOL : has_protocol,
            EINLASSKONTROLLE: einlk,
            // ----------------------------->| "entweder kein token, oder wenn PREREG bei CDAP/DHC, dann ist mit token in ordnung"
            // MENU : has_pid && !estp && !dprv && (!tok || (PREREG && (CDAP || DHC))) && !pstp && !cl, (290421 nur für dhc getestet) // AI menu fix
            MENU : has_pid && !estp && !dprv && (!tok || (!PREREG )) && !pstp && !cl, // AI menu fix

            
            /* 
                diese modes können auch VOR dem exists ermittelt werden:
            */
            SETUP: SETUP,
            INTRO: (!rd && !tok && !cl && !dprv) || intro || false,
            CONSTRUCT: constr,

            /* 
                diese modes können ganz OHNE exists ermittelt werden:
            */
            EDIT_SETUP: estp,
            CHECKLIST: cl, // vor 16-02-2021: rd && !tok && cl && !dprv,
            PREREG: PREREG,
            SETUP_SUCCESS: dprv,
            LOGGED_IN: Boolean(lgn),
            LOADING: false
        }

        if (DHC)
        {
            this.mode.MENU = has_pid && !estp && !dprv && ( tok || !PREREG ) && !pstp && !cl
            this.mode.VIEW = rd && ( tok || (!PREREG && !SETUP && (CDAP || DHC))) && !cl && !dprv
            this.mode.EDIT = this.mode.EDIT || eclst /* edit=checklist */

            console.log('DHC mode EDIT: %o', this.mode.EDIT)

            if( SETUP && tok )
                this.mode.VIEW = false
            //console.log({has_pid , estp , dprv , tok , PREREG  , pstp , cl })
        }


        this.app.mode = this.mode
        this.app.copyModeToGlobalVars()
        return this.mode
    }


    /**
     * ermittelt permids des edp objekts
     * (child, parend, tocument, protocol etc.)
     * @return {JSON/Object} this.permIDs - json objekt mit zugehörigen object-ids
     */
    static getPermIDs() {
        if( this.failed )
            return false
            
        if(/* !this.success || */ !this.parsed )
            return this.error('Missing valid data.', 'Exists.pid:342')

        let e = this.parsed

        let parent, child

        if (e.success)
            try {
                parent = e.response.success.object[0]._
                child = e.response.success.object[0]._
            } catch (err) { }

        if (e.response && e.response.data)
            parent = e.response.data.permId

        // brute force/safety für ggf. falsch im resolver konfigurierte Etiketten (a-ident change 25.10.2019)
        if (this.app.template === 'ai') {
            parent = 'dBL3MZWMub'
        }

        if (!e.permid) {
            if (this.app.noChangeRightsRef) this.app.noChangeRightsRef.show() // TODO
        }
	    if (this.app.forceParentID) 
	    { 
		    parent=this.app.forceParentID 
		    e.permid=this.app.forceParentID
        }
        
        
        this.permIDs = {
            CHILD: child || e.childpermid,
            PARENT: parent || e.permid,
            DOCUMENT: e.permid,
            CHECKLIST: e.checkliste,
            PROTOCOL: e.protocol
        }
        
        return this.permIDs
    }


    /**
     * fügt dokumente in renderData ein, 
     * damit sie in der view gerendert werden
     * @return {Boolean} boolean - true, falls erfolgreich
     */
    static mergeDocumentsIntoRenderData(){
        if( this.failed )
            return false
            
        if( !this.app )
            return this.error('Cannot merge documents.', 'Exists.merge:394')

        let app = this.app

        if( app.mode.SETUP )
            return null

        if( !app.exists_documents )
            return null

        if( !app.renderData )
            return null

        let insert_index = app.getElementPosition(null, 'insert_documents')

        let no_insert_pointer = insert_index === app.renderData.length
        if( no_insert_pointer )
            return null
        
        // INSERT
        let doc_index = 0
        app.exists_documents.map((doc, i)=>{
            if( !doc )
                return null

            if( !doc.title || !doc.filename )
                return null
                
            if( doc.title.includes('$') || !doc.filename.includes('.pdf') )
                return null
                
            let index = doc_index + insert_index
            let element = {
                type: 'document',
                file: doc.perm_id,
                name: doc.title,
                filename: doc.filename,
            }

            app.renderData.insert(index, element)
            doc_index++    
            
            return null
        })

        return true
    }


    /**
     * sucht nach feld im json/objekt
     * @return {JSON/Object/Number/String} value - je nach Datentyp des Elements 
     */
    static get_element_from_object( data, field ){
    
        let value = ''

        for(let f in data){
    
            if( field === f && !value){
                value = data[f]
                break
            }
    
            // rekursiv weitersuchen
            let is_object = typeof data[f] === 'object'
            if( is_object && !value ){
                value = this.get_element_from_object( data[f], field )
            }
        }

        return value
    }


    /**
     * extrahiert errors aus helper response
     * @return {String} json - string mit relevantem error log
     */
    static get_error_message( data ){
    
        let helper  = this.get_element_from_object( data, 'error'   )
        let failure = this.get_element_from_object( data, 'failure' )
        let task    = this.get_element_from_object( data, 'task'    )
        let source  = this.get_element_from_object( data, 'source'  )
        let ecode   = this.get_element_from_object( data, 'ecode'   )

        let full = ''
        let mws = ''

        if( source || task || failure )
        {
            full = mws = [ source , task , failure ].join(' ')

            if( helper )
            {
                if( helper.match(/\.$|\:$/) )
                    helper = helper.substr( 0, helper.length-1 )

                full = helper + ': ' + mws
            }
        }

        return { full, mws, helper, ecode }
    }
}