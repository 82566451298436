import React, { Component } from 'react'
import Video from './Video'
import Image from './Image'

import './css/Cover.css'

const SMALL = '4vh'
const MEDIUM = '40vh'

class Cover extends Component {
    constructor(props, context) {
        super(props, context)

        this.videoElement = null

        this.state = {
            height: null,
            minified: false
        }
    }

    componentDidMount() {
        this.registerForOrientationChange()
    }

    registerForOrientationChange() {
        window.orientationDependentElements['cover'] = this
    }

    orientationChange() {
        let maxed = this.props.ss.services.maximized()
        /* if ( window.isPortrait() && maxed)
            this.setHeight('4vh')
        else 
            this.setHeight(null)  */

        this.setState({minified: maxed})
    }

    setHeight(h) {
        let maxed = this.props.ss.services.maximized()
        this.setState({
            height: h,
            minified: maxed
        })
    }

    minimize(m) {
        let ve = this.videoElement
        if (ve)
            return m ? ve.maximize() && this.setHeight(MEDIUM) : ve.minimize() && this.setHeight(SMALL)
    }

    render() {
        let h = this.state.height
        h = h ? { maxHeight: h } : {}

        return (
            <div className={'Cover' + (this.state.minified ? ' minified' : '')} style={{}}>
                {this.props.lang === 'de' ? <Video ref={(c) => this.videoElement = c} /> : <Image />}
            </div>
        )
    }
}

export default Cover
