import React, { Component } from 'react'
import aifamily from './svg/1stad_logo_title_nn.png'
import './css/Startpage.css'
import AGB from '../../common/AGB'

class Startpage extends Component {
  render(){
    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          {//<img src={aiLogoConv} id="aiLogoConv" alt="aiLogoConv" />
          }
          <img src={aifamily} id="aifamily" alt="aifamily" />

          <p id='big'>
                <b>1stad</b> - einfach - sicher und immer dabei!
              </p>
              <p>
                Willkommen auf der Startseite von <b>1stad</b>.
              </p>
              <p>
                Mit <b>1stad</b> kann man ganz komfortabel Verbandsbucheinträge erstellen und pflegen.
              </p>
              <p>
                Eine gute Entscheidung für die persönliche Sicherheit, denn einfach ist besser.
              </p>
              

          {!this.props.menu ? 
            (<div>
              <AGB/>
              <div id='aigo' className='button'  onClick={() =>{
                let goto = window.getURLParameter('goto')
                window.location.href = goto ? goto : this.props.goto }
              } >{"Und los geht's"}</div>
            </div>)
            :
            this.props.menu
          }



          <p id='kontakt'>
            <a id="ailink" href="http://dig-sie.de/KONTAKT/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Kontakt
              </span>
            </a><br/>
            <a id="ailink" href="http://dig-sie.de/Impressum-und-AGB/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Impressum
              </span>
            </a>
          </p>



        </div>
      </div>
    )
  }
}

export default Startpage
