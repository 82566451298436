import React, { Component } from 'react'
import FolderName from './FolderName'
import Loader from './Loader_'
import File from './File'

const HIDE_EMPTY_FOLDER = true

class FileList extends Component {
    constructor(props, context) {
        super(props, context)

        this.app = this.props.app
        this.single_folder_display_mode = false // url
        
        this.init_meta()

        if( !this.auto_request ){
            this.init_list()
        }else{
            this.list = <Loader />
        }
    }
    

    init_meta(){

        this.is_root = this.props.is_root
        this.permid  = this.props.permid
        this.folder  = this.props.folder || {}
        this.path    = this.props.path

        let path_exists = this.folder && this.folder[ this.permid ]
        this.auto_request = (!path_exists && this.is_root) || this.single_folder_display_mode ? true : false

        if( this.is_root ){
            
            this.is_fullscreen = window.getURLParameter( 'docs' ) === 'fullscreen'
            this.fullscreen = this.is_fullscreen ? ' fullscreen' : ''
            this.inset = ' RootList'

            if( this.is_fullscreen ){
                this.section = null
            }else{
                this.section = null // <div className='FileSectionName'>{ this.props.e.name }</div>
            }
        }else{
            this.inset = ' Inset'
            this.fullscreen = ''
        }
    }


    sort( data = [], key = 'title' ){
        if( !data || !data.length || data.length > 100 )
            return data

        let sorted = []
        let keys = []

        for (let i = 0; i < data.length; i++) {
            const elem = data[i]

            if( elem ){
                if( elem[key] ){
                    keys.push(elem[key])
                }
            }
        }

        keys = keys.sort()

        for (let i = 0; i < keys.length; i++) {
            const wanted = keys[i]
            
            for (let j = 0; j < data.length; j++) {
                const elem = data[j]

                if( elem ){
                    if( elem[key] === wanted ){
                        sorted.push( elem )
                    }
                }
            }
        }

        return sorted
    }


    init_list(){

        this.list = []

        if( this.folder ){
            let folder_current = this.folder[ this.permid ]

            if( folder_current ){
                let { children, documents/* , code */ } = folder_current
                /* if( code === 401 ){
                    return this.list.push( <div>Unauthorized</div> )
                } */

                children = this.sort(children, 'title')
                documents = this.sort(documents, 'title')
        
                if( children ){
                    for (let i = 0; i < children.length; i++) {

                        const pid           = children[ i ].perm_id
                        this.folder[ pid ]  = children[ i ]
                        const empty         = children[ i ].downwards === 'false'

                        if( HIDE_EMPTY_FOLDER && empty ){
                            // dont push empty folder to list
                        }else{
                            this.list.push( <FileList key={i}  app={ this.app } folder={ this.folder } permid={ pid } empty={empty}/> )
                        }
                    }
                }

                if( documents ){
                    for (let i = 0; i < documents.length; i++) {
                        if( documents[ i ] )
                            this.list.push( <File key={ children.length + i} meta={ documents[ i ] } no_margin={ this.is_fullscreen && this.is_root } app={this.app}/> )
                    }
                }
            }
        }

        if( !this.list.length ){
            this.did_request = false
            this.closed_by_user = true
        }
    }

    should_request(){
        /* reuqest or not */
        if( !this.did_request ){
            let fdata = this.folder[ this.permid ]
            if( fdata ){
                return !fdata.documents || !fdata.children
            }else{
                return true
            }
        }
        return false
    }


    async request_folder(){
        let self = this
        this.auto_request = false

        /* reuest object info */
        await this.app.post('/folder', { folder: this.permid }).then(async function (response) {
            await response.text().then(
                /* async */ function (response) {
                    try {
                        let _json = JSON.parse(response)
                        self.folder[ self.permid ] = _json
                        self.did_request = true
                        self.closed_by_user = false
                    } catch (e) {
                        window.showError('FileList /folder: ' + e)
                    }
                }
            )
        })
        
        self.init_list()
        self.forceUpdate()
    }


    handle_click(){
        if( this.props.empty ){
            return false 
        }
        
        if( this.should_request() ){
            this.request_folder()
            this.list = <Loader />
            this.forceUpdate()
            return null
        }
    }


    render() {
        if( this.folder ){
            if( this.folder[ this.permid ] ){
                if( this.folder[ this.permid ].code === 404 ){
                    return (
                        <div className={'FileList' + this.inset + this.fullscreen } id={ this.permid }>
                            Could not find folder (permid <b>{this.permid}</b>)
                        </div>
                    )
                }
            }
        }

        if( this.auto_request ){

            this.request_folder()

            /* if( Object.keys(this.folder).length === 0 ){
                return (
                    <div className={'FileList' + this.inset + this.fullscreen } id={ this.permid }>
                        Loading folder (permid <b>{this.permid}</b>)
                    </div>
                )
            } */

            return <Loader />
        }else{
            // falls das folder-verhalten (ausblenden wenn leer) so bleiben soll, ist ein kleines refactoring der ifs nötig
            let css = this.props.empty ? { opacity: '0.25' } : {} // überflüssig, wenn leere ausgeblendet werden

            if( this.props.empty )
                return null
            else
                return (
                    <div className={'FileList' + this.inset + this.fullscreen } id={ this.permid } style={css} onClick={ () => this.handle_click() }>
                        { this.section }
                        <FolderName folder={ this.folder } filelist={this} app={this.app} permid={ this.permid } fullscreen={ this.is_fullscreen } root={this.is_root} empty={this.props.empty} toggle={()=>{
                            if( this.props.empty ){
                                return false
                            }

                            if( !this.closed_by_user ){
                                this.closed_by_user = true
                                this.did_request = false
                                this.list = null
                                this.forceUpdate()
                            }else{
                                this.request_folder()
                                this.list = <Loader />
                                this.forceUpdate()
                            }
                        }}/>
                        { this.list }
                    </div>
                )
        }
    }
}

export default FileList


