import React, { Component } from 'react'
import './css/AGB.css'


class AGB extends Component {
  render(){ 
     
    return (
      <div className='agbElement'>
        <p className="agbContainer" >
        Mit der Nutzung bestätigen Sie, dass Sie unsere <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_Igf2SQq0zE' id='datenschutz'  rel="noopener noreferrer" >Datenschutzerklärung</a> und <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_0JrTXgcudm' id='agb'  rel="noopener noreferrer" >Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben. 
        <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_43CfLRWtui'  rel="noopener noreferrer" > Impressum</a>

        <br/>
        <br/>

        <b>Hinweis zu Cookies:</b> Wir setzen Cookies. Wenn Sie nicht damit einverstanden sind, brechen Sie die Nutzung bitte ab. 
        </p>
      </div>
      
      
    )
  }
}

export default AGB
