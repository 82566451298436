import React, { Component } from 'react'
import guideSVG    from './svg/guide.svg'

class Introduction extends Component {

  render() {
    let guideIcon = null
    if( this.props.guide )
      guideIcon = <a href={this.props.guide} target='_blank' rel="noopener noreferrer">
                    <img src={guideSVG} id='guideIcon' alt="guide"/>
                  </a>

    let css = {}
    if( this.props.headerID === 'whatsdown' )
      css = {top: '123px'}

    let fcolor = {}
    if(this.props.isAIdent){
      //css = { backgroundColor: 'hsl(30, 100%, 60%)' }
      // fcolor = { color: 'white'} 
    }

    let showQRID = this.props.e.showQR === 'true' || parseInt(this.props.e.showQR) === 1 || this.props.isAIdent

    let {BI} = this.props.app.type

    return (
        <div id='intro' style={css}>
          { guideIcon }

          {
            this.props.name ? 
              <div className='heading' style={ BI? {textAlign: 'left'}:{}} >
                <span>
                  {this.props.name + ' '}
                </span>

                {
                  showQRID ? 
                    <span style={{opacity: 0.25}}>
                      ({this.props.pid})
                    </span>  
                  :
                    '' 
                }

              </div>
            :
            null
          }

          {
            this.props.intro ? 
              <div className='introduction' style={fcolor}>
                <span id='introMessage'>{ this.props.intro }
                {
                  showQRID ? 
                    <b className='pidBold'>{ this.props.pid }</b>
                  :
                  ''
                }
                </span>
              </div>
            :
            null
          }
          
        </div>
      )
  }
}  

export default Introduction