import React, { Component } from 'react'
import Details from './ServiceListDetails'

import arrow from './svg/icon_arrowRight.svg'

class FastSend extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            selected: this.props.root.selectedService === null ? this.props.data.focus || this.props.root.state.singleService : this.props.root.selectedService === this.props.index
        }
    }

    componentDidMount(){
        console.log('FastSend.js didMount')
    }

    close() {
        if (this.agb())
            this.setState({ selected: false })
    }

    toggle() {
        if (this.agb()) {
            this.props.root.closeAll(this.props.index)
            this.setState({ selected: !this.state.selected },() => this.props.root.selectChild(this.props.index))
        }
    }

    agb() {
        return this.props.root ? this.props.root.agb() : false
    }

    render() {
        let s = this.state.selected

       

        return (
            <div className='line'>
                <div className={s ? 'head bg94L' : ''} >
                    <div className={'bg97L flex' + (s ? ' bgblue' : '')}>
                        <img className={'list_icon' + (s ? ' invert' : '')} src={this.props.buttonIcon} alt='service' onClick={() => this.toggle()}/>
                    </div>
                    <span className={'lineName' + (s ? ' bold_gray' : '')} onClick={() => this.toggle()}>
                        {this.props.data.text}
                    </span>
                    <div className='more flex' onClick={() => this.toggle()}>
                        <img className={'lineMore' + (s ? ' rot90' : '')} src={arrow} alt='service' />
                    </div>
                </div>

                <Details selected={this.state.selected} intro={this.props.data.text} />
            </div>
        )
    }
}

export default FastSend
