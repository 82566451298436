import React, { Component } from 'react'
import SimpleInput from './SimpleInput'
import ToggleButton from './ToggleButton'
import ResetButton from './ResetButton'

class ElementEditor extends Component {
    constructor(props){
        super(props)

        this.state = {
            open: false,
            text: 'EDITOR'
        }

        this.parentModifier = this.props.parentModifier
        this.parentModifier.elementEditor = this

        this.id = this.parentModifier.id
        this.type = this.parentModifier.type
        this.app = this.parentModifier.app
        this.renderData = this.app.renderData
        this.error_message = []
        this.local_element_data = global.deep_clone( this.renderData[ this.id ] )

        this.descriptions = {
            selfIndex: 'Automatisch generierter Element-Index.',
            id: 'Element-ID muss einmalig sein. (Muss vom Editor automatisch geprüft werden oder einmalig gemacht werden, z.B. durch eineni Index)',
            name: 'Name des Elements, was dem Benutzer in der Oberfläche angezeigt wird.',
            src: 'Ein Bild für das Menü. Kann entweder ein vorgefertigtes Bild in UQRC sein, oder eine URL zu einer Bilddatei.',
            logo: 'Ein Bild für den Header. Kann entweder ein vorgefertigtes Icon in UQRC sein, oder eine URL zu einer Bilddatei.',
            edpfile: 'Der Dateiname, unter dem die vom Benutzer ausgefüllte JSON im EDP abgespeichert werden soll.',
            post2edp: 'Schalter, um beim Speichern eine Notification an das EDP abzusetzen.',
            type: 'Der Element-Typ entscheidet das Erscheinungsbild und die Funktion des Elements.',
            value: 'Der Wert des Elements kann bei sichtbaren Elementen leer gelassen werden, oder als Voreinstellung ausgefüllt werden.',
            css: 'Einige Elemente können mit zusätzlichen CSS-Eigenschaften optisch an das Layout angepasst werden.'
        }

        this.updateAllEditorFields = () => {
            let i_max = this.inputs.length
            
            for(let i=0; i<i_max; i++){
                let field = this.inputs[i]
                field.update()
            }
        }
    }

    popup_reset(){
        let self = this
            window.show_popup( 
                'Zurücksetzen bestätigen', 
                'Sind Sie sicher, dass Sie alle Felder auf ihre Standardwerte zurücksetzen möchten?', 
                {
                    okay: {
                        name: 'Ja, zurücksetzen', 
                        callback: function(){
                            self.reset()
                            console.log('zurücksetzen')
                        }
                    }, cancle: {
                        name: 'Nein, abbrechen', 
                        callback: function(){
                            console.log('abbruch')
                        }
                    }
                }
            )
    }

    reset(){
        console.log('reset')
        this.local_element_data = global.deep_clone( this.renderData[ this.id ] )
        this.forceUpdate()
        this.updateAllEditorFields()
    }

    open(){
        this.setState({open: true })
    }

    close(){
        this.setState({open: false })
    }

    showDiscription( field ){
        if( this.description !== undefined && this.description_title !== undefined){
            this.description_title.innerText = field

            let warning = ''
            for (let i = 0; i < this.error_message.length; i++) {
                const e_msg = this.error_message[i]

                if( e_msg ){
                    warning += e_msg + '<br/>'
                }
            }

            this.description.innerHTML = warning || this.descriptions[ field ] || '(keine Beschreibung vorhanden)'

            if( warning ){
                this.description.style.color = 'red'
            }else{
                this.description.style.color = ''
            }
        }
    }

    createList(){
        let list = []
        let rd_elem = this.local_element_data // deep clone

        let i = 0
        for(let field in rd_elem ){
            let forbidden_fields = ['_selfIndex']
            let disabled_types = ['selfIndex', 'type']
            let disable = disabled_types.includes( field )
            let show = !forbidden_fields.includes( field )
            if( show ){
                let input = <SimpleInput type='text' value={rd_elem[field]} field={field} disabled={disable} parent={this} i={i}/>
                let icon = <ToggleButton disabled={disable} parent={this} i={i}/>
                let reset = <ResetButton disabled={disable} parent={this} i={i}/>
                i++
                list.push(
                    <tr key={field} onMouseEnter={ ()=> this.showDiscription(field)}>
                        <td className='fieldName' width='1%'>{field}</td>
                        <td>{input}</td>
                        <td width='1%'>{icon}</td>
                        <td width='1%'>{reset}</td>
                    </tr>
                )
            }
        }

        // create discription filed
        list.push(<tr>
            <td className='fieldDescriptionIcon'>
                <span className='modifier_button_icon icomoon_general icomoon_info2'></span>
            </td>
            <td>
                <div className='fieldDescriptionTitle' ref={(el)=> this.description_title = el} ></div>
                <div className='fieldDescription' ref={(el)=> this.description = el} ></div>
            </td>
        </tr>
        )
        return list
    }

    render() {
        return /* this.state.open ?  */(
            <div className='ElementEditorContainer' style={{display: this.state.open ? 'block' : 'none' }}>
                <div className='editorTitle'>Daten für Element {this.id}: {this.type}</div>
                <div>
                    <table>
                        <tbody>
                            {
                                this.createList()
                            }
                        </tbody>
                    </table>
                </div>
                <div className='buttonsEditor'>
                    <span className='editorCancle' onClick={()=>this.close()}>Abbrechen</span>
                    <span className='editorReset' onClick={()=>this.popup_reset()}>Zurücksetzen</span>
                    <span className='editorConfirm' onClick={()=>this.close()}>Übernehmen</span>
                </div>
            </div>
        )/* 
        : 
        null */
    }
}

export default ElementEditor
