import React, { Component } from 'react'
const NOT_FOUND = false

class IconSelector extends Component {
    constructor(props, context) {
        super(props, context)

        this.paths = []
        this.files = []

        let { e } = this.props
        this.obtainIconImages(e)

        this.state = { // icons alternativ in render() generieren
            icons: this.createIcons(this.paths),
            selected: this.dicon,
            path: this.dpath,
            text: e.text
        }
    }

    obtainIconImages(e){
        let { icons, set, path} = e
        let n = icons ? icons.split('|') : []
        n.map( e => {
            let f = path + '/' + e
            this.paths.push(f)
            this.files[f] = this.loadIcon(f)
            return null
        })

        let d = this.dpath = path + '/' + set
        this.files[d] = this.loadIcon(d)
        this.dicon = this.createIcon( d, 0 )
    }

    loadIcon(f){
        try {
            return require(`${f}`)
        } catch (err) {
            return NOT_FOUND
        }
    }

    createIcon(f, i = 0, cn = 'icon'){
        let img = this.files[f]
        return <img src={img} className={cn} alt={f} key={i} onClick={()=>this.selectIcon(f)}/>
    }

    createIcons(list){
        return (
            list.map((f, i)=>{
                return this.createIcon( f, i )
            })
        )
    }

    selectIcon(f){
        let d = this.state.path === f
        this.setState({
            selected: d ? this.dicon : this.createIcon(f), 
            path: d ? this.dpath : f 
        })
    }

  render(){
    return (
        <div className='isContainer'>
            <div className="IconSelector">
                {this.state.icons}
            </div>
            <div className="IconSelectorText">
                {this.state.text}
            </div>
            <div className="SelectedIcon pointer">
                {this.state.selected}
            </div>
        </div>
    )
  }
}

export default IconSelector
