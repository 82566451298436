import React, { Component } from 'react'
import './css/Startpage.css'
import reload from './svg/reload.svg'
import new_window from './svg/new_window.svg'
import error_icon from './svg/buggy_code.svg'
import copy_icon from './svg/copy.svg'

/* import * as Sentry from "@sentry/react";
import mws_conf from '../../../mws.conf' */
const SENTRY_ON = false // mws_conf.SENTRY !== 'off'


class Startpage extends Component {

    componentDidMount(){
        
    }

    clipboard_error_info(){
        let emsg = ''
        let etxt = document.getElementsByClassName("error_text")

        for (let v in etxt) { 
            if( etxt.hasOwnProperty(v) )
                emsg += (etxt[v].innerText + ' ')
        }
        
        if( navigator.clipboard.writeText )
            navigator.clipboard.writeText(emsg).then(function() {
                window.showError('Fehlermeldung erfoglreich in Zwischenablage kopiert.')
            }, function(err) {
                window.showError( err )
            })
        else
            window.showError( 'Anscheinend unterstützt Ihr Browser das Kopieren in die Zwischenablage nicht. Sie können die Fehlermeldung jedoch auch manuell kopieren oder ein Bildschirmfoto anfertigen.' )
    }


    render() {
        if( SENTRY_ON ){
            /* Sentry.captureException(new Error("Empty.JS Error"), {
                tags: {
                    section: "empty.js",
                },
            }) */
        }
        window.hideLoader()
        window.hideCaptchaBadge()
        let app = this.props.app
        let text = this.props.text || app.blueErrorPage.text

        if( typeof text !== 'string'){
            text = JSON.stringify( text )
        }
        text = text.replace('":', '": ').replace('",', '", ') // damit error nich tdie zeile sprengt
            
        let ecode = app ? app.blueErrorPage.ecode : 'no app'
        document.getElementById('root').style.marginTop = 0

        let err_msg = 'Mögliche Ursache: Fehlende Authorisierung.'
        let orig_err = ''

        if( app && app.exists_failed && app.exists_error )
        {
            /* let ef = app.exists_failed

            if( ef.code === 404 )
            {
                err_msg = 'Die angeforderte Datei konnte nicht geladen werden.'
                orig_err = ( ef.request || '' ) + ' ' + ( ef.permid || '' ) + ' ' + ( ef.response || '' ) + ' ' + ( ef.code || '' )
            }

            if( ef.error )
            {
                console.log( ef )
                err_msg = ef.error
                orig_err = JSON.stringify( ef.mws_oiv2_response )
            } */

            orig_err = app.exists_error.mws
            err_msg = app.exists_error.helper
            ecode = app.exists_error.ecode || ecode
        }

        let qrid = app ? app.pid : 'no_qrid'
        let service = app ? app.type.SERVICE : 'no_valid_service'
        let info = <span>{new Date().toLocaleString() + ', ' + qrid + ' (' + service + '):' }</span>
        document.getElementsByTagName('html')[0].className += ' error_green'

        let copy_error_btn = <img src={copy_icon} className='copy' alt='copy' onClick={this.clipboard_error_info}/>

        const server = window.server
        if( server )
            server.log({ message: text, hint: ecode, source: '/components/variantsy/empty/Startpage (aka BlueErrorPage)', log_level: 'warning' })

        return (
            <div className="aiCenter">
                <div className="aiStartContainer">

                    <div id='empty_big'>
                        <div><img src={error_icon} id='error_icon' alt='error_icon'/></div>
                        <div>Uups... </div>
                        <div>Irgendwas ist schief gelaufen!</div>
                        <br />
                        {
                            text ? 
                            <span>
                                Die Anzeige konnte nicht geladen werden.<br />
                                Versuchen Sie die Seite <img src={reload} className='reload' alt='reload' /><a href={ window.location.href } >neu zu laden</a>.
                                <br />
                                <br />
                                <div className='error_text'>{info}</div>
                                <div className='error_text'>{text}</div>
                                {
                                    ecode ? 
                                    <div className='error_text'>{ecode}</div>
                                    :
                                    ''
                                }
                                {
                                    copy_error_btn
                                }
                                <br />
                                Falls der Fehler dauerhaft besteht,<br />informieren Sie bitte
                                <img src={new_window} className='new_window' alt='new_window' /><a href="https://makrolog.freshdesk.com/support/tickets/new">Makrolog</a>.
                                
                            </span>
                            :
                            <span>
                                {
                                    err_msg || 'Die Anzeige konnte nicht geladen werden.'
                                }
                                <br />
                                {
                                    orig_err ?
                                    <div className='error_text'>{info}<br/><br/>{orig_err}</div>
                                    :
                                    ''
                                }
                                {
                                    ecode ? 
                                    <div className='error_text'>{ecode}</div>
                                    :
                                    ''
                                }
                                {
                                    copy_error_btn
                                }
                                <br />
                                Melden Sie sich bitte ggf. erneut an und <img src={reload} alt='reload' /><a href={ window.location.href } >laden</a> Sie die Seite neu.<br /><br /><br />
                                Falls der Fehler dauerhaft besteht,<br />informieren Sie bitte
                                <img src={new_window} className='new_window' alt='new_window' /><a href="https://makrolog.freshdesk.com/support/tickets/new">Makrolog</a>.
                            </span>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Startpage
