import React from 'react'

class ToggleButton extends React.Component {
    constructor(props) {
        super(props)
        this.disabled = this.props.disabled
        this.parent = this.props.parent
        this.i = this.props.i

        this.state = {
            active: undefined
        }
    }

    click(){
        let result = this.parent.inputs[this.i].toggle()
        this.setState({
            active: result
        })
    }

    render() {
        if( !this.disabled ){
   
            let cls = this.state.active ? 'icomoon_expanded' : 'icomoon_expand'
            return <div onClick={()=>this.click()} className={`icomoon_general ${cls}`}></div>
            
        }else{
            return null
        }
    }
}

export default ToggleButton