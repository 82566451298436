import React, { Component } from 'react'
import './css/Details.css'

class Details extends Component {
    render() {
        let service = this.props.service
        return (
            <div className="Details" >
                <div className='ServiceName'>{service.name}</div>
                <div className='ServiceDescription'>{service.description}</div>
            </div>
        )
    }
}

export default Details
