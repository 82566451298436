import React, { Component } from 'react'
import mws_conf from '../mws.js'

class News extends Component
{
    constructor( props, context )
    {
        super( props, context )

        this.title = this.props.meta.title
        this.last_update = this.props.meta.last_update
        this.description = this.props.meta.description
        this.filename = this.props.meta.filename
        this.idtoken = window.getURLParameter('idtoken')
        // this.image = this.props.meta.image
        this.perm_id = this.props.meta.perm_id
        this.init_object_path()

        if( this.last_update ){
            this.last_update = this.last_update.replace('.0','')
        }

        if( this.props.no_margin ){
            this.css = { margin: 0 }
        }else{
            this.css = { paddingBottom: '2rem' }
        }
    }


    init_object_path(){
        let op = mws_conf.OBJECT_PATH
        if( op ){
            if( !op.match(/^\//) ){
                op = '/'+op
            }
            if( !op.match(/\/$/) ){
                op = op+'/'
            }
        }

        this.object_path = op
        return op
    }
    
    format_time( tstring ){
        if( tstring ){
            let ts = tstring.split(' ')
            if( ts.length > 1 ){
                let date = ts[0]
                let time = ts[1]

                let year_month_day = date.split('-')
                tstring = year_month_day[2] + '.'
                tstring += year_month_day[1] + '.'
                tstring += year_month_day[0]
            }
        }

        return tstring
    }

    render()
    {

        let subd     = mws_conf.EDP_SUBD ? mws_conf.EDP_SUBD + '.' : ''
        let domain   = mws_conf.DOMAIN

        
        let is_pdf = this.filename && this.filename.includes('.pdf')
        let file_url
        if( this.filename ){
            if( is_pdf ){
                file_url = this.props.app.helper + '/pdf?f=' + this.perm_id + '&idtoken=' + this.idtoken
    
            }else{
                file_url = this.props.app.helper + '/img?f=' + this.perm_id + '&idtoken=' + this.idtoken
                //file_url = 'http://localhost:5000/img?f=c63e0d56-0445-47a0-a986-a47a892a2e41_Bildschirmfoto 2022-04-13 um 14.48.10.png'
            }
        }



        return (
            <div className='News Inset' style={ this.css }>
                {/* <a href={file_url} target='_blank'> */}
                    <table>
                        <tbody>
                            <tr>
                                {/* <td className='files'><span className='icomoon_file icomoon_general'></span></td> */}
                                <td className='left'>
                                    <div className='LastUpdate'>{ this.format_time( this.last_update )}</div>
                                    <div className='NewsTitle'>{ this.title }</div>
                                    <div className='NewsDescription'>{this.description}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
               {/*  </a> */}
               {
                    file_url ?
                        <table>
                            <tbody>
                                    {
                                        is_pdf ?
                                            <tr>
                                                {/* <td className='files'><span className='icomoon_newsfile icomoon_general'></span></td> */}
                                                <td className='left'>
                                                    <a href={file_url} target='_blank'>
                                                        <div className='NewsFile'>{ this.filename }</div>
                                                    </a>
                                                </td>
                                            </tr>
                                        :
                                            <tr>
                                                
                                                <td className='left'>
                                                    <img src={file_url} style={{maxWidth: '70vw'}}/>
                                                </td>
                                            </tr>

                                    }
                            </tbody>
                        </table>
                    :
                        null
               }
            </div>
        )
    }
}

export default News

