import React, { Component } from 'react'
import './css/Startpage.css'
import LocalDevIcon from '../../LocalDevIcon'
import Image from '../../Image'

import Header from '../../Header'

class CDAPHeader extends Component {
    render() {
        let app = window.appjs
        let header_img_src = app ? app.getRenderDataElement('header', 'src') : false

        return <Header name={'cdap_name'} id={'cdap'} key={0} pid={app.pid} isAIdent={false} app={app}/>

        return (
            <div>
                <LocalDevIcon />
                {
                    header_img_src ?
                        <Image e={{ className: 'cdapheaderimg', src: header_img_src }}/>
                    :
                        <p id='big'>
                            <b>CORONA PRESENCE</b>
                        </p>
                }
            </div>
        )
    }
}

export default CDAPHeader
