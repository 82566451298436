import settings from '../config/settings.js'

// ausgabe nur lokal
export const log = settings.localhosts.includes( window.location.hostname ) ? 
    ( msg, find, color ) => {

        var targetId = settings.htmlDebugElementId
        var col    = color || 'red'

        var d = document.getElementById(targetId)
        if (!d) {

            d = document.createElement('div')
            d.id = 'debug'
            d.onclick = function(){
                this.style.display = 'none'
            }

            var html = document.getElementsByTagName('html')[0]
            html.appendChild(d)
        }
        
        if (typeof msg!== 'string')
            try {
                msg = JSON.stringify(msg)
            } catch (error) {}

        let options = { hour12: false }
        let time    = new Date(Date.now()).toLocaleString('en-US',options) + ' '
        let output  = document.createElement('div')

        msg = msg.split(find).join(`<b style="color: ${col};">${find}</b>`) //  replace( find, )

        // output.style.color = col
        output.innerHTML   = time + msg +'<br/><br/>'

        d.appendChild(output)
        console.log(msg)
    }
    :
    () => {}

    export const clog = settings.localhosts.includes( window.location.hostname ) ? 
    ( msg, data ) => {
        if(data)
            console.log(msg, data)
        else
            console.log(msg)
    }
    :
    () => {}