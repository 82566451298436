import React, { Component } from 'react'
import WhatsDownMessage from './WhatsDownMessage'

import arrow from './svg/icon_arrowRight.svg'

class NewReport extends Component {
    constructor(props, context) {
        super(props, context)

        let {root, data, index} = this.props
        if( root && data ){
            this.state = {
                selected: root.selectedService === null ? data.focus || root.state.singleService : root.selectedService === index
            }
        }else{
            this.state = {
                selected: true
            }
        }
    }

    componentDidMount(){
        console.log('NewReport.js didMount')
    }

    close() {
        if (this.agb())
            this.setState({ selected: false })
    }

    toggle() {
        if (this.agb()) {
            this.props.root.closeAll(this.props.index)
            this.setState({ selected: !this.state.selected },() => this.props.root.selectChild(this.props.index))
        }
    }

    agb() {
        return this.props.root ? this.props.root.agb() : false
    }

    render() {
        let s = this.state.selected
        let {data} = this.props
        let text = data ? data.text : 'no text'
        

        return (
            <div className='line'>
                <div className={s ? 'head bg94L' : ''} >
                    <div className={'bg97L flex' + (s ? ' bgblue' : '')}>
                        <img className={'list_icon' + (s ? ' invert' : '')} src={this.props.buttonIcon} alt='service' onClick={() => this.toggle()}/>
                    </div>
                    <span className={'lineName' + (s ? ' bold_gray' : '')} onClick={() => this.toggle()}>
                        {text}
                    </span>
                    <div className='more flex' onClick={() => this.toggle()}>
                        <img className={'lineMore' + (s ? ' rot90' : '')} src={arrow} alt='service' />
                    </div>
                </div>

                <WhatsDownMessage selected={this.state.selected} intro={text} root={this.props.root} />
            </div>
        )
    }
}

export default NewReport
