import React, { Component } from 'react'

class IconButton extends Component {
    constructor(props, context){
        super(props)

        if( props.app ){
            this.app = this.props.app

            if( this.props.e ){

                let { e } = this.props
                this.isLast = this.app.getLastElementPosition( e.type, e.elementClass ) === e.selfIndex
            }
        }

        try{
            let icon = this.props.icon

            if ( icon.match( /^http/ ) ){   
                this.icon = icon
            }
            
            if ( icon.match( /^helper:/ ) ){
                icon = icon.replace('helper:','')
                this.icon = this.app.helper + '/' + icon
            }

            if( !this.icon ){
                this.icon = require( `${ icon }` )
            }
        } catch ( error ){}

        try{
            let next = this.props.next

            if ( next.match( /^http/ ) ){
                this.next = next
            }

            if ( next.match( /^helper:/ ) ){
                next = next.replace('helper:','')
                this.next = this.app.helper + '/' + next
            }
            
            if(!this.next){
                this.next = require( `${ next }` )
            }
        } catch ( error ){}
    }

    render() {
        let {onClick, title, text, icon, e } = this.props
        let { icon_css, navigation } = e
        let nextIconStyle = {}
        let lastListElementStyle = {
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem'
        }

        let icon_elem
        if( icon && icon.match(/^text:/)){
            let text = icon.split(':')[1]
            icon_elem = <span style={ icon_css }>{text}</span>
        }else{
            icon_elem = <img style={ icon_css } src={this.icon}/>
        }

        let no_nav = navigation === 'none'
        if( no_nav ){
            nextIconStyle = { display: 'none' } 
        }

        return (
            <div className='iconButton' onClick={onClick} style={ this.isLast ? lastListElementStyle : {} }>
                <table>
                    <tbody>
                        <tr>
                            <td className='iconButton_icon'>
                                {
                                    icon_elem
                                }
                            </td>
                            <td className='iconButton_tt_group wordwrap'>
                                <div className='iconButton_title'>{title}</div>
                                <div className='iconButton_text'>{text}</div>
                            </td>
                            <td className='iconButton_next'>
                                <img src={this.next} style={nextIconStyle}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default IconButton
