import React, { Component } from 'react'
import _services from './lang/Services'
import Service from './Service'
import Group from './Group'
import Default from './Default'
import './css/Groups.css'
import './css/List.css'

const DEFAULT_PADDING = '10vh'
const map = {
    'service-meldung': 'msg',
    'anleitung': 'doc',
    'hotline': 'tel',
    'faq': 'faq',
    'leer': 'drop',
    'bestellen': 'cart',
    'message': 'msgDark'
}

class Groups extends Component {
    constructor(props, context) {
        super(props, context)
        
        this.state = {
            paddingTop: null
        }

        this.children = []
        
        window.orientationDependentElements['services'] = this
    }

    orientationChange() {
        this.setFullscreen()
    }

    maximized() {
        let ch = this.children
        if (Object.keys(ch).length) {
            return Object.keys(ch).some((n) => {
                return ch[n].state.maximized
            })
        }
        return false
    }

    setFullscreen(b) {
        this.setState({ paddingTop: (window.isPortrait() && this.maximized()) || b ? DEFAULT_PADDING : null })
    }

    
    renderServiceTabsFromUrl() {
        return Boolean(this.props.ss.get('tabs'))
    }

    render() {
        let servicePreviews = ['msg']
        let pt = this.state.paddingTop
        let padding = pt ? { paddingTop: pt } : {}

        // dispatcher
        return (
            <div className='Groups' style={padding}>
                {
                    this.renderServiceTabsFromUrl() ?
                        this.props.ss.meta.tabs.map((id, i) => {
                            return <Service key={i} service={_services[map[id]][this.props.ss.meta.lang]} i={map[id]} url={id === 'faq' ? '' : this.props.ss.meta.url + id} />
                        })
                        :
                        servicePreviews.map((id, i) => {
                            switch (id) {
                                case 'msg': return <Group key={i} idx={i} services={this} ss={this.props.ss} ref={(c) => this.children[i] = c} />
                                default: return <Default key={i} name={id} />
                            }
                        })
                }
            </div>
        )
    }
}

export default Groups
