import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import ServiceSelector from './ServiceSelector';

window.servicesJSON = [
    { text: 'FAQ', type: 'document', url: 'https://cbcdn2.gp-static.com/uploads/product_manual/file/202/HERO3_Plus_Black_UM_ENG_REVD.pdf' },
    { text: 'Bedienungsanleitung', type: 'document', url: 'https://cbcdn2.gp-static.com/uploads/product_manual/file/202/HERO3_Plus_Black_UM_ENG_REVD.pdf' },
    { text: 'Toner leer', type: 'message', focus: false },
    { text: 'Papier leer', type: 'message' },
    { text: 'Drucker reagiert nicht', type: 'message' },
    { text: 'Nachricht verfassen', type: 'other', focus: true },
    { text: 'Support Anruf', type: 'call' },
    { text: 'Impressum & AGB', type: 'legal' }
  ]

ReactDOM.render(<ServiceSelector/>, document.getElementById('root'));


