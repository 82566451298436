import React, { Component } from 'react'
import aifamily from './svg/aifamily.svg'
import aiLogoConv from './svg/aiLogoConverted.svg'
import './css/Startpage.css'
import AGB from '../../common/AGB'

class Startpage extends Component {
  render(){
    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          <img src={aiLogoConv} id="aiLogoConv" alt="aiLogoConv" />
          <img src={aifamily} id="aifamily" alt="aifamily" />
          <p id='big'>
            <b>Boehringer Checklisten</b>!
          </p>
          <p>
            Willkommen auf der Startseite von <b>BI-Checklisten</b>.
          </p>
          <p>
            Mit unseren <b>Checklisten</b> haben Sie Ihr Qualitäts-Management immer im Griff.
          </p>
          <p>
            Eine gute Entscheidung für Sicherheit und Qualität, denn einfach ist besser.
          </p>
          <AGB/>
          <div id='aigo' className='button'  onClick={() =>{
            let goto = window.getURLParameter('goto')
            window.location.href = goto ? goto : this.props.goto }
          } >{"Und los geht's"}</div>
          <p id='kontakt'>
            <a id="ailink" href="http://dig-sie.de/KONTAKT/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Kontakt
              </span>
            </a><br/>
            <a id="ailink" href="http://dig-sie.de/Impressum-und-AGB/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Impressum
              </span>
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default Startpage
