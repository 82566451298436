import React, { Component } from 'react'
import line_h from './svg/line_h.svg'
import line_v from './svg/line_v.svg'
import Note from './Note'

const ROTATED = -90

class Section extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = { deg: ROTATED }

        this.onLoad = true

        this.app = this.props.app
        let {BI, CDAP} = this.app.type
        if( BI ){
            this.classname = 'SectionBI'
        }else if( CDAP ){
            this.classname = 'SectionCDAP'
        }else{
            this.classname = 'Section'
        }
        this.render_as_button = global.parseBool(this.props.e.render_as_button)

        this.toggleSection = this.toggleSection.bind(this)

        this.setNoteRef = (i, t) => {
            this.qrCodeNoteRef = t
        }
        this.qrCodeNoteElem = <Note content='Bitte QR-Code scannen um Rundgang zu starten!' setNoteRef={this.setNoteRef} />
    }



    componentDidMount() {
/*         try {
            let elm = document.getElementsByClassName('Section')[0]
            let fsize = document.defaultView.getComputedStyle( elm, null).getPropertyValue('font-size')
            elm.innerText = fsize
        } catch (error) {
            document.getElementsByClassName('Section')[0].innerText = error
        }
 */
        if (this.onLoad && this.props.e.collapse === 'true') {
            this.onLoad = false
          this.closeSection()
            //this.toggleSection()
        }
    }

    closeOtherSections(self){
        let secs = this.app.sections
        for (var key in secs) {
            if(secs.hasOwnProperty(key)){
                if(key !== self){
                    secs[key].closeSection()

                    if(true){
                        secs[key].refs.self.style.opacity = 0.5
                    }
                }
            }
        }
    }



    closeSection() {
        let list = document.getElementsByClassName(this.props.section)
        if (!list.length)
            return

        for (var i = 0; i < list.length; i++) {
            //list[i].dataset.display = 'hide'
            list[i].parentNode.dataset.display = 'hide'

           /*  list[i].style.maxHeight = '0px'
            list[i].style.paddingBottom = '0px'
            list[i].style.paddingTop = '0px'
            list[i].style.marginTop = '0px' */
        }
        this.setState({ deg: 0 })
    }




    highlightOtherSections() {
        let sections = document.getElementsByClassName(this.classname)
        if (!sections.length)
            return null

        for (var i = 0; i < sections.length; i++) {
            sections[i].style.opacity = 1
        }
    }

    toggleSection() {
        /* 071121 für BI deaktiviert, da die Cards schon eingeklappt werden*/
        return false
        // if closeOthers=true
        if(true){
            this.closeOtherSections(this.props.name)
        }

        this.refs.self.style.opacity = 1

        if (!this.app.validated) {
            this.qrCodeNoteRef.show()
            return
        }

        if( /* todo: force_message_text */ false ){

            // nur andere sections toggeln, wenn unfinishedModal existiert
            let om = this.app.getOpenModal()
            if (om) {
                let same = om.checkit.section === this.props.name.replace(' ', '_')
                if (this.app.unfinishedModal() && same) {
                    om.notes['noText'].show()
                    return
                }
            }
        }

        let list = document.getElementsByClassName(this.props.section)
        let degr = ROTATED

        if (!list.length)
            return

        for (var i = 0; i < list.length; i++) {
            // list[i]: sub-element der section

            let show = list[i].parentNode.dataset.display === 'show'
            if (show) { // => hide
                this.highlightOtherSections()

                degr = 0
                this.setState({ deg: degr })
                //}.bind(null, list[i], this), 1);

                if( true || list[i].classList.contains('submit') || list[i].classList.contains('submit_wdf') ){
                    // list[i].parentNode.style.maxHeight = '0rem'
                    list[i].parentNode.dataset.display = 'hide'
                }
            } else { // => show
                
                list[i].parentNode.dataset.display = 'show'
                //list[i].dataset.display = 'show'                 

                if( true || list[i].classList.contains('submit') || list[i].classList.contains('CheckBoxContainer')){
                        //list[i].style.maxHeight = 'inherit'
                        this.setState({ deg: degr })
                }else{
                    this.setState({ deg: degr })
                }
            }
        }
    }

    render() {
        // console.log(this)
        let renderAsButton = this.render_as_button && this.state.deg !== 90 // ? ' RenderSectionAsButton ' : ''
        return (
            <div>
                <div
                    className={ this.classname + ' pointer' + (renderAsButton ?  ' RenderSectionAsButton ' : '')}
                    id={this.props.section}
                    style={{ opacity: this.props.disabled ? 0.25 : 1.0 }}
                    ref='self'
                    onClick={this.toggleSection}>
                    <span> {this.props.name} </span>
                {
                    /* 071121: collapse für BI deaktiviert, da die Cards schon eingeklappt werden*/
                    //<img src={line_h} className='collapseIcon' style={{ margin: '0.4rem', transform: 'rotate(' + (this.state.deg + 90 ) + 'deg)', filter: 'invert(' + (renderAsButton? 1 : 0) + ')' }} alt='close' />
                    //<img src={line_v} className='collapseIcon' style={{ margin: '0.4rem', /* transform: 'rotate(' + (this.state.deg + 15) + 'deg)', */ filter: 'invert(' + (renderAsButton? 1 : 0) + ')' }} alt='close' />
                 }
                </div>
                {this.qrCodeNoteElem}
            </div>

        )
    }
}

export default Section


