import React, { Component } from 'react'
import plus from './svg/plus_circle.svg'


class InsertElement extends Component {

    componentDidMount(){
        if(!global.added_elements){
            let temp = 0
            this.props.app.renderData.map((e, i)=>{
                
                if(e.count){
                    let ecount = Number(e.count)
                    temp = ecount > temp ? ecount : temp
                }

                if(e.id){
                    let eid = e.id.split('___')
                    temp = eid.length > 1 && eid[eid.length-1].length === 1 && temp < Number(eid[eid.length-1]) ? Number(eid[eid.length-1]) : temp
                }

                return null
            })
            
            global.added_elements = temp
        }
    }


    getInsertionIndex(e, app){
        let { id, type, position='', index=0 } = e
        
        if(!global.insert_element_index ){
            if(position.match(/^self$/))
                index = app.getElementPosition(id, type) + 1
            else if(position.match(/^new$/))
                index = app.getElementPosition(id, type) + global.added_elements // todo: extended + standard ?
            else if(position.match(/^before$|^beforeInverted$/))
                index = global.insert_element_index = app.getElementPosition(null, type)
            else
                index = global.insert_element_index = app.getElementPosition(position) + 1 //position =: id eines elements
        }else
            index = global.insert_element_index

        return index
    }


    insertElement(e, app){
        let insert_ = global.clone(Array.isArray(e.insert) ? e.insert : [e.insert])

        let INSERT_ELEMENTS = insert_

        let SIMPLE_QUICKMESSAGE = insert_.length === 1 && insert_[0].type.match(/quickmessage/)
        let WACHENDORFF_QUICKMESSAGE = SIMPLE_QUICKMESSAGE && (e.mode && e.mode.match(/extended/))

        let TISCH_NUMMER = insert_.length === 1 && insert_[0].type.match(/text/) && insert_[0].name.match(/Tisch-Nummer|Tischnummer/)
        let DFB_ZONE = insert_[0].type.match(/select/) && insert_[0].name.match(/Zone/)

        // nur 1 mal hinzufügen: tisch nummer / dfb zone
        if( TISCH_NUMMER || DFB_ZONE ){
            console.log('INSERT ELEMENT HIDE INSERT BUTTONS')
            app.hideInsertButtons = true
        }

        global.added_elements++
        if( SIMPLE_QUICKMESSAGE ){
            insert_[0].id      = e.id + '___' + global.added_elements
            insert_[0].name    = e.name //+ ' (' + global.added_elements + ')'
            insert_[0].mode    = e.mode
            insert_[0].count   = global.added_elements
            insert_[0].classes = 'marginTop'
            insert_[0].creator = 'user'

            if( WACHENDORFF_QUICKMESSAGE ){ // wachendorff
                INSERT_ELEMENTS = [insert_[0]].concat([
                    {
                        "id": insert_[0].id +  /* '___' + global.added_elements + */global.CONTACT_DEFAULT,
                        "name": "Meldung senden",
                        "placeholder": "E-Mail / Telefonnummer (SMS)",
                        "type": "text",
			            "body":"<%checkbox_test%> <%absender%:> %deviceName% %value%",
                        "classes": "paddingLeft",
                        "required": "0"
                    },
                    {
                        "id": insert_[0].id + /* '___' + global.added_elements + */ global.CONTACT_URGENT,
                        "name": "Dringende Meldung senden",
                        "placeholder": "E-Mail / Telefonnumme (SMS)r",
                        "type": "text",
			            "body":"<%checkbox_test%> <%absender%:> %deviceName% %value% (Dringend!)",
                        "classes": "paddingLeft",
                        "required": "0"
                    },
                    {
                        "id": insert_[0].id + /* '___' + global.added_elements + */ global.CONTACT_DIRECT,
                        "name": "Jetzt anrufen",
                        "placeholder": "Telefonnummer (Sprache)",
                        "type": "text",
			            "body":"<%checkbox_test%> <%absender%:> %deviceName% %value%",
                        "classes": "paddingLeft",
                        "required": "0"
                    }
                ])
            }
        }

    
        // insert data
        app.renderData.insert(
            this.getInsertionIndex(e, app), // einfüge-position innerhalb von renderData bestimmen
            INSERT_ELEMENTS
        )
        
        // set new index
        if(e.position.match(/^before$/))
            global.insert_element_index+= INSERT_ELEMENTS.length //+ (WACHENDORFF_QUICKMESSAGE ? 1 : 0 )
        

        app.forceUpdate()
    }


    render() {
        let { e, app } = this.props
        if(app.hideInsertButtons)
            return null


        let height = 'calc(var(--normal) * 1.5)'


        return (
            <div id={e.id} className='InsertElement' style={{/* fontSize:fontSizeBig */}} onClick={()=>this.insertElement(e, app)}>
                {
                    app.insert_element_toolbox ? 
                    null
                    :
                    <div className='ToolboxHeader' data-set-tb={app.insert_element_toolbox = true} >
                        Button hinzufügen:
                    </div>
                }
                <div>
                    <img className='IconAddElement' src={plus} style={{height: height }} alt='+'/>
                    <span className='TextAddElement'>
                        {e.name}
                    </span>
                </div>
            </div>
        )
    }
}

export default InsertElement
