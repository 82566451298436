import React, { Component } from 'react'

import './css/ServiceMessage.css'

class ServiceMessage extends Component {
  render() {

    let texte = ['Toner leer', 'Papier leer', 'Dokumente kommen nicht an', 'Drucker reagiert nicht', 'Eine längere vorgefertigte Nachricht, eventuell mehrzeilig', 'Sonstiges Problem...']

    return (
      <div className="ServiceMessage flexItem">
        ServiceMessage:

        {
          texte.map( (id, i) => {
            return <div key={i} className='line'>{id}</div>
          })
        }

      </div>
    )
  }
}

export default ServiceMessage
