import React, { Component } from 'react'
import placeholder from './svg/icon_doton.svg'

class Impressum extends Component {
    constructor(props, context) {
        super(props, context)

        this.agb = null

        this.state = {
            height: '5em'
        }
    }

    componentDidMount() {
        let start = this.startSpan.getBoundingClientRect().top
        let action = this.actionButton.getBoundingClientRect().bottom

        if (this.state.height === '5em')
            this.setState({ height: 'calc(3.5em + ' + (action - start) + 'px)' })
    }

    UNSAFE_componentWillUpdate() {
        if (this.props.selected)
            this.agb = this.props.root.AGB.accepted()
    }


    render() {
        let s = this.props.selected
        let agb = this.agb //s ? this.props.root.agb() || false : false

        return (
            <div className='ServiceDetails bg98L' style={s ? { height: this.state.height } : { height: 0 }}>
                <div className='bgblue bg97L flex'>
                    <img className='list_icon IconPlaceholder' src={placeholder} alt='service' />
                </div>
                <div className='DetailsSubgroup' >
                    <div className='SubgroupDescription'>
                        <span id='start' ref={(c) => this.startSpan = c}></span>
                        Hier finden Sie unsere <span>AGB's</span> und <span>Datenschutzerklärung</span>.
                    </div>

                    <div className='SubgroupButtons' ref={(c) => this.actionButton = c}>
                        {
                            agb ?
                                null :
                                <span className='OpenButton bgblue Button' >
                                    <span>AGB akzeptieren</span>
                                </span>
                        }
                    </div>


                </div>

            </div>
        )
    }
}

export default Impressum
