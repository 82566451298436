import React, { Component } from 'react'
import presence from './svg/cdap_tmpl.svg'
import air from './svg/air_tmpl.svg'
import wd from './svg/wd_tmpl.svg'
import ihk from './setup_templates/ihk.json'
import wdsetup from './setup_templates/wdsetup0821.json'

var server

class TemplateSelector extends Component {
    constructor(props, context) {
        super(props, context)

        this.templates = {}
        this.templates[ 'ihk' ]     = ihk
        this.templates[ 'wdsetup' ] = wdsetup

        this.icons = {}
        this.icons[ 'presence' ]    = presence
        this.icons[ 'air'      ]    = air
        this.icons[ 'wd'       ]    = wd

        this.urlParameterActivation = this.props.e.urlParameterActivation
        this.showOnStartup          = this.props.e.showOnStartup

        this.app                    = this.props.app
        this.title                  = this.props.e.title
        this.warning                = this.props.e.warning
        this.description            = this.props.e.description
        this.templateList           = this.props.e.templateList
        this.buttonTextOpen         = this.props.e.buttonTextOpen
        this.buttonTextClose        = this.props.e.buttonTextClose
        this.templateBox            = this.constructTemplateList()
        
        this.state = {
           show: this.initialDisplayState()
        }

        this.phead = document.getElementById('show_more_head')
        this.pbody = document.getElementById('show_more_body')
    }


    componentDidMount() {
        server = window.server
    }


    show(){
        this.setState({
            show: true
        })
    }


    hide(){
        if( !this.loading ){

            this.setState({
                show: false
            })
        }
    }


    toggle(){
        this.setState({
            show: !this.state.show
        })
    }


    initialDisplayState(){
        let url_active = document.location.href.includes( this.urlParameterActivation )
        let on_startup = this.showOnStartup && this.showOnStartup !== 'false'
        return url_active || on_startup
    }

    getTemplateSelectorIndex(){
        return this.getFirstElementTypeIndexInJson( 'templateSelector' )
    }


    getSubmitElementIndex(){
        return this.getFirstElementTypeIndexInJson( 'submit' )
    }


    getFirstElementTypeIndexInJson( type ){
        let rd = this.app.renderData

        for (let i = 0; i < rd.length; i++) {
            if( rd[ i ].type === type )
                return i
        }

        return 0 // ? rd.length
    }


    getStartEndIndices(){
        let start = this.getTemplateSelectorIndex()
        let end = this.getSubmitElementIndex()
        let count = end - start
        return { start, end, count }
    }


    error_popup( file, error ){
        this.loading = false
        
        this.phead.innerHTML = 'Fehler beim Laden der Vorlage' 
        this.pbody.innerHTML = `<b>${ file.title }</b>: ${error.message}` 
        
        window.show_popup()
        window.hideLoader()
    }


    async success_popup( file ){
        window.hideLoader()

        return new Promise( resolve => {

            this.phead.innerHTML = 'Hinweis' 
            this.pbody.innerHTML =  `Vorlage <b>${ file.title }</b> erfolgreich geladen. ` 
            
            window.show_popup()

            let self = this
            let intv = setInterval(() => {
                if( !window.popupState ){

                    clearInterval( intv )

                    /* hide template selector */
                    this.loading = false
                    this.hide()
                    resolve( true )
                }
            }, 200 );
        })
    }


    validate( response, file ){
        /* server error generieren */
        let { status, statusText } = response
        if( status !== 200 ){
            throw{
                name: "templateJsonNotFound",
                message: `permid ${file.permid}: ${statusText} (${status})`
            }
        }
    }


    check( data, file ){
        /* xml stream error generieren */
        if( data.match(/xml version.*failure.*task.*stream/)){
            throw{
                name: "noJsonTemplate",
                message: `${file.permid}: XML Stream Error. (Falsche permid oder keine Zugriffsrechte.)`
            }
        }
    }


    /* mws/file get + parse */
    async get( file ){
        file.permid = file.permid.replace('_','')

        /* mws transfer api via helper */
        let response = await this.app.post('/json', { permid: file.permid })
        this.validate( response, file )
        
        /* parse payload */
        let data = await response.text()
        this.check( data, file )

        /* parse json */
        data = JSON.parse( data )
        return data
    }


    load( file ){
        let template = this.templates[ file.local ]
        return template.slice( 2, template.length )
    }


    merge( data ){
        /* merge json into setup */
        let index = this.getStartEndIndices()
        this.app.renderData.splice( index.start + 1, this.app.renderData.length /* index.count */, data )
        this.app.renderData = this.app.renderData.flat(1)
    }


    async fetchTemplateData( file ){

        if( !this.loading ){
            try {
                /* loading animation */
                window.showLoader()
                this.loading = true

                let data
                if( file.permid ){ 

                    /* edp/mws */
                    data = await this.get( file )
                }else{
                    
                    /* local json */
                    data = this.load( file )
                }
        
                // merge into renderData
                this.merge( data )
        
                await this.success_popup( file )
                this.app.forceUpdate()

            } catch (error) {

                this.error_popup( file, error )
                server.log( `setup template selector: ${ file.title }: ${error.message}` )
            }

            
        }
    }


    constructTemplateList(){
        let template_array = []
        for( let el in this.templateList ){
            if( this.templateList.hasOwnProperty( el ) ){

                let t = this.templateList[ el ]
                let src = this.icons[ t.icon ]
    
                template_array.push(
                    <li>
                        <img src={ src       } alt={ t.icon }/>
                        <h3>{  t.title       }</h3>
                        <div>{ t.description }</div>
                        <span onClick={ () => this.fetchTemplateData( t ) }>{ t.loadButton }</span>
                    </li>
                )
            }
        }

        // https://css-tricks.com/adaptive-photo-layout-with-flexbox/
        // template_array.push(<li></li>)
        return template_array
    }

    render() {

        return (
            <div>
                <div className='templateBtn' onClick={ () => this.show() }>{ this.buttonTextOpen }</div>
                {
                    this.state.show ?
                        <div>
                            <div id='templateBox'>
                                <h3>{ this.title       }</h3>
                                <div>{ this.description }</div>
                                <p>{ this.warning }</p>
                                <div className='templateBtn close debuttonize' onClick={ () => this.hide() }>{ this.buttonTextClose }</div>
                                <ul>{  this.templateBox }</ul>
                                <div className='templateBtn close' onClick={ () => this.hide() }>{ this.buttonTextClose }</div>
                            </div>
                        </div>
                        : 
                        null
                }
            </div>
        )
    }
}

export default TemplateSelector


