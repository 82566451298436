import React, { Component } from 'react'

class UnknownElement extends Component {
    render() {
        let type = this.props.type
        let name = this.props.name
        return (
            <div id='unknownElement'>
                JSONtoHTML: Unknown element type <b>{(type || 'undefined')}</b> ({(name || 'undefined')}, add to FILTER_TYPES in App.js)
            </div>
        )
    }
}

export default UnknownElement
