import React, { Component } from 'react'
import './css/Startpage.css'
import LocalDevIcon from '../../LocalDevIcon'
import Image from '../../Image'

class DHCHeader extends Component {
    render() {
        let header_img_src = window.appjs.getRenderDataElement('header', 'src')

        return (
            <div>
                <LocalDevIcon />
                {
                    header_img_src ?
                        <Image e={{ className: 'dhcheaderimg', src: header_img_src }}/>
                    :
                        <p id='big'>
                            <b>CoronaAssist:RAPIDOK</b>
                        </p>
                }
            </div>
        )
    }
}

export default DHCHeader