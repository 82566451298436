import React, { Component } from 'react'
import './css/Video.css'

import empty from './video/empty.mp4'
import empty_ from './video/empty.png'

import workers from './video/reencoding_shutterstock_videos_x1.mp4'
import workers_ from './video/workers.png'

import writes from './video/writes.mp4'
import writes_ from './video/writes.png'

class Video extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            play: true,
            minimized: false,
            landscape: window.innerHeight < window.innerWidth 
        }

        this.wasPlaying = true
    }

    componentDidMount(){
        var self = this
        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
              window.setTimeout( () =>
              {
                self.setState({   
                    landscape: window.innerHeight < window.innerWidth 
                })
              }
              , 40)
            }, false)
        }
      }

    minimize(){
        this.wasPlaying = this.state.play
        this.refs.video.pause()
        this.setState({minimized: true, play: false})
        return true
    }

    maximize(){
        this.setState({minimized: false, play: this.wasPlaying})
        if(this.wasPlaying) this.refs.video.play()
        return true
    }

    handleClick(){
        let {play} = this.state
        play ? this.refs.video.pause() : this.refs.video.play()

        this.wasPlaying = !play
        this.setState({
            play: !play
        })
    }

    render() {
        
        let {play, minimized} = this.state
        let t = ( Math.floor(Date.now() / 1000) - 1573763300 ) % 3
        let v = t ? (t > 1 ? empty : writes ) : workers
        let p = t ? (t > 1 ? empty_ : writes_ ) : workers_
        let f = play && !minimized ? {} : {} //{ filter: 'blur(9px)' }
        /* 
        let s = minimized || play? {opacity: 0} : {} */
        return (
            <div className='Video' >
                <video autoPlay loop muted playsInline  poster={p} ref='video' style={f} onClick = { ()=>this.handleClick() }>
                    <source type="video/mp4" src={v} />
                    <p>Your browser does not support the video element.</p>
                </video>
                {
           
                  /*   <img className='playBtn' style={s} src={arrow} alt='play' onClick = { ()=>this.handleClick() }/> */
                }
                
            </div>
        )
    }
}

export default Video
