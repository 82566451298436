import React, { Component } from 'react'
import pdficon from './svg/pdf.svg'
//import Loader from './Loader'

var server

class DocumentDisplay extends Component {

    componentDidMount() {
        server = window.server

        let self = this

        try {
            self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
        } catch (error) {
            let trySetData = setInterval(function () {

                if (self && self.refs && self.refs.thisElement) {
                    self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
                    clearInterval(trySetData)
                }
            }, 250)
        }
    }

    render() {
        let { name, collapse } = this.props

        let height = 'var(--big)'

        return (
            <div className='submit_btn_container' data-display={collapse}>
                <div className={"checkListItemRow " + this.props.section} ref='thisElement'  >
                    <div className="documentName"
                        onClick={() => {
                            console.log('open pdf file: %o', this.props.file)
                            /* var win = window.open(this.props.file, '_blank');
                            win.focus() */

                            window.location.href = this.props.file
                        }
                        }>
                        <span style={{ textDecoration: 'none', fontSize: 'var(--normal)' }} className='breakWords'>{ (name + ' anzeigen')  || 'Zusätzliches Dokument öffnen'}</span>
                        {/* <img src={pdficon} alt='pdf' className='pdfIcon' style={{ height: height }} /> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default DocumentDisplay


