import React, { Component } from 'react'
import './css/GTCHint.css'

class GTCHint extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            show: false
        }
    }

    hide() {
        this.setState({ show: false })
    }

    show() {
        this.setState({ show: true })
    }

    accept() {
        this.setCookie('AGB', (new Date()).toLocaleString(), 365)
        this.hide()
    }

    accepted() {
        return Boolean(this.getCookie('AGB'))
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    render() {
        if (!this.state.show)
            return null

        return (
            <div className="GTCHint" >
                <div className='GTCHintHeader'>AGB Akzeptieren</div>
                <div className='GTCHintDisclaimer'>Zum Fortfahren müssen Sie unsere <span>AGB's</span> akzeptieren.</div>
                <div className='GTCHintButtons'>
                    <div className='GTCHintCancel' onClick={() => this.hide()}>
                        Abbrechen
                    </div>
                    <div className='GTCHintAccept' onClick={() => this.accept()}>
                        Akzeptieren
                    </div>
                </div>
            </div>
        )
    }
}

export default GTCHint
