import React, { Component } from 'react'

class Note extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            show: false,
            rollout: 'closed'
        }

        this.closing = false

        this.show = this.show.bind(this)
    }

    componentDidMount() {
        this.props.setNoteRef(this.props.i, this) //
    }

    componentDidUpdate() {
        let self = this        

        // ---[click]---> show=true --->  if:'closed'  ---[delay]---> set:'opened'
        // (css: display=block before animation start)
        if (!this.closing && this.state.show && this.state.rollout === 'closed'){
            setTimeout(function () {
                self.setState({ rollout: 'opened' })
            }, 40) // ~1frame
        }
        
        // ---[click]---> setState:'closed' ---> if:!closing ---[delay]---> show=false
        // (delayed css: display=none after animation end)
        if ( this.closing && this.state.show){
            this.closing = false
            setTimeout(function () {
                self.setState({ show: false })
            }, 400) // ~2x animation duration
        }
    }

    show() {
        if (!this.state.show) {
            let self = this
            self.setState({ show: true })
        }
    }

    render() {
        let display = this.state.show ? 'block' : 'none'

        let padding = this.state.rollout === 'opened' ? '45px' : '0'
        let height  = this.state.rollout === 'opened' ? '100vh' : '0vh'

        return (
            <div className='Warning' style={{ display: display, padding: padding, maxHeight: height }}>
                <div>
                    {this.props.content}
                </div>
                <div className='OK' onClick={() => { this.setState({ rollout: 'closed' }); this.closing = true }   }>
                    OK
            </div>

            </div>
        )
    }
}

export default Note


