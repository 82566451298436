import React, { Component } from 'react'


class TextBox extends Component {

    componentDidUpdate(){
        console.log('TextBox updated')
    }

    render() {
        let { message, e, css } = this.props
        let { short, label, button, iconClass } = this.props.e
        let classname = e.type === 'redbox' ? 'RedBox' : 'TextBox'
        let text = message

        /* 
            message kann
            - text
            - text mit html-elementen (nur b und br), oder
            - html-string 
            sein

            abhängig davon wird es in .tb_description gerendert
         */
        let match_html = message.match(/<.*?>/)
        let message_is_html = match_html && match_html.index === 0
        let message_contains_html = match_html && match_html.index > 0

        /* (legacy) */
        if( message_contains_html ){

            let lines = String( message ).split(/<br>|<br\/>|<\/br>/)

            text = lines.map( (line, index) => {
                let fragments = line.split(/<b>|<b\/>|<\/b>/)
                let bolded = fragments.map( (fragment, i) => {
                    return i%2 ? <b key={i}>{fragment}</b> : fragment
                })
                return <span key={index}>{bolded}<br/></span>
            })
        }
        

        return (
                <div className={classname} style={ css ? css : {}}>
                    <table >
                        <tbody >
                            <tr>
                                {
                                    iconClass ? 
                                        <td style={{ width: '1%', textAlign: 'left', verticalAlign: 'middle' }}><span className={iconClass + ' icomoon_general'}></span></td>
                                    :
                                        null
                                }
                                <td style={{ width: '94%', textAlign: 'left' }}>
                                
                                
                                    {
                                        message_is_html ?
                                            <div className='tb_description' /* style={ css ? css : {}}  */dangerouslySetInnerHTML={{ __html: text }} ></div>
                                        :
                                            <div className='tb_description' /* style={ css ? css : {}} */ >
                                                { text }
                                            </div>
                                    }
                                    { 
                                        short ? 
                                            <div className='show_more' onClick={()=>{
                                                
                                                window.show_popup()

                                                let head = document.getElementById('show_more_head')
                                                let body = document.getElementById('show_more_body')
                
                                                head.innerHTML = label || 'Information'
                                                body.innerHTML = short

                                            }}><span>{ '?' || button || 'Info'}</span></div> 
                                        :
                                        ''
                                    }
                                
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        )
    }
}

export default TextBox


