import React, { Component } from 'react'
// import {isAndroid, isMobile} from 'react-device-detect'
// import introGif from './gif/3y6mlh.gif'


class WDIntroduction extends Component {

  render() {
//    var HTMLOUT = { 'isUndefined': false }
//    if( typeof window.HTMLOUT === 'undefined' )    HTMLOUT = { 'isUndefined': true }
  
    let top = {top: '7em'}
//    if (isAndroid && !HTMLOUT.isUndefined) {
//        var t = window.HTMLOUT.getIdToken()
//        return (<div>isandroid and htmlout: {t}</div>)
//    }
//    if (isAndroid ) {
//        return (<div>isandroid only</div>)
//        }
//        if (!HTMLOUT.isUndefined) {
//            return (<div>HTML out defined (ohne isandroid)</div>)
//            }

let pid = this.props.pid
    let introText = this.props.intro.split('%CODE%')
    if( introText.length === 1 ){
      introText[1] = ''
      pid = ''
    }

    return (
        <div id={this.props.id} style={top} className='WDIntroContainer'>
          {/* <div className='gifContainer'>
            <img src={introGif} alt='intro'></img>
          </div> */}
          
          {
            this.props.intro ?
                <div>
                    <div className='wdintroduction' >
                        
                    {/* <div id='setupQRCodeID'>QR-Code ID <span id='setupPID'>{ this.props.pid }</span></div> */}
                    <span className='wdintroMessage'>{ introText[0] }<b className=''>{ pid }</b>{introText[1]}</span>              
                    </div>
                    {/* <div className='Tip'><b>Tipp:</b><br/> Das Etikett am besten gleich anbringen. <span className='TipSmall'>Sonst findest Du es auf dem Bogen nicht mehr so leicht wieder.</span></div> */}
                </div>

              
            :
            null
          }
          
        </div>
      )
  }
}  

export default WDIntroduction


