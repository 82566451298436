import React, { Component } from 'react'
import './css/AGB.css'


class AGB extends Component {
  render(){ 
     
    return (
      <div>
        <p className="agbContainer" >
        By using this service you agree to the <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_Igf2SQq0zE' id='datenschutz'  rel="noopener noreferrer" >Privacy Declaration</a> and to our <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_0JrTXgcudm' id='agb'  rel="noopener noreferrer" >Terms of Service</a>. 
        <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_43CfLRWtui'  rel="noopener noreferrer" > About us</a>

        <br/>
        <br/>

        <b>Information about Cookies:</b> If you do not agree to the use of Cookies - please stop here and dont use this service. 
        </p>
      </div>
      
      
    )
  }
}

export default AGB
