import React, { Component } from 'react'

class NewProtocol extends Component {

    render() {
        let { app } = this.props
        let { VIEW } = app.mode

        return ( VIEW && app.permid.CHECKLIST && (app.type.VB || app.type.CDAP) ?
            <div className='introButton' style={{ backgroundColor: '#00ab63' }} onClick={() => window.location.href.append('mode=checklist').refresh() } >neuer Eintrag</div>
            :
            null
        )
    }
}

export default NewProtocol
