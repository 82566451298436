import React, { Component } from 'react'
import Header from './Header'
import Cover from './Cover'
import Services from './Services'
import './css/ServiceSelector.css'

const defaultTabs = ['leer', 'bestellen', 'message']

class ServiceSelector extends Component {
    constructor(props, context) {
        super(props, context)

        window.orientationDependentElements = []
        this.cover = null
        this.services = null

        this.state = {
            language: 'de'
        }

        this.meta = {
            lang: this.state.language,
            tabs: this.get('tabs') ? this.get('tabs').split('|') : defaultTabs,
            url: 'http://my-qr.io/nn_debug/?fromSS=true&type=nntype&pid=' + this.get('pid') + '&tab='
        }
    }

    componentDidMount() {
        this.addOrientationEventListener()
    }

    addOrientationEventListener() {
        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function () {
                window.setTimeout(() => {
                    let ode = window.orientationDependentElements
                    if (Object.keys(ode).length) {
                        Object.keys(ode).map((n) => {
                            return ode[n].orientationChange()
                        })
                    }
                }
                    , 40)
            }, false)
        }
    }

    get(urlparam) {
        return decodeURIComponent((new RegExp('[?|&]' + urlparam + '=([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null
    }

    render() {
        let l = this.state.language
        return (
            <div className="ServiceSelector" >
                <Header lang={l} parent={this} />
                <Cover lang={l} ss={this} ref={(c) => this.cover = c} />
                <Services ss={this} ref={(c) => this.services = c}/>
            </div>
        )
    }
}

export default ServiceSelector
