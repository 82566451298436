import React, { Component } from 'react'

import './css/ServicePhone.css'

class ServicePhone extends Component {
  render() {
    return (
      <div className="ServicePhone flexItem">
        ServicePhone
      </div>
    )
  }
}

export default ServicePhone
