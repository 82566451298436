import React, { Component } from 'react'


class Image extends Component
{

    render()
    {
        let { src, e, app } = this.props
        let path = src || e.src
        let data = e.data
        let css = e.css || {} 

        if( data && e.selfIndex && app )
        {
            // img später aus dem json entfernen
            if( !app.cached_image_indices )
                app.cached_image_indices = []

            app.cached_image_indices[ e.selfIndex ] = e.selfIndex
        }

        try
        {
            let image
            if( !data )
            {
                if ( path.match( /^http/ ) )
                    image = path
                else
                    image = require( `${ path }` )
            }
            else
            {
                image = data
            }

            return <div className={ e.className || 'image' } ><img src={ image } alt='missing' style={ css }/></div>
        } catch ( error )
        {
            return null
        }
    }
}

export default Image


