import React, { Component } from 'react'
import mws_conf from '../mws.js'

class File extends Component
{
    constructor( props, context )
    {
        super( props, context )

        this.title = this.props.meta.title
        this.last_update = this.props.meta.last_update
        this.filename = this.props.meta.filename
        this.perm_id = this.props.meta.perm_id
        this.init_object_path()

        if( this.last_update ){
            this.last_update = this.last_update.replace('.0','')
        }

        if( this.props.no_margin ){
            this.css = { margin: 0 }
        }else{
            this.css = {}
        }
    }


    init_object_path(){
        let op = mws_conf.OBJECT_PATH
        if( op ){
            if( !op.match(/^\//) ){
                op = '/'+op
            }
            if( !op.match(/\/$/) ){
                op = op+'/'
            }
        }

        this.object_path = op
        return op
    }
    

    render()
    {

        let subd     = mws_conf.EDP_SUBD ? mws_conf.EDP_SUBD + '.' : ''
        let domain   = mws_conf.DOMAIN
        let file_url = this.props.app.helper + '/pdf?f=' + this.perm_id //`http://${subd}${domain}${this.object_path}_${this.perm_id}`  

        return (
            <div className='File Inset' style={ this.css }>
                <a href={file_url} target='_blank'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='files'><span className='icomoon_file icomoon_general'></span></td>
                                <td className='left'>
                                    <div>{ this.title }</div>
                                    {/* <div className='LastUpdate'>{ this.last_update } - { this.perm_id }</div> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </a>
            </div>
        )
    }
}

export default File

