module.exports = 
    {   
        localhosts: '192.168.178.32|192.168.0.115|localhost',
        uqrcServer: 'uqrc-helper.whatsdown.com',
        debugToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJNQUciLCJleHAiOjE1NzA1NDYzNTAsInVzZXJfaWQiOiIxYTcwMTRiNy02MDViLTExZTktOTZjZS0wNjIxZTM2OGZhZmEiLCJ1c2VyX25hbWUiOiJzeXN0ZW1fd2R3ZWJ1aSIsImlhdCI6MTU3MDQ1OTk1MH0.V4CEqV3Dvk70511oJs3MG7BIhklNZ_ZWgDkjdzyGjFo',
        existsError: '[Request-Error] QR-Code exists: %SERVER_RESPONSE (Wrong/Expired Token)',
        notFoundError: '[Request-Error] QR-Code not found',
        createError: '[Request-Error] QR-Code create: %SERVER_RESPONSE (Wrong/Expired Token)',
        htmlDebugElementId: 'debug'
    }

