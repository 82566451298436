import React, { Component } from 'react'
import Impressum from './Impressum'

import arrow from './svg/icon_arrowRight.svg'

class Legal extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selected: this.props.data.focus || this.props.root.state.singleService
    }
  }

  close() {
    this.setState({ selected: false })
  }

  render() {
    let s = this.state.selected

    return (
      <div className='line' onClick={() => {
        this.props.root.closeAll(this.props.index)
        this.setState({ selected: !this.state.selected })
      }
      }>
        <div className={s ? 'head bg94L' : ''} >
          <div className={'bg97L flex' + (s ? ' bgblue' : '')}>
            <img className={'list_icon' + (s ? ' invert' : '')} src={this.props.buttonIcon} alt='service' />
          </div>
          <span className={'lineName' + (s ? ' bold_gray' : '')}>
            {this.props.data.text}
          </span>
          <div className='more flex'>
            <img className={'lineMore' + (s ? ' rot90' : '')} src={arrow} alt='service' />
          </div>
        </div>
        <Impressum selected={this.state.selected} intro={this.props.data.text} root={this.props.root}/>
      </div>
    )
  }
}

export default Legal
