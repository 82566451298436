import React from 'react'

// http://localhost:3000/cdap/zqrhg5?cache=false&mode=checklist&localtemplate=whatsdown&construct=true
// https://youtu.be/SuQRxdDCMh8?t=1991

class SimpleInput extends React.Component {
    constructor(props) {
        super(props)

        this.field = this.props.field
        this.parent = this.props.parent
        this.i = this.props.i
        this.style = {
            width: '100%',
            resize: 'vertical'
        }

        this.parseValue = () => {
            let {value} = this.props 

            if( typeof value === 'object' ){
                try {
                    let jstr = JSON.stringify( value )
                    this.value_is_object = true
                    
                    return jstr
                } catch (error) {
                    console.log(error.message)
                    this.parent.error_message = error.message
                    return value
                }
            }else{
                return value || ''
            }
        }

        this.state = {
            value: this.parseValue(),
            textarea: false,
            border: { border: 'none'}
        }

        this.handleChange = this.handleChange.bind(this)
    }



    reset(){
        let self = this
        this.switching_state = true
        if( this.state.value !== this.parseValue() ){
            window.show_popup( 
                'Zurücksetzen bestätigen', 
                'Sind Sie sicher, dass Sie dieses Feld den Standardwert zurücksetzen möchten?', 
                {
                    okay: {
                        name: 'Ja, zurücksetzen', 
                        callback: function(){
                            self.setState({
                                value: self.parseValue()
                            })
                        }
                    }, cancle: {
                        name: 'Nein, abbrechen'
                    }
                }
            )
        }else{
            window.show_popup( 
                'Vorgang nicht möglich', 
                'Das Feld wurde nicht geändert und kann nicht zurückgesetzt werden.', 
                {
                    okay: {
                        name: 'Verstanden'
                    }
                }
            )
        }
    }

    toggle(){
        this.switching_state = true
        let val = this.state.value
        let ta = this.state.textarea

        if( ta ){
            if( val.match(/\n/) ){
                val = val.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim()
            }
        }

        this.setState({
            textarea: !ta,
            value: val
        })
        return !ta
    }


    update(){
        let v = this.parseValue()
        this.setState({
            value: v
        })
    }


    reference(){
        if( !this.parent.inputs ){
            this.parent.inputs = []
        }

        this.parent.inputs[this.i] = this
    }

    componentDidMount(){
        this.reference()
    }

    componentDidUpdate(){
        this.reference()
            
        if( !this.state.textarea ){
            let de = this.dom_element_input
            if( de ){
                if( de.clientWidth < de.scrollWidth || this.value_is_object ){
                    this.toggle()
                }
            }
        }
    }

    getErrorMessage( err ){
        return `Warnung: Syntax-Error in JSON-Feld "${this.field}"! (${err.message})`
    }

    onFocus(){
        let ta = this.dom_element_textarea

        //let height = { height: `${ 4 + lines*2.1 }rem` }

        if( ta.clientHeight < ta.scrollHeight ){
            // clamp
            let clamped_height = Math.min(ta.scrollHeight, window.innerHeight*0.8 )

            ta.style.height = clamped_height+'px'
        }
    }

    handleChange(event) {
        let {value} = event.target
        let border = {}

        if( this.value_is_object ){
            try {
                JSON.parse( value )
                this.parent.error_message[ this.i ] = null
            } catch (error) {
                this.parent.error_message[ this.i ] = this.getErrorMessage( error )
                border = { border: '4px solid red'}
            }
            this.parent.showDiscription( this.field )
        }

        this.setState({ 
            value: value,
            border: border
        })
    }

    render() {
        let { textarea, value, border } = this.state
        let height = {}

        if( textarea ){
            if( this.switching_state && !value.includes('\n' )){
                this.switching_state = false
                value = value.replace(/\{/g, '{\n    ').replace(/\}/g, '\n}\n').replace(/\,/g, ',\n    ').replace(/:/g, ' : ')
            }
            if( value ){
                let mat = value.match(/\n/g)
                if( mat ){
                    let lines = mat.length
                    height = { height: `${ 4 + lines*2.1 }rem` }
                }else{
                    height = { height: `${ 4 + 2.1 }rem` }
                }
            }
            return <textarea type="text" value={ value } style={Object.assign( {}, this.style, border, height)} onChange={ this.handleChange }  onFocus={ ()=>this.onFocus() } ref={(e)=>this.dom_element_textarea = e}></textarea>
        }else{
            // reformattierung (?)
            if( this.switching_state && typeof value === 'string'){
                this.switching_state = false
                value = value.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim()
            }
            return <input type="text" value={ value }  style={Object.assign( {}, this.style, border)} disabled={ this.props.disabled } onChange={ ()=>this.handleChange() } ref={(e)=>this.dom_element_input = e}/>
        }
    }
}

export default SimpleInput