import React, { Component } from 'react'


class Back extends Component
{

    home(){
        let home = ''
        let query = document.location.search
        let url_params = new URLSearchParams( query )
        let { app, e } = this.props

        if( e.home ){
            home = e.home
        }else{
            let url_usecheckliste = url_params.get('usecheckliste')
            if( url_usecheckliste ){
                let usecheckliste = localStorage.getItem( url_usecheckliste )
                if( usecheckliste ){
                    home = JSON.parse( usecheckliste ).home
                }
            }else{
                
                let object_qrid = localStorage.getItem( app.pid )
                if( object_qrid ){
                    home = JSON.parse( object_qrid ).home
                }
            }
        }

        if( home ){
            window.location.href = home
        }
    }

    render()
    {
        let { e, app } = this.props
        let { icon, title, css } = e


        return <div className='home' style={ css } onClick={ () => this.home() } style={  css  }>
            <table>
                <tr>
                    <td style={{width: "0%"}}>
                        <span className='modifier_button_icon icomoon_general icomoon_home'></span>
                    </td>
                    <td className='wordwrap'>
                        <div style={{textAlign: "left", padding: "0 1rem"}} >{title}</div>
                    </td>
                </tr>
            </table>
        </div>
    }
}

export default Back


