import React, { Component } from 'react'

import './css/Default.css'

class Default extends Component {
  render() {
    return (
      <div className="Default">
        Error: No such service. ({this.props.name})
      </div>
    )
  }
}

export default Default
