import React, { Component } from 'react'
import icon from './svg/local.svg'
import uqrc from './svg/uqrc.svg'

const BASEDOMAIN = window.location.hostname.split('.')[0]
const LOCALDEV = BASEDOMAIN.includes('192') || BASEDOMAIN.includes('localhost')
const RELEASE_CANDIDATE = BASEDOMAIN.includes('uqrc')

class LocalDevIcon extends Component {

    showUserAgent(){
        let header = document.getElementsByClassName('header')[0]
        if( header ) header.innerHTML = window.navigator.userAgent
    }

    render() {
        if(LOCALDEV)
            return <img id='local' src={icon} alt='local mode' onClick={ this.showUserAgent }/>

        if(RELEASE_CANDIDATE)
            return <img id='local' src={uqrc} alt='uqrc mode' onClick={ this.showUserAgent }/>

        return ''

        //return LOCALDEV ? <img id='local' src={icon} alt='local mode' /> : ''
    }
}

export default LocalDevIcon


