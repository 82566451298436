import React, { Component } from 'react'
import folder_background from './png/pharma.png'

class StreamGroup extends Component {
    constructor(props, context) {
        super(props, context)

        let fpid = this.props.folder[ this.props.permid ]
        if( fpid ){

            this.title = fpid.title || (fpid.code === 401 ? fpid.response : '_no_title_')
            this.perm_id = fpid.perm_id || fpid['perm-id']
            this.last_update = fpid.last_update
            this.image = fpid.image
        }else{
            this.title = this.props.app.displayTitle || 'Title'
            this.perm_id = 'Perm ID'
            this.last_update = 'Last Update'
        }

        this.is_fullscreen = this.props.fullscreen
        this.parent = this.props.parent
        this.is_root = this.props.root

        if( this.last_update ){
            this.last_update = this.last_update.replace('.0','')
        }
    }

    render() {
        // back to parent (permid) ? -> browser history
        // let css = this.props.empty ? { cursor: 'default' } : {}
        let css = this.is_root ? { padding: '1.1rem', fontWeight: 600 } : {}

        let is_open = this.props.filelist.did_request

        if( this.is_fullscreen ){
            
            return (
                <div className='FolderNameFullscreen' style={{ backgroundImage: `url(${folder_background})` }}>
                    <table>
                        <tbody>
                            <tr>
                              
                                <td className='fullscreen_title'>
                                    <div>{ this.title }</div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }else{

            if( this.is_root ){
                return null
            }else{

                let root_image = this.props.e && this.props.e.root_image ? this.props.e.root_image : ( this.image ? this.props.app.helper + '/img?f=' + this.image : '' )
    
                let stream_group_lead_image_css = {
                    backgroundImage: `url('${ root_image || 'https://images2.bovpg.net/r/media/1/1/2/8/2/128251.jpg'}')`,
                    height: '6rem',
                    width: '6rem',
                    display: 'block',
                    backgroundSize: 'cover',
                    marginRight: '1rem',
                    borderRadius: '0.2rem'
                }
    
                return (
                    <div className='StreamGroup' style={css} onClick={()=>{this.props.toggle()}}>
                        <table>
                            <tbody>
                                <tr>
                                    {
                                        this.is_root?
                                        <td className='folders'><span style={ stream_group_lead_image_css }></span></td>
                                        :
                                        <td className='folders'><span style={ stream_group_lead_image_css }></span></td>
                                    }
                                    <td className='left'>
                                        <div dangerouslySetInnerHTML={{__html: this.title}}></div>
                                        {/* <div className='LastUpdate'>{ this.last_update }{ this.last_update? ' - ' : '' }{ this.perm_id }</div> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
        }
    }
}

export default StreamGroup

