import React from 'react'
import BlueErrorPage from './variants/empty/Startpage'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      error: false,
      message: null
     };
  }

  static getDerivedStateFromError(err) {
    /* console.log('error boundary catched: %o', err.stack)
    console.log('error boundary catched: %o', JSON.stringify(err, Object.getOwnPropertyNames(err))) */
    return { error: true, message: err }
  }

  componentDidCatch(error, info){
    console.log('catched error: %o', error)
    //this.setState( { message: error } )
  }

  render() {
    console.log('err boundary %o', this.state.error)

    if( this.state.message )
      console.log('err message %o', this.state.message.message)

    if (this.state.error) {

      const server = window.server
      if( server )
        if( this.state.message )
          server.log({ stack_trace: this.state.message.stack.substring(0, 300) + '...' , source: '/components/ErrorBoundary', log_level: 'fatal' })
      
      return <div>{ this.state.message ? this.state.message.stack : 'ERROR'}</div>

        // fallback UI
       /*  try {
            if(this.state.error.props) // nur error message ausgeben
                return <BlueErrorPage text={this.state.error.props}/>
            else // 1. und 2. zeile aus error stack extrahieren, und die url in klammern dahinter weglassen
                return <BlueErrorPage text={this.state.error.stack.split('\n')[0] + ' ' + this.state.error.stack.split('\n')[1].replace(/\(.*\)/,'')}/>
        } catch (error) {
            return <BlueErrorPage text='ErrorBoundary failed to parse Error'/>
        } */

    }
    return this.props.children;
  }
}