import React from 'react'

class ResetButton extends React.Component {
    constructor(props) {
        super(props)

        this.disabled = this.props.disabled
        this.parent = this.props.parent
        this.i = this.props.i
    }

    click(){
        this.parent.inputs[this.i].reset()
    }

    render() {
        if( !this.disabled ){
            return <div onClick={()=>this.click()} className={`icomoon_general icomoon_undo`}></div>
        }else{
            return null
        }
    }
}

export default ResetButton