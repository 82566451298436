import React, { Component } from 'react'
import NewReport from './NewReport'
import FastSend from './FastSend'
import Document from './Document'
import Support from './Support'
import GTCHint from './GTCHint'
import Legal from './Legal'

import msg from './svg/messaging.svg'
import grow from './svg/icon_minMax.svg'
import drop from './svg/icon_maxMin.svg'

class Group extends Component {
    constructor(props, context) {
        super(props, context)

        window.orientationDependentElements['group' + this.props.idx] = this
        this.selectedService = null
        this.serviceElements = ['Services']
        this.services = []
        this.AGB = null

        window.servicesJSON = [
            { text: 'FAQ', type: 'document', url: 'https://cbcdn2.gp-static.com/uploads/product_manual/file/202/HERO3_Plus_Black_UM_ENG_REVD.pdf' },
            { text: 'Bedienungsanleitung', type: 'document', url: 'https://cbcdn2.gp-static.com/uploads/product_manual/file/202/HERO3_Plus_Black_UM_ENG_REVD.pdf' },
            { text: 'Toner leer', type: 'message', focus: false },
            { text: 'Papier leer', type: 'message' },
            { text: 'Drucker reagiert nicht', type: 'message' },
            { text: 'Nachricht verfassen', type: 'other', focus: true },
            { text: 'Support Anruf', type: 'call' },
            { text: 'Impressum & AGB', type: 'legal' }
          ]

        this.state = {
            maximized: false,
            selectedID: -1,
            fullHeight: this.getFullHeight(),
            singleService: window.servicesJSON.length === 1
        }
    }

    orientationChange() {
        this.setState({
            fullHeight: this.getFullHeight()
        })
    }

    getFullHeight() {
        return this.isPortrait() ? this.serviceElements.length > 1 ? 60 : 90 : 100
    }

    isPortrait() {
        return window.innerHeight > window.innerWidth
    }

    service(i, c) {
        this.services[i] = c
    }

    closeAll(except) {
        return this.services.map((s, i) => {
            if (except !== i && s.close)
                return s.close()

            return null
        })
    }

    selectChild(s) {
        this.selectedService = s
    }

    agb() {
        let agb = this.AGB

        if (agb && agb.accepted())
            return true

        agb.show()
        return false
    }

    toggleMaxSize(i) {
        this.setState({ maximized: !this.state.maximized })

        window.setTimeout(() => {
            let max = this.state.maximized

            this.props.ss.cover.minimize(!max)
            this.props.services.setFullscreen(max)
            this.setState({ selectedID: max ? i : -1 })
        }, 40)
    }

    render() {
        
        // text:'Dokumente',type:'doclist' --> zeigt die dokumente aus objectinfov2 an, wenn keine dokumente dann entfällt diese Überschrift

        return (
            this.serviceElements.map((e, i) => {

                let hide = this.state.selectedID >= 0 ? this.state.selectedID !== i : false
                let show = !hide
                let max = this.state.maximized
                let ph = this.state.fullHeight
                let ll = this.serviceElements.length

                let height = '0'
                if (show)
                    height = (max ? ph : ph / ll) + 'vh'

                return (
                    <div className="Group" style={{ height: height }} key={Math.random()}>
                        <GTCHint ref={(c) => this.AGB = c} />
                        <img className='grow' src={max ? drop : grow} alt='grow' onClick={() => this.toggleMaxSize(i)} />
                        <div className='groupName'>
                            {this.serviceElements[i]}
                        </div>
                        <div className='groupDetail'>
                            Wählen Sie eine Aktion aus der Liste aus. Sie können Nachrichten verschicken, Dokumente öffnen, oder den Support kontaktieren.
                        </div>
                        {
                            window.servicesJSON.map((id, s) => {
                                switch (id.type) {
                                    case 'document':
                                        return <Document key={s} index={s} ref={(c) => this.service(s, c)} content={id} />
                                    case 'message':
                                        return <FastSend key={s} index={s} ref={(c) => this.service(s, c)} buttonIcon={msg} data={id} root={this} />
                                    case 'other':
                                        return <NewReport key={s} index={s} ref={(c) => this.service(s, c)} buttonIcon={msg} data={id} root={this} />
                                    case 'call':
                                        return <Support key={s} index={s} ref={(c) => this.service(s, c)} text={id.text} root={this} />
                                    case 'legal':
                                        return <Legal key={s} index={s} ref={(c) => this.service(s, c)} buttonIcon={msg} data={id} root={this} />
                                    default:
                                        return <div>type <b>{id.type}</b> not supported</div>
                                }
                            })
                        }

                    </div>
                )
            })
        )
    }
}

export default Group
