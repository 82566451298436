import React, { Component } from 'react'
import CheckboxSingle from './CheckboxSingle'
import TextBox from './TextBox'

class CheckboxContainer extends Component {
    constructor(props, context){
        super(props)

        this.state = {
            redBorder: false
        }

        this.app = this.props.app
        this.e = this.props.e
        this.id = this.props.e.id
        this.name = this.props.e.name
        this.text = this.props.e.text
        this.message = this.props.e.message + '' // copy (ohne referenz)
        this.message_destination = this.props.e.message_destination
        this.required = this.props.e.required
        this.show_checkboxes = this.is_true( this.props.e.show_checkboxes )
        this.selection_style = this.props.e.selection_style || 'checkbox' // 'line'
        this.edit_selected = this.props.e.edit_selected
        this.radio = this.props.e.checkbox_style === 'radio'
        this.multi_select = this.is_true( this.props.e.multi_select )
        this.width = parseInt( this.props.e.width || 1 )
        this.options = this.props.e.options
        this.options_initial = JSON.parse(JSON.stringify(this.props.e.options))
        this.children = []
        this.disclaimer = this.text ? <TextBox message={this.props.e.text} e={this.e} /> : null
        this.border_color = '#a8a8a8'

        this.html = this.create_html() 
    }

    is_true( val ){
        return val === true ||
            val === "true" ||
            val === "1"
    }

    make_options_diff(){
        if( !this.diff ){
            this.diff = []
        }

        for (let i = 0; i < this.options.length; i++) {
            const opt_id = this.options[i].id
            const opt_v = this.options[i].value
            const opti_v = this.options_initial[i].value
            
            if( opt_v !== opti_v ){
                // value editiert
                if( opt_v > 0 ){
                    // 1
                    this.diff[i] = opt_id
                }
                
                if( opt_v === 0 ){
                    // 0
                    this.diff[i] = null
                }
            }else{
                // velue nicht editiert
                this.diff[i] = null
            }
        }

        this.diff = this.diff.filter(Boolean)
        console.log(this.diff)
    }


    set_checkbox_state( index, value ){
        // anderere abwählen
        if( !this.multi_select ){
            for (let i = 0; i < this.options.length; i++) {

                if( index !== i ){
                    if( this.options[i].value !== -1 ){

                        let reset = this.options_initial[i].value !== 1 && !this.edit_selected
                        if( reset ){

                            this.options[i].value = 0
                            if( this.options_initial[i].info ){
                                this.options[i].info = this.options_initial[i].info
                            }

                            this.children[i].setState({ value: 0})
                        }
                    }
                }
            }
        }

        // set
        this.options[ index ].value = value
        if( this.options_initial[index].info ){
            if( value === 0 ){
                //reset
                this.options[index].info = this.options_initial[index].info
            }else{
                this.options[index].info = this.app.replace_percent( this.options_initial[index].info )
            }
        }

        // editierte values merken
        this.make_options_diff()

        if( this.props.e.message ){

            if( this.diff.length ){
                this.message = this.props.e.message.replace( '%new_selections%', this.diff )
            }else{
                this.message = this.props.e.message
            }

            console.log(this.message)
        }

        // update renderData
        this.app.renderData[ this.props.e.selfIndex ].options = this.options
        this.app.renderData[ this.props.e.selfIndex ].diff = this.diff // neu ausgewählte
    }

    create_html(){
        let width = this.width
        let table = []
        let row = []
        let td_style = {
            textAlign: width === 1 ? 'left' : 'center' // in json festlegen?
        }

        let is_first_row = true

        for (let opt = 0; opt < this.options.length; opt++) {
            const option = this.options[opt]


            let left_td = opt%width === 0

            row.push(
                <td style={ td_style } key={opt}>
                    <CheckboxSingle container={this} option={option} index={opt} key={opt} first={left_td}/>
                </td>
            )
            
            let single_line = width === 1
            let first_el = opt === 0
            let new_row = (opt+1)%width === 0
            let last_el = opt === this.options.length-1

            

            if( single_line || 
                last_el || 
                (new_row && !first_el) || 
                (first_el && last_el) ){

                let css = {}
                if( width > 1 ){
                    css = { borderBottom: '1px solid ' + this.border_color }
                    if( is_first_row ){
                        css.borderTop = '1px solid ' + this.border_color 
                        is_first_row = false
                    }
                }

                table.push(
                    <tr key={opt} style={css}>
                        {
                            row
                        }
                    </tr>
                )

                row = []
            }            
        }

        return <table className='CheckboxTable'>
            <tbody>
                {
                    table
                }
            </tbody>
        </table>
    }

    render() {
        let cb_cont_css = this.state.redBorder ? { borderRight: '6px solid red' } : {}

        return (
            <div className='CheckboxContainer' style={cb_cont_css}>
                {
                    this.name ? 
                    <div className='CheckboxContainerName'>
                        {
                            this.name
                        }
                    </div>
                    :
                    null
                }
                {
                    this.disclaimer
                }
                {
                    this.html
                }
            </div>
        )
    }
}

export default CheckboxContainer
