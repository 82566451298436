import React, { Component } from 'react'
import aidentIcon from './png/ainewlogo.png'
import boehringerIcon from './svg/boehringer.svg'
import christIcon from './svg/christ.svg'
import dwaIcon from './svg/dwa.svg'
import firstad from './variants/1stad/svg/1stad_logo.svg'
import menuIcon from './svg/menu.svg'
import wdText from './svg/whatsdown_schriftzug.svg'
import wdLogo from './png/wd_logo.png'
import cdaLogo from './svg/wdcda_uqrc_icon.svg'
// import cdapLogo from './png/corona_assist_manager.png' //'./png/cda256.png'
import cdapLogo from './variants/cdap/png/presence_klein-1.png' //'./png/cda256.png'
import rhenusLogo from './svg/rhenus.svg' 
import ihkLogo from './svg/ihk.svg' 

import eagLogo from './svg/eag_logo.svg'
import noLogo from './svg/nologo.svg'
import dhcLogo from './svg/swissonemed_inverted.svg'
import adokLogo from './png/adok_logo.png'

import settings from '../config/settings.js'

class Header extends Component {
    constructor (props, context) {
        super(props, context)

        this.icon = []
        this.icon['adok']       = adokLogo
        this.icon['oma']        = noLogo
        this.icon['aident']     = aidentIcon
        this.icon['ai']         = aidentIcon
        this.icon['header']     = boehringerIcon // 'legacy'
        this.icon['boehringer'] = boehringerIcon
        this.icon['nologo']     = noLogo
        this.icon['dhc']        = dhcLogo
        this.icon['rapidok']    = dhcLogo
        this.icon['christ']     = christIcon
        this.icon['dwa']        = dwaIcon
        this.icon['1stad']      = firstad
        this.icon['cda']        = cdaLogo
        this.icon['cdap']       = cdapLogo
        this.icon['rhenus']     = rhenusLogo
        this.icon['ihk']        = ihkLogo
       // this.icon['cdap']     = cdaLogo
        this.icon['wdeag']      = eagLogo
        this.icon['whatsdown']  = wdLogo

        this.local = settings.localhosts.includes( window.location.hostname ) ? ' (intern)' : ''
    }

    componentDidMount(){

        let root = document.getElementById('root')
        try {
            let header = document.getElementsByClassName('header')[0]
            let header_style = document.defaultView.getComputedStyle( header, null )
            let header_height = header_style.getPropertyValue('height')
            root.style.marginTop = header_height
        } catch (error) {
            root.style.marginTop = '10vmax'
        }
        /* try {
            let fsize = document.defaultView.getComputedStyle(document.getElementById('cdap'), null).getPropertyValue('font-size')
            document.getElementById('cdap').innerText = fsize + ' ' + navigator.userAgent
        } catch (error) {
            document.getElementById('cdap').innerText = error
        } */
    }

  render() {

    let {id, app, name} = this.props

    if( app.headerid_override )
        id = app.headerid_override

    let icon = this.icon[id]
    // let {pid} = app.pid
    let {VB, CDAP, BI, OMA, AD} = app.type
    let {SETUP} = app.mode
    let json_header_name = app.getRenderDataElement('header', 'name')
    name = VB && !SETUP ? app.displayTitle : ( name || json_header_name )


    let borderRadius = {}
    if(/* this.props.isAIdent */  app.ai || id === 'adok' ){
        borderRadius = { borderRadius: '100rem', height: '4rem', margin: '.25rem', padding: '.1rem' } //adok
    }

    let dhc_in_url = window.location.pathname.split('/').includes('dhc')
    if( app.headerid_override )
        dhc_in_url = false

    let is_dhc = id === 'dhc' || dhc_in_url // einfach "... = DHC" ?
    if( is_dhc )
    {
        borderRadius.display = 'none'
        name = <div >
            <img 
                style={{position: 'relative'}}
                src={this.icon['dhc']} 
                id='headerIcon' 
                alt='logo'
            />
        </div>
    }

    if( CDAP )
    {
        let style = {position: 'relative'}
        let icon = this.icon['cdap']
        if( this.props.e ){

            if( this.props.e.logo ){
                if( this.props.e.logo.match(/^http/) ){
                    icon = this.props.e.logo

                    
                }else{
                    if( this.props.e.logo.match(/^\.\//) ){
                        icon = require( `${ this.props.e.logo }` )
                    }else{
                        icon = this.icon[ this.props.e.logo ]
                    }
                }
            }

            if( this.props.e.logo === 'ihk' ){
                icon = this.icon[ this.props.e.logo ]
                style = {position: 'relative'/* , background: '#ededed' */}
            }

            let css = this.props.e.css
            if( css ){
                style = Object.assign( style, css )
            }
        }

        borderRadius.display = 'none'
        name = <div >
            <img 
                style={ style }
                src={ icon } 
                id='headerIcon' 
                alt='logo'
            />
        </div>
    }
    
    app.setHeaderIconUrl(icon)

    let header_css = {}
    if( id === 'adok' ){
        let hbg = app.getRenderDataElement('header', 'background')
        header_css = { background: hbg || 'var(--ad_color_gradient)'}
    }

    if( id === 'oma' ){
        let hbg = app.getRenderDataElement('header', 'background')
        header_css = { background: hbg || 'var(--oma_color_gradient)'}
    }

    if( is_dhc ){
        let hbg = app.getRenderDataElement('header', 'background')
        header_css = { background: hbg || 'rgb(35, 32, 33)'/*  '#d9d9d9' */}
    }

    if( CDAP ){
        let hbg = app.getRenderDataElement('header', 'background')
        // { background: hbg || 'white' /* '#e7e7e7' */, borderBottom: 'solid #cccccc 1px'}
    }

    if(BI){ 
        header_css = {color: 'black'}
    }

    if(AD){ 
        header_css = {color: 'black'}
    }

    if(OMA){ 
        header_css = {color: 'black'}
    }
    // background: linear-gradient(to left, #416677 0%, #59757f 100%);
    // background: linear-gradient(to left, hsl(199deg 29% 54%) 0%, hsl(196deg 18% 55%) 100%);

    if( !icon || is_dhc || CDAP )
        icon = this.icon['nologo']


    let header = null
    if ( id === 'whatsdown' )
        header = (
            <div id="WDHead">
                <img id="WDLogo" src={wdLogo} alt='logo'/>
                <div id='SVGHeadContainer'>
                    <img id="SVGHeadText" src={wdText} alt='logo'/>
                </div>                
                <div id="WDSubText">
                    { this.props.subtext || ('QR-Code Setup ' + this.local) }
                </div>
            </div>
        )
    else
        header = (
            <div>
                <div id={id} className='header' style={header_css}>
                    <img 
                        style={borderRadius}
                        src={icon} 
                        id='headerIcon' 
                        alt='logo'
                        onClick={
                            () => { 
                                let debug = document.getElementById( settings.htmlDebugElementId )
                                if( debug )
                                    debug.style.display = 'block'
                            }
                        }
                    />
                        {name}
                    <img 
                        src={menuIcon} 
                        id='menuIcon'
                        alt='menu'
                        onClick={ () => document.getElementById("menu").classList.toggle("open") }
                    />
                </div>
                <div id='menu'>
                    menu open
                </div>
            </div>
        )

    return header
  }
}  

export default Header
