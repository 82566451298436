import React, { Component } from 'react'
import wdLogo from './svg/wdLogo.svg'
import flavour from './svg/INDUSTRY.svg'
import './css/Header.css'

class Header extends Component {

  changeLanguage() {
    let p = this.props.parent
    let lg = p.state.language
    p.setState({ language: lg === 'de' ? 'en' : 'de' })
  }

  render() {
    return (
      <div className='Header' onClick={() => this.changeLanguage()}>
        <div className='Logo'>
          <img src={wdLogo} alt='WhatsDown'/>
        </div>
        <div className='Flavour'>
          <img src={flavour} alt='Flavour'/>
        </div>
      </div>
    )
  }
}

export default Header
