import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import Signature from './components/Signature'
import ErrorBoundary from './components/ErrorBoundary'
import PreRegisterLanding from './components/PreRegisterLanding'
import BlueErrorPage from './components/variants/empty/Startpage'

import ReactMarkdown from 'react-markdown'
import cdap_dsgvo from './markdown/cdap_dsgvo.js'
import cdap_agb from './markdown/cdap_agb.js'

/* import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
 */
// import mws_conf from './mws.conf'
const SENTRY_ON = false // mws_conf.SENTRY !== 'off'


global.CONTACT_DEFAULT = '_default'
global.CONTACT_URGENT = '_urgent'
global.CONTACT_DIRECT = '_directcall'

global.parseBool = function (val='') {
  switch (String(val).toLowerCase().trim()) {
      case 'false': case 'no': case '0': case '': case 'null': case 'undefined': return false
      default: return true
  }
}

/* 
  stackoverflow.com/q/122102:
  If you do not use Dates, functions, undefined, Infinity, 
  RegExps, Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, 
  Typed Arrays or other complex types within your object, a very simple 
  one liner to deep clone an object is: 
*/
global.clone = function (j={}) {
  try {
    return JSON.parse(JSON.stringify(j)) 
  } catch (error) {
    return {error}
  }
}

// stackoverflow.com/q/122102:
global.deep_clone = function clone(obj) {
  if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
      return obj

  if (obj instanceof Date)
      var temp = new obj.constructor() //or new Date(obj);
  else
      var temp = obj.constructor()

  for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj['isActiveClone'] = null
          temp[key] = clone(obj[key])
          delete obj['isActiveClone']
      }
  }
  return temp;
}


if( SENTRY_ON ){
  /* Sentry.init({
    dsn: "https://3c033a5c3fcc4d588fe2c55efd0b7bd9@o459737.ingest.sentry.io/5459256",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  }); */
}


const prereg = window.getURLParameter('mode')
switch (prereg) {
  case 'preregister':
    ReactDOM.render( <PreRegisterLanding key={0} />, document.getElementById('root'))
    break;

  case 'cdap_agb':
  
    // sobald andere flavors agb#s bekommen, in switch-default verschieben und mit if-block schalten
    ReactDOM.render(<div style={{padding: '2rem'}}><ReactMarkdown>{ cdap_agb }</ReactMarkdown></div>, document.body)
    break;

  case 'cdap_dsgvo':
  
    // sobald andere flavors dsgvo's bekommen, in switch-default verschieben und mit if-block schalten
    ReactDOM.render(<div style={{padding: '2rem'}}><ReactMarkdown>{ cdap_dsgvo }</ReactMarkdown></div>, document.body)
    break;

  default:

    const paths = window.location.pathname.split('/')
    try {

      const do_delete_cookies = paths.includes('deletecookies')
      const single_pathname = paths.length === 2

      if( do_delete_cookies && single_pathname )
      {
        window.showCookieDelete('Sollen die Cookies für diese Seite gelöscht werden?', true )
        break;
      }

      const show_signature = paths.includes('signature')
      if( show_signature && single_pathname )
      {
        ReactDOM.render(  <Signature> <App key={0} /></Signature> , document.getElementById('root'))
        break;
      }


    } catch (error) {
      ReactDOM.render( <BlueErrorPage text={'Fehler-Ursache: Es gab einen Fehler beim Löschen der Cookies: ' + error } />, document.getElementById('root'))
      break;
    }
    
    const valid_url = paths.length > 2
    if( !valid_url )
      ReactDOM.render( <BlueErrorPage text='Fehler-Ursache: Die aufgerufenene URL ist unvollständig oder enthält einen Tippfehler.' />, document.getElementById('root'))
    else
      ReactDOM.render(  <ErrorBoundary> <App key={0} /></ErrorBoundary> , document.getElementById('root'))
    break;
}


