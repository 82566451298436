import React, { Component } from 'react'
import './css/Startpage.css'
import AGB from './AGB'
//import cover from './svg/cdap_logout.svg'
import cover from '../../png/cda1024g.png'
import cover_gg from '../../png/cda1024gg.png'
import CDAPHeader from './CDAPHeader'

/* import ReactHtmlParser from 'react-html-parser' */


class Logout extends Component {
    constructor(props, context) {
        super(props, context)
        
        this.button_pressed = false
        this.button_element = null
        this.additional_buttons = []
        this.wait_for_completion = false
        this.showing_error = false
    }


    render() {
        let id = 'logout_button'
        let app = this.props.app
        let cname = app.cookie.name
        let anonym = Boolean(app.getCookie(cname)) ? '' : '' // '(anonym)'

        /* anonym = app.replace_percent('text <b>%cdap00%</b> bla.<p>Vielen Dank! - Ihr Eintrag wurde in der Corona-Anwesenheitsliste <b >%displayTitle%</b> gespeichert.<br/><br/>Um sich abzumelden drücken Sie einfach auf Weiter oder scannen den QR-Code erneut.<br/><br/>Ihnen gefällt corona-presence?<br/>Sie möchten es gerne für Ihren Betrieb/Ihre Veranstaltung einsetzen?<br/>Ganz einfach: <a href="https://www.corona-presence.de?pk_campaign=uqcdadirect" target="_blank" rel="noopener noreferrer">Hier</a> können Sie sofort Ihre eigene Liste anlegen. <a href="https://www.corona-presence.de?pk_campaign=uqcdadirect" target="_blank" rel="noopener noreferrer">corona-presence</a> ist und bleibt dauerhaft kostenlos, das ist unser digitaler Beitrag gegen das Virus</p>')
        anonym = <div>{ReactHtmlParser ( anonym )}</div> */

        let cdap_success_icon = cover
        if ( window.getURLParameter('icon') === 'preregconfirmed' ) cdap_success_icon = cover_gg // doppelter haken

        if( !this.additional_buttons.length )
            this.additional_buttons = app.create_confirm_buttons( window.LOGOUT )

        let tnr_view = null
        let table_nr = window.getURLParameter('t')
        if( parseInt(table_nr) >= 0 )
        {
            tnr_view = <div> in <b>Zimmer { parseInt(table_nr) }</b>:</div>
        }

        return (
            <div className="aiCenter">
                <div className="aiStartContainer">
                    {
                        <CDAPHeader />
                    }
                    <p>
                        Mit <b>Corona Presence</b> wird Ihre Anwesenheit datenschutzkonform erfasst.
                    </p>

                    <img src={cdap_success_icon} id='cover' alt='cover' style={{ width: '70%', maxWidth: '33rem' }}></img>

                    <p style={{ fontSize: 'var(--normal)', marginBottom: 0 }}>
                        Aktuell gültige Anmeldung {anonym} für <br /><b>{this.props.app.displayTitle}</b>{tnr_view?'':':'}<br />
                        {
                            tnr_view
                        }
                        <span className='lastLogin' >{app.getCookie('login_' + app.pid).replace(';','')}</span>
                    </p>
                    {
                        this.additional_buttons
                    }
                    <div id={id} className='introButton button' ref={() => this.button_element = document.getElementById(id)} style={{ backgroundColor: 'rgb(0, 143, 255)' }} onClick={async (event) => {
                            event.preventDefault()

                            if( this.button_pressed )
                                return null
                            else
                                this.button_pressed = true

                            if( this.button_element )
                                this.button_element.style.backgroundColor = '#bfbfbf'
                                

                            let msg
                            let clm = app.getCookie('logoutmessage_' + app.pid, 'Logout' )
                            try {
                                msg = JSON.parse(clm)
                            } catch (err) {
                                app.post_error({ cookieWert: clm , ursprung: 'Logout.js (Zeile 86)', grund: 'catch-block von JSON.parse( clm ) ausgelöst (clm: cookie logoutmessage)', error: err })
                                msg = { 
                                        subject: 'Failed to JSON.parse( logoutmessage ): ', 
                                        body: ( 
                                            clm ? 
                                            clm
                                            : 
                                            (
                                                'empty string, navigator.cookieEnabled: ' 
                                                + navigator.cookieEnabled.toString() 
                                                + '; document.cookie: ' 
                                                + document.cookie
                                            )
                                        ) 
                                    }
                            }

                            let this_btn = document.getElementById(id)
                            app.showLoader( this_btn )

                            await app.postJsonMessages2EDP(msg)

                            app.setCookie('logoutmessage_' + app.pid, '', 0)
                            app.setCookie('message_parent_id_' + app.pid, '', 0) 
                            app.setCookie('login_' + app.pid, '', -2)

                            window.hideLoader()
                            window.location.href.refresh()
                        }
                        }> Abmelden <br /> 
                    </div>


                    <div id={'weitere_personen'} className='introButton button' style={{ color: 'rgb(0, 143, 255)', backgroundColor: 'transparent' }} onClick={() => {
                            window.location.href.append({mode:'checklist',fromcookie:'ignore'}).refresh()
                        }
                        }> Weitere Personen einchecken <br /> 
                    </div>
                    
                    <AGB />
                </div>
            </div>
        )
    }
}

export default Logout
