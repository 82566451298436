module.exports = `# Datenschutzerklärung für Corona-Assist  (Stand: 01.06.2021)

Name und Kontaktdaten des Verantwortlichen

Makrolog AG\
Herr Andreas Herberger, Vorstandsvorsitzender\
Patrickstraße 43\
65191 Wiesbaden\
Deutschland\
Telefon: 0611 -- 957820\
Fax: 0611 -- 9578228\
E-Mail: zentrale&#64;makrolog.de\
Internet: http://www.makrolog.de

## Zwecke der Verarbeitung
Daten werden erhoben, damit im Rahmen des Vertrages über die Nutzung des Dienstes WhatsDown die vertraglich geschuldete Leistung des Dienstes erbracht werden kann und die Zwecke des Vertrages erfüllt werden können. 
a) Es werden folgende Daten erhoben:
Die für die Corona-Kontatverfolgung vorgegebene Daten zur Erreichbarkeit: Vorname Nachname, Telefonnummer, Postleitzahl Wohnort, Strasse mit Hausnummer, ggf. Namen von Begleitpersonen und Tisch-Nummer/DFB-Zone
Beim Anlegen/Verwalten von Anwesenheitslisten (&#34;Betreiber&#34;) zusätzlich: Mobiltelefonnummer (zur Authentifizierung/Login)
Rechtsgrundlage ist entweder eine Einwilligung gemäß Art. 6 Absatz1 a) DSGVO oder aber Art. 6 Absatz 1 b) DSGVO. 

b) Im Falle der Nutzung von Push-Diensten (Notifications) werden die Daten gespeichert, um die geschuldeten Informationen vertragskonform an den Kunden weiterleiten zu können. Die Push-Dienste können vertragsgemäß über die Funktionalitäten des jeweiligen Push-Dienstes (z. B. Einstellungen am Mobilgerät) abbestellt werden. 
Rechtsgrundlage ist entweder eine Einwilligung gemäß Art. 6 Absatz1 a) DSGVO oder aber Art. 6 Absatz 1 b) DSGVO. 
Neben der Mobiltelefonnummer wird für die Push-Dienste ggf. eine eindeutige Geräte-ID mit der Mobiltelefonummer verbunden und gespeichert.  

c) Technisch bedingte Zugriffsberechtigungen 
Es werden keine besonderen Zugriffrsberechtigungen für die Nutzung von corona-presence mit einem Browser benötigt. 

d) Erhebung von Standortdaten
Es werden keine Standortdaten vom Gerät erhoben. Beim Eintragen in eine Anwesenheitsliste wird lediglich die Information (ohne Koordinaten) gespeichert, dass sich der Benutzer in diese Anwensenheitsliste eingetragen hat sowie die Inhalte der vom Benutzer ausgefüllten Felder. 

f) Einsatz von Cookies
Corona-Presence.de verwendet Cookies. Die Übermittlung von Cookies an Drittanbieter oder der Einsatz von Cookies für Werbezwecke erfolgt nicht. 
corona-presence speichert den Eintragungszeitpunkt in eine Anwesenheitsliste in einem Cookie um dem Benutzer später das Abmelden von der jeweiligen Anwesenheitsliste zu ermöglichen.
Zusätzlich kann der Benutzer durch Aktivieren der entsprechenden Funktion die von ihm gemachten Eintragungen in einem Cookie auf seinem Gerät speichern. 
Um unsere Angebote kontinuierlich zu verbessern, erheben wir darüberhinaus über Cookies Nutzungsdaten auf pseudonymer Basis. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f) DSGVO.
Die Erfassung der Nutzugnsdaten erfolgt mit dem Programm Matomo unter der Adresse hub.makrolog.de. 

## Empfänger oder Kategorien der Empfänger

Die Daten werden bei dem jeweiligen Betreiber des Dienstes bzw. bei dem von diesem eingeschalteten Auftragsverarbeiter gespeichert. 

## Übermittlung von Daten 
a) Eine Übermittlung der Daten in ein Drittland oder an eine internationale Organisation finden nicht statt. 

b) Es findet im Rahmen der Vertragsabwicklung eine Übertragung der Daten an weisungsabhängige Auftragsverarbeiter statt. Diese werden sorgfältig ausgewählt und vertraglich verpflichtet. Eine Datenübermittlung erfolgt nur im Falle der ausdrücklichen Einwilligung hierzu oder im Falle einer dahingehenden gesetzlichen Regelung. 

## Speicherdauer
Die Daten werden für die Dauer bis zur Erfüllung des jeweiligen Speicherungszweckes bzw. bis zur gesetzlich zulässigen Höchstdauer der Speicherung gespeichert. Diese beträgt bei der Anwendung von corona-presence.de 4 Wochen. Danach werden die Daten automatisch gelöscht. 

## Auskunftsrecht
Die betroffene Person hat das Recht, von dem Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden.

## Recht zum Widerruf der Einwilligung
Die betroffene Person hat das Recht, eine erteilte Einwilligung in die Datenerhebung/-speicherung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Die betroffene Person wird vor Abgabe der Einwilligung über das Recht zum Widerruf informiert.

## Beschwerderecht bei der Aufsichtsbehörde

Die betroffene Person hat das Recht sich bei der Aufsichtsbehörde über den Umgang mit ihren personenbezogenen Daten oder das Verfahren der Bearbeitung und Speicherung der Daten zu beschweren. Zuständige Aufsichtsbehörde in Hessen ist:

Der Hessische Beauftragte für Datenschutz und Informationsfreiheit\
Gustav-Stresemann-Ring 1\
65189 Wiesbaden\
Telefon: 0611-1408 0\
Telefax: 0611-1408 611\
E-Mail: poststelle&#64;datenschutz.hessen.de

(mailto:poststelle&#64;datenschutz.hessen.de)

## Automatisierte Entscheidungsfindung, Profiling
Profiling oder eine automatisierte Entscheidungsfindung kommen nicht zum Einsatz.

## Recht auf Berichtigung
Die betroffene Person hat das Recht, von dem Verantwortlichen unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen.

## Recht auf Löschung
Die betroffene Person hat das Recht, von dem Verantwortlichen zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, personenbezogene Daten unverzüglich zu löschen, wenn die Speicherung zu den Zwecken nicht  mehr notwendig ist, die Einwilligung zur Datenspeicherung/Erhebung widerrufen worden ist und sonst keine Rechtsgrundlage für die Speicherung/Erhebung besteht, die betroffene Person der Verarbeitung widersprochen hat, die Daten unrechtmäßig verarbeitet wurden oder die Löschung einer rechtlichen Verpflichtung entspricht. 

## Recht auf Einschränkung der Verarbeitung
Die betroffene Person hat das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn die Richtigkeit der personenbezogenen Daten bestritten wird, die Verarbeitung unrechtmäßig ist und die betroffene Person statt der Löschung der Daten die Einschränkung der Nutzung verlangt, der Verantwortliche die personenbezogenen Daten nicht mehr benötigt, die betroffene Person sie jedoch zur Verfolgung von Rechtsansprüchen benötigt oder die betroffene Person Widerspruch eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen die des Betroffenen überwiegen. 

## Mitteilung bei Berichtigung oder Löschung der Daten
Der Verantwortliche teilt allen Empfängern, denen personenbezogenen Daten offengelegt wurden, jede Berichtigung oder Löschung der personenbezogenen Daten oder eine Einschränkung der Verarbeitung mit.

Recht auf Datenübertragbarkeit

Die betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten, die sie einem Verantwortlichen bereitgestellt hat, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und in diesem Format auch einem anderen Verantwortlichen zu übermitteln, wenn der Verarbeitung der Daten eine Einwilligung zugrunde liegt und die Verarbeitung automatisiert erfolgt.`