import React, { Component } from 'react'
import './variants/cdap/css/Startpage.css'
import AGB from './variants/cdap/AGB'
import CDAPHeader from './variants/cdap/CDAPHeader'
import DHCHeader from './variants/dhc/DHCHeader'

import Header from './Header'

import { isMobile } from 'react-device-detect'

var QRCode = require('qrcode.react')

class PreRegisterLanding extends Component {
    constructor(props, context) {
        super(props, context)

        let { app } = this.props
        let msg_pid = app ? app.getCookie('message_parent_id_'+app.pid) : false
        if( msg_pid ){
            window.location.href = window.location.protocol + '//' + window.location.host + "/cdap/" + app.pid + "?mode=checklist"
        }

        this.target = window.location.href
        this.state = {
            showQR: !isMobile
        }
    }

    componentDidMount(){
        window.hideLoader()
        document.getElementsByTagName('html')[0].style.backgroundImage = 'repeating-linear-gradient(-9deg, white, hsl(0deg 0% 95%) 50%)'
    }

    yes() {
        this.target.append({ mode: 'checklist' }).refresh()
    }

    no() {
        this.preregbtn.style.maxHeight = this.state.showQR ? '100vmax' : '0vmax'

        setTimeout(function () {
            if (this.qrcode) this.qrcode.style.maxHeight = '100vh'
        }.bind(this), 100);

        this.setState({ showQR: true })
    }

    render() {

        let { app } = this.props
        let { type } = app

        let header = <CDAPHeader />
        if( type && type.DHC )
            // header = <DHCHeader />
            header = <Header name={'no name'} id={'dhc'} key={0} pid={app.pid} isAIdent={false} app={app}/>

        // let showLoginAgain = app.getCookie('showLoginAgain_' + app.pid) === 'true'
        // let logoutMessage = window.getURLParameter('logoutmessage')

        let btncss = isMobile ?
            {
                backgroundColor: 'rgb(0, 143, 255)'
            }
            :
            {
                backgroundColor: 'transparent',
                border: '2px solid rgb(0, 143, 255)',
                color: 'rgb(0, 143, 255)'
            }

        let qrcss = { maxHeight: '100vh' }

        return (
            <div className="aiCenter">
                <div className="aiStartContainer">
                    {
                        header
                    }
                    <p>
                        
                        Vorregistrierung für die Anwesenheitsliste<br /><b>{app ? app.displayTitle : 'keinTitel'}</b> aktivieren<br />

                    </p>
                    <div className='preregbtn' ref={(e) => { this.preregbtn = e }}>
                        {
                            isMobile ?
                                <div>
                                    <p>
                                        Bitte bestätigte, dass dies das Mobilgerät ist, dass du zur Teststelle mitnimmst.
                                    </p>
                                    <div className='introButton button' style={btncss} onClick={() => this.yes()}> Ja<br /> </div>
                                    <div className='introButton button' style={{ backgroundColor: 'rgb(0, 143, 255)' }} onClick={() => this.no()}> Nein<br /> </div>

                                </div>
                                :
                                <p>
                                    Dies ist kein Mobilgerät. Bitte scanne den folgenden QR-Code mit dem Mobilgerät, das du zur Veranstaltung mitnimmst.
                                </p>
                        }
                    </div>
                    <div className='qrcode' style={isMobile ? {} : qrcss} ref={(e) => { this.qrcode = e }}>
                        <div>
                            <QRCode
                                value={this.target}
                                renderAs='svg'
                                size={512}
                                bgColor='#00000000'
                                fgColor='#464c69'
                            />
                        </div>
                        <a href={this.target.append({ mode: 'checklist' })} style={{ textDecoration: 'underline' }}>Uuups. Dies ist doch mein Mobilgerät. Weiter...</a>
                    </div>
                    <AGB />
                </div>
            </div>
        )
    }
}

export default PreRegisterLanding
