import React, { Component } from 'react'
import placeholder from './svg/icon_doton.svg'

import arrow from './svg/icon_send_white.svg'
import cam from './svg/icon_cam.svg'

const DEFAULT_HEIGHT = '6em'

class WhatsDownMessage extends Component {
    constructor(props, context) {
        super(props, context)

        this.input = null
        this.textarea = null

        this.state = {
            height: DEFAULT_HEIGHT
        }
    }

    componentDidMount(){
        let start = this.startSpan.getBoundingClientRect().top
        let action = this.actionButton.getBoundingClientRect().bottom

        if(this.state.height === DEFAULT_HEIGHT)
            this.setState({height: 'calc(3.5em + ' + (action-start) + 'px)'})
    }

    agb(){
        return this.props.root ? this.props.root.agb() : false
    }

    render() {
        let s = this.props.selected

        return (
            <div className='ServiceDetails bg98L'  style={ s? {height: this.state.height} : {height: 0}}>
                <div className='bgblue bg97L flex'>
                    <img className='list_icon IconPlaceholder' src={placeholder} alt='service' />
                </div>
                <div className='DetailsSubgroup' >
                    <div className='SubgroupDescription'>
                        <span id='start' ref={(c)=> this.startSpan = c }></span>
                        
                        <input placeholder={'Ihr Kontakt'/* this.props.intro */} rows="1" ref={(c)=>this.input=c} onFocus={()=> {if (!this.agb()) this.input.blur() }}></input>
                        <textarea placeholder={'Ihre Service-Nachricht...'/* this.props.intro */} ref={(c)=>this.textarea=c} onFocus={()=> {if (!this.agb()) this.textarea.blur() }  }></textarea>
                    </div>
                    <div className='SubgroupButtons'ref={(c)=> this.actionButton = c}>
                        <img className='iconH2em' src={cam} alt='service' />
                        <span className='OpenButton bgblue Button' >
                            <span>Senden</span>
                            <img className='iconH' src={arrow} alt='service' />
                        </span>
                    </div>
                </div>

            </div>
        )
    }
}

export default WhatsDownMessage
