import React, { Component } from 'react'
import qrcode from './svg/qr.svg'
// import manikin from './svg/mask_green.svg'
// import manikin from './svg/mask_blue.svg'
import manikin from './svg/mask.svg'
import Warning from './Warning'

import { log } from '../helpers/PrintHTMLDebug'

class StartButton extends Component {
    constructor(props, context) {
        super(props, context)

        this.setNoteRef = (i, t) => {
            this.NoteRef = t
        }

        this.NoteElem = <Warning content='Falscher Standort!' setNoteRef={this.setNoteRef} />
    }

    componentDidMount() {
        this.props.parent.setStartButtonRef(this.refs.startBtn)
    }

    render() {

        let button = this.props.jsonData
        let parent = this.props.parent

        let mustBeValidated = button.validatedAccess !== '' && !parent.validated
        let startButtonClass = mustBeValidated ? 'redBtn' : 'greenBtn' //'blueBtn'
        let startIcon = parent.validated ? manikin : qrcode
        let startText = parent.validated ? (button.name || 'Anlagenrundgang starten') : (button.invoke || 'Standort verifizieren')
        let href = 'guide' in button ? button.guide.replace('%qrid', parent.pid) : ''
        let guideBtn = <div key={1} id='guideBtn'>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <a href={href} target='_blank' rel="noopener noreferrer">
                                {button.guideBtn}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        {/* <div
            key={1}
            id='guideBtn'
            onClick={() => console.log('guide href: %s', href)} >
            <a href={href} target='_blank' rel="noopener noreferrer">
                {button.guideBtn}
            </a>
        </div> */}

        return (
            <div id='startButtonContainer' ref={ (s)=> this.self = s } >
                <div key={0} id='start' className={startButtonClass} ref='startBtn'
                    onClick={
                        () => {
                            //this.NoteRef.show() // zwecks DEBUGGING
                            //return // zwecks DEBUGGING

                            /* if( parent.type.CDAP ){
                                this.self.style.display = 'none'
                            } */

                            if (button.validatedAccess && !parent.validated) {
                                parent.getQRCode('starteRundgang')
                            } else {
                                // andere checkpunkte schließen und den ersten öffnen
                                /* parent.closeOthers(parent.firstElement) */

                                let first_row = parent.rows[ parent.firstElement ]
                                if( first_row )
                                    first_row.toggleOpen( first_row.state.isOpen )
                            }

                            // brandschutz (hat bisher keinen validated access)
                            if (typeof button.validatedAccess === 'undefined') {
                                parent.setValidated(true)
                            }

                            
                        }
                    }>
                    <table >
                        <tbody >
                            <tr>
                                <td style={{ width: '1%', textAlign: 'left' }} id='startIconContainer'>
                                    <img src={startIcon} className='startIcon' alt='start' />
                                </td>
                                <td style={{ width: '94%', textAlign: 'left', paddingLeft: '1rem' }}>
                                    {startText}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                { button.guide && button.guideBtn ? guideBtn : ''}
                { this.NoteElem}
                { this.LocationNoteElem}
            </div>
        )
    }
}

export default StartButton


