import React, { Component } from 'react'


class LastMessages extends Component {

    componentDidMount() {
    }

    render() {
        let {app, e} = this.props

        if( !app.messages )
            return null

        let messages = app.messages.map( (e, i) =>{
            return <div className='lmGroup'><div className='lmDate'>{e.date_db}</div><div>{e.text_db}</div></div>
        })

        console.log('messages elements: %o', messages)

        return (
            <div className='lastMessages'>
                <div className='lmTitle'>{e.name || 'Letzte Nachricht' }</div>
                <div>{messages}</div>
                
            </div>
        )
    }
}

export default LastMessages


