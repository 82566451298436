import React, { Component } from 'react'
import { isAndroid } from 'react-device-detect'
import DocumentMeta from 'react-document-meta';
import LocalDevIcon from './LocalDevIcon'
import Socialmedia from './SocialMedia'
import Header from './Header'

import cover from './variants/cdap/svg/cdap_loggedin.svg'


class SetupSuccess extends Component {

    getSetupSuccessButtons(type) {
        let { app } = this.props
        let user = encodeURI(window.getURLParameter('user'))
        let { CDA } = type
	    // let lang = window.getURLParameter('lang')

        let setupSuccess = {
            nextBtnFunc: () => console.log('getSetupSuccessScreen unset'),
            nextBtnText: "unset",
            setupFotoFunc: () => console.log('getSetupSuccessScreen unset'),
            setupFotoText: "unset"
        }

        if (isAndroid)
            setupSuccess = {
                nextBtnFunc: () => { if (typeof window.HTMLOUT !== 'undefined') window.HTMLOUT.callAndroidFunction("objektliste"); else window.location.href = 'https://my-qr.io/' + app.pid },
                nextBtnText: "Überspringen",
                setupFotoFunc: () => { if (typeof window.HTMLOUT !== 'undefined') window.HTMLOUT.openCamera(app.permid.CHILD) },
                setupFotoText: "Jetzt Foto aufnehmen"
            }


        if (window.mag_webkit)
            setupSuccess = {
                nextBtnFunc: CDA ? () => window.WebViewCloseCDA() : () => window.WebViewClose(),
                nextBtnText: CDA ? "Weiter":"Überspringen",
                setupFotoFunc: () => { app.getSetupImage(app.permid.CHILD, 'wdimage') },
                setupFotoText: "Jetzt Foto aufnehmen"
            }


        if (!isAndroid && !window.mag_webkit)
            setupSuccess = {
                nextBtnFunc: () => window.location.href = 'https://web.whatsdown.com/?user=' + user + "&idtoken=" + app.idtoken,
                nextBtnText: "Weiter"

                /* 
                //!isAndroid: klicked Foto aufnehmen ->  this.getSetupImage( this.permid.CHILD , "wdimage" ) '
                this.getSetupImage(this.permid.CHILD, 'wdimage')   //TODO <--> this.permid.CHILD
                 */
            }

        return (
            <div>
                <div id='ueberspringen' className='button'  onClick={setupSuccess.nextBtnFunc} >{setupSuccess.nextBtnText}</div>
                {
                    CDA ? 
                    ''
                    :
                    <div id='setupFoto' onClick={setupSuccess.setupFotoFunc} >{setupSuccess.setupFotoText}</div>
                }
                <br />
                <br />
            </div>)

    }




    render() {
        
        let { app, url, meta } = this.props
        let { WD, AI, AD, VB, CDA, EAG, CDAP } = app.type
        //neverused// let { MENU } = app.mode

        let header_name = app.getRenderDataElement('header', 'name')
        console.log('en header_name', header_name)


        let urls = {
            CDA: url.DIGSIE + '/vb/'  + app.pid,
            CDAP: url.CDAP + 'cdap/'  + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie:'ignore',mode:'checklist'}) : ''),
            CDAP: url.CDAP + 'cdap/'  + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie:'ignore',mode:'checklist'}) : ''),
            CAAC: url.CAAC + 'caac/'  + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie:'ignore',mode:'checklist'}) : ''),
            OMA:  url.OMA   + 'oma/'   + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie:'ignore',mode:'checklist'}) : ''),
            DHC: url.DIGSIE + '/vb/'  + app.pid,
            VB: url.DIGSIE + '/vb/'  + app.pid,
            AI: url.DIGSIE + '/ai/' + app.pid,
            AD: url.DF_WEB_VIEWER_AD + '?service=a-dok&qrid=' + app.pid /* + '&user=' + app.getRenderDataElement('text', 'value') */ + '&idtoken=' + app.idtoken,
            EAG: url.DF_WEB_VIEWER_EAG + '?service=wdeag&qrid=' + app.pid /* + '&user=' + app.getRenderDataElement('text', 'value') */ + '&idtoken=' + app.idtoken,
            WD: url.DF_WEB_VIEWER_WD + '?service=wdwd&qrid=' + app.pid /* + '&user=' + app.getRenderDataElement('text', 'value') */ + '&idtoken=' + app.idtoken
        }

        let successButtons = this.getSetupSuccessButtons(app.type)
        let { fontSizeBig } = window.customCss
        // return SETUP_SUCCESS mit MENU false und true
        // 1stad SETUP SUCCESS (setup success und !menu)
        // 1stad PROTOCOL SUCCESS (setup success und menu)

        let setupSuccessContent = <div>no setup success content for this mode</div>

 /*        if (WD)
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={app.InstanceName} id={app.headerid} key={0} pid={app.pid} app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >

                        <div>
                            <b>Nur noch 1 Schritt und dein WhatsDown-Etikett ist fertig konfiguriert - ein paar Ideen....</b>
                            <ul>
                                <li>Allgemein: ein Foto des Typenschilds</li>
                                <li>Bei Fahrzeugen: ein Foto der Luftdrucktabelle</li>
                                <li>Betriebsanweisung vorhanden: mach ein Foto davon</li>
                            </ul>
                            <p>Nach der Konfiguration ist es dann ganz einfach auf das Foto zuzugreifen - Einfach WhatsDown QR-Code scannen und dein Bild wird sofort für jeden, der vor Deinem Gerät steht angezeigt. Zusätzlich kann er dann auch gleich noch Kontakt zu Dir aufnehmen, wenn er nicht weiterkommt</p>
                        </div>

                    </div>
                    {successButtons}
                </DocumentMeta>
            )
 */
        if (AI)
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={'Immer dabei, mit A-Ident.'} id={'aident'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Dein A-Ident-Etikett ist jetzt fertig konfiguriert</b>
                            <p>Jetzt kann im Ernstfall jeder ganz einfach auf Deine bei A-Ident gespeicherten Daten zugreifen: A-Ident QR-Code scannen und von Dir hinterlegte A-Ident-Informationen werden sofort angezeigt.</p>
                        </div>
                    </div>
                    <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.AI} >Weiter zur Vorschau auf Deine A-Ident Daten</div>
                </DocumentMeta>
            )

        if (EAG)
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={'WDEAG Setup.'} id={'wdeag'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Das WDEAG-Etikett ist jetzt fertig konfiguriert</b>
                            <p>Im nächsten Schritt können Dokumente in die vordefinierten Ordner von <b>{app.displayTitle}</b> hinzugefügt werden.</p>
                        </div>
                    </div>
                    <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.EAG} >Weiter</div>
                </DocumentMeta>
            )

        if (AD)
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={'Immer verfügbar, mit A-Dok.'} id={'adok'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Dein A-Dok-Etikett ist jetzt fertig konfiguriert</b>
                            <p>Im nächsten Schritt kannst Du Deine wichtigen Dokumente unter <b>{app.displayTitle}</b>  hinzufügen und später jderzeit über das Etikett wieder darauf zugreifen.</p>
                        </div>
                    </div>
                    <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.AD} >Weiter</div>
                   
                </DocumentMeta>
            )
        if (CDA)
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={'Desinfektionsobjekt anlegen'} id={'cda'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Dein Desinfektions-Objekt wurde erstellt</b>
                            <p>Im nächsten Schritt kannst Du jetzt einen Zeitplan für die Desinfektion festlegen. </p>
                        </div>
                    </div>
                    {successButtons}

                   
                </DocumentMeta>
            )
        if ( CDAP ){
                let fromSetup = window.getURLParameter('from') === 'setup'
                setupSuccessContent = !fromSetup ? //MENU ? //MENU ist eigentlich falsch, da die ui nach mwsCreate() neu geladen wird und eine checkliste möglicherweise bereits existiert, MENU wäre höchstens im vorherigen schritt passend
                    (
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={'Corona Anwesenheitsliste'} id={'cdap'} key={0} pid={app.pid}  app={app}/>

                            <div id='postSaveText' onLoad={window.hideLoader} >
                            <img src={cover} id='cover' alt='cover'></img>
                                <div style={{fontWeight: '400', fontSize: fontSizeBig}}>
                                    {
                                        app.fromCookieIgnore ? 
                                        <p>Der Gast wurde in der Corona-Anwesenheitsliste <b >{app.displayTitle}</b> eingetragen.<br/><br/></p>
                                        :
                                        <p>Thank You! - Your entry has been recorded in the list for <b >{app.displayTitle}</b>.<br/><br/>To checkout just scan the QR-Code again or press CONTINUE</p>
                                    }
                                </div>
                            </div>
                            <div id='ueberspringen' className='button' style={{backgroundImage: 'linear-gradient(to right, #01affe 0%, #1a7cfa 100%)'}} onClick={() => window.location.href = urls.CDAP} >
                                {
                                    app.fromCookieIgnore ? 
                                    'Nächsten Gast eintragen'
                                    :
                                    'Continue'
                                }
                            </div>
                            {
                                app.fromCookieIgnore ? 
                                '' : <Socialmedia />
                            }
                            
                        </DocumentMeta>
                    ) : (
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <div id='postSaveText' onLoad={window.hideLoader} >
                                <div>
                                <b>Herzlichen Glückwunsch! Ihre Corona Anwesenheitsliste zu "{app.displayTitle}" 
                            <Header name={'Corona Anwesenheitsliste Location'} id={'cdap'} key={0} pid={app.pid} app={app}/>wurde erstellt.</b><br/>
                                
                                {/* <p>Im nächsten Schritt können Sie nun zum Beispiel probeweise einen Eintrag vornehmen.</p> */}
                                </div>
                            </div>
                            <div id='aufsteller' className='drucken button'  onClick={() =>window.open( 'https://web.corona-presence.de/webuicdap/qrcode/index.php?data='+app.pid+'&title='+app.displayTitle)} >Tischaufsteller/Aushang drucken</div>
                            <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.CDAP + ( urls.CDAP.includes('?') ? '&' : '? ') + 'print=possible' } >Weiter</div>
                            <Socialmedia />
                        </DocumentMeta>
                    )
            }


            if ( WD ){
                let fromSetup = window.getURLParameter('from') === 'setup'
	    	let url = "./" + app.pid + "?mode=checklist";
                setupSuccessContent = !fromSetup ? //MENU ? //MENU ist eigentlich falsch, da die ui nach mwsCreate() neu geladen wird und eine checkliste möglicherweise bereits existiert, MENU wäre höchstens im vorherigen schritt passend
                    (
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={'WhatsDown'} id={'whatsdown'} key={0} pid={app.pid}  app={app}/>

                            <div id='postSaveText' onLoad={window.hideLoader} >
                                <div style={{fontWeight: '400', fontSize: fontSizeBig}}>
                                
                                        <p>Vielen Dank! - Ihre Meldung wurde an den Betreiber von <b >{app.displayTitle}</b> gesendet.</p>
			    <p>Noch eine Meldung <a href={url}>senden</a></p>
                                </div>
                            </div>                            
                        </DocumentMeta>
                    ) : ( <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={'WD Setup.'} id={'whatsdown'} key={0} pid={app.pid}  app={app}/>
                            <div id='postSaveText' onLoad={window.hideLoader} >
                                <div>
                                    <b>Herzlichen Glückwunsch! Der WhatsDown-Code ist jetzt fertig konfiguriert</b>
                                </div>
                            </div>
                            <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.WD } >Weiter</div>
                        </DocumentMeta>
                    )
            }


        if (VB){
            let fromSetup = window.getURLParameter('from') === 'setup'
            setupSuccessContent = !fromSetup ? //MENU ? //MENU ist eigentlich falsch, da die ui nach mwsCreate() neu geladen wird und eine checkliste möglicherweise bereits existiert, MENU wäre höchstens im vorherigen schritt passend
                (
                    <DocumentMeta {...meta} >
                        <LocalDevIcon />
                        <Header name={'1stad'} id={'1stad'} key={0} pid={app.pid}  app={app}/>
                        <div id='postSaveText' onLoad={window.hideLoader} >
                            <div>
                                <b>Vielen Dank! - Ihr Eintrag wurde im Verbandsbuch "{app.displayTitle}" gespeichert.</b>
                                <p>Der zuständige Verbandsbuch-Administrator kann jetzt darauf zugreifen</p>
                            </div>
                        </div>
                        <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.VB} >Weiter</div>
                    </DocumentMeta>
                ) : (
                    <DocumentMeta {...meta} >
                        <LocalDevIcon />
                        <Header name={'1stad'} id={'1stad'} key={0} pid={app.pid} app={app}/>
                        <div id='postSaveText' onLoad={window.hideLoader} >
                            <div>
                                <b> Herzlichen Glückwunsch! Ihr 1stad-Etikett "{app.displayTitle}" ist jetzt fertig konfiguriert</b>
                                <p>Im nächsten Schritt können Sie nun zum Beispiel probeweise einen Eintrag vornehmen.</p>
                            </div>
                        </div>
                        <div id='ueberspringen' className='button'  onClick={() => window.location.href = urls.VB} >Weiter</div>
                    </DocumentMeta>
                )
        }
        return setupSuccessContent
    }
}

export default SetupSuccess
