import React, { Component } from 'react'

class CheckboxSingle extends Component {
    constructor(props, context){
        super(props)

        this.container = this.props.container
        this.option = this.props.option
        this.initial_value = this.props.option.value
        this.fixed = this.initial_value === 1
        this.index = this.props.index
        this.first = this.props.first

        this.state = {
            value: this.option.value // parseInt( this.option.value || 0 ) > 0 ? 1 : 0
        }

        this.container.children[ this.index ] = this
        this.nosave = window.getURLParameter('nosave') === 'true'
    }

    click(){
        if( this.nosave ){
            return false
        }
        
        let fixed_value = !this.container.edit_selected && this.initial_value > 0 
        let unclickable_field = this.option.value < 0

        if( unclickable_field || fixed_value ){
            return false
        }

        let new_val = this.state.value ? 0 : 1

        this.container.set_checkbox_state( this.index, new_val )
        this.setState({
            value: new_val
        })
    }

    render() {
        let checked = this.state.value > 0 
        let show_checkboxes = this.container.show_checkboxes
        let radio = this.container.radio

        let ico_class
        if( show_checkboxes ){
            ico_class = 'icomoon_general icomoon_' + (radio ? 'radio_' : '') + (checked ? 'checked' : 'unchecked')
        }else{
            ico_class = {}

        }

        let cb_name_css = checked ? { fontWeight: 500 } : {} 
        if( this.container.width > 1 ){
            cb_name_css.marginLeft = 0
        }

        let css = checked && !show_checkboxes ? { background: '#00ffbc' } : {}
        if( this.first && this.initial_value < 0 ){
            css.borderRight = '1px solid ' + this.container.border_color
        }

        let uneditable_css = this.container.e.uneditable_field_css
        if( uneditable_css && this.fixed ){
            css = Object.assign( css, uneditable_css )
        }

        let selected_field_css = this.container.e.selected_field_css
        if( selected_field_css && !this.fixed && checked ){
            css = Object.assign( css, selected_field_css )
        }

        return (
            <div className='CheckboxSingle' style={ css } onClick={ ()=>this.click() }>
                <span className={ ico_class }></span>
                <span className='CheckboxName' style={ cb_name_css } >
                    {
                    this.option.name
                    }
                    
                    {
                        /* this.state.value */
                    }
                </span>
            </div>
        )
    }
}

export default CheckboxSingle
