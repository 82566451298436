import React, { Component } from 'react'
import docIcon from './svg/icon_document.svg'

class Document extends Component {

    render() {
        return (
            <div className='line' onClick={ () => window.open(this.props.content.url, '_blank') }>
                <div>
                  <div className='bg97L flex'>
                    <img className='list_icon' src={docIcon} alt='service' />
                  </div>
                  <span className='lineName'>
                    {this.props.content.text}
                  </span>
                </div>
            </div>
        )
    }
}

export default Document
