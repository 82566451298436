import React, { Component } from 'react'
import './css/AGB.css'


class AGB extends Component {
  render(){ 
     
    return (
      <p className="agbContainer" >
        Mit <b>Und los geht's</b> bestätigen Sie, dass Sie unsere <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_Igf2SQq0zE' id='datenschutz'  rel="noopener noreferrer">Datenschutzerklärung</a> und <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_0JrTXgcudm' id='agb' rel="noopener noreferrer">Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben.
      </p>
    )
  }
}

export default AGB
