import React, { Component } from 'react'
import Details from './ServiceListDetails'



import call from './svg/icon_call_simple.svg'

class Support extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            selected: false
        }
    }

    close(){
        this.setState({selected: false})
      }

    render() {
        let content = {first: 'SMS', second: 'Anruf', intro: 'Den für diesen Bereich zuständigen Techniker telefonisch kontaktieren.'}

        return (
            <div className='line' onClick={ () => { this.props.root.closeAll(this.props.index); this.setState({ selected: !this.state.selected}) } }>
                <div>
                  <div className='bg97L flex'>
                    <img className='list_icon' src={call} alt='service' />
                  </div>
                  <span className='lineName'>
                    {this.props.text}
                  </span>
                </div>
                <Details selected={this.state.selected} content={content}/>
              </div>
        )
    }
}

export default Support

