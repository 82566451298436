import React, { Component } from 'react'

import './css/ServiceFAQ.css'

class ServiceFAQ extends Component {
  render() {
    return (
      <div className="ServiceFAQ">

      </div>
    )
  }
}

export default ServiceFAQ
