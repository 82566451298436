import React, { Component } from 'react'
import document from './svg/document.svg'
import documentA from './svg/documentAttached.svg'
import { log } from '../helpers/PrintHTMLDebug'

var server

class DocumentAdd extends Component {
    constructor(props, context) {
        super(props, context)

        this.app = this.props.app
        this.documentID = this.props.e.id
        this.selfIndex = this.props.e.selfIndex

        this.state = {
            documentAttached: false,
            documentName: this.app.renderData[ this.selfIndex ].original,
            zoom: false
        }

        this.onPhotoChange = this.onPhotoChange.bind(this)
        this.removePhoto = this.removePhoto.bind(this)
    }

    componentDidMount(){
        server = window.server
    }

    onPhotoChange(evt) {

        let self = this

        var tgt = evt.target || window.event.srcElement,
            files = tgt.files

        if (FileReader && files && files.length) {
            var fr = new FileReader()
            fr.onload = function () {
                try {
                    let fileType = files[0].type.split('/')[1]
                    self.setState({ documentAttached: true, documentName: files[0].name, documentType: fileType })
                    self.props.setImageRef(self.documentID, files[0], fileType)
                    self.app.renderData[ self.selfIndex ].original = files[0].name
                } catch (error) {
                    console.log(error)
                }
            }

            log('files[0].name: ' + files[0].name)
            log('files[0].type: ' + files[0].type.split('/')[1] )
            fr.readAsDataURL(files[0]);
        }
    }

    removePhoto() {
        try {
            document.getElementById(this.documentID).src = null
            this.props.resetImageRef(this.documentID)
            this.app.renderData[ this.selfIndex ].original = ''
            this.setState({ documentAttached: false, documentName: '' })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        // this.documentID = "document" + this.props.e.id
        // let displayPhoto = this.state.documentAttached || this.props.file ? { display: 'block' } : { display: 'none' }
        // let zoomPhoto = this.state.zoom ? { maxWidth: '78vw' } : {}
        let inputID = this.props.e.id + 'InputFile'
        let original = this.state.documentName // this.app.renderData[ this.selfIndex ]
        /* this.state.documentAttached ?  */

        return (
            <div className='PhotoAddRow' onClick={() => this.app.closeOthers(9999) } >
                <table className='PhotoAddTable' >
                    <tbody>
                        <tr>
                            <td className='PhotoAddNameTD' onClick={ () => this.InputFile.click() } ><span>{/* this.state.documentAttached */ original ? /* this.props.e.post + ': ' + this.state.documentName */ original : this.props.e.name }</span></td>
                            <td className='PhotoAddIconTD'>
                                <input type="file" name="filetoupload" id={inputID} className="inputfile" onChange={this.onPhotoChange} ref={(e) => this.InputFile = e}/>
                                <label htmlFor={inputID}>
                                    <img className='PhotoAddIcon' src={this.state.documentAttached ? documentA : document} alt='+' />
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default DocumentAdd


