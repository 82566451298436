import React, { Component } from 'react'

class SocialMedia extends Component {

  render() {
    return null // 270221 deaktiviert
    /* try {
        let fb = require('./svg/fb.svg')
        let insta = require('./svg/insta.svg')

        let fb_url = 'https://www.facebook.com/coronapresence/'
        let insta_url = 'https://www.instagram.com/coronapresence/'

        return <div className='socialmedia'>
            <a href={fb_url} target='_blank' rel='noopener noreferrer'><img className='smicon' src={fb} alt='facebook'/></a>
            <a href={insta_url} target='_blank' rel='noopener noreferrer'><img className='smicon' src={insta} alt='instagram'/></a>
            </div> 
    } catch (err) {
        return null
    } */
  }
}  

export default SocialMedia