import React, { Component } from 'react'
import folder_background from './png/pharma.png'

class FolderName extends Component {
    constructor(props, context) {
        super(props, context)

        let fpid = this.props.folder[ this.props.permid ]
        if( fpid ){

            this.title = fpid.title || (fpid.code === 401 ? fpid.response : '_no_title_')
            this.perm_id = fpid.perm_id || fpid['perm-id']
            this.last_update = fpid.last_update
        }else{
            this.title = this.props.app.displayTitle || 'Title'
            this.perm_id = 'Perm ID'
            this.last_update = 'Last Update'
        }

        this.is_fullscreen = this.props.fullscreen
        this.is_root = this.props.root

        if( this.last_update ){
            this.last_update = this.last_update.replace('.0','')
        }
    }

    render() {
        // back to parent (permid) ? -> browser history
        // let css = this.props.empty ? { cursor: 'default' } : {}
        let css = this.is_root ? { padding: '1.1rem', fontWeight: 600 } : {}

        let is_open = this.props.filelist.did_request

        if( this.is_fullscreen ){
            
            return (
                <div className='FolderNameFullscreen' style={{ backgroundImage: `url(${folder_background})` }}>
                    <table>
                        <tbody>
                            <tr>
                              
                                <td className='fullscreen_title'>
                                    <div>{ this.title }</div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }else{
            return (
                <div className='FolderName' style={css} onClick={()=>this.props.toggle()}>
                    <table>
                        <tbody>
                            <tr>
                                {
                                    this.is_root?
                                    null
                                    :
                                    <td className='folders'><span className={ 'icomoon_folder_'+( is_open ? 'open' : 'closed')+' icomoon_general'}></span></td>
                                }
                                <td className='left'>
                                    <div dangerouslySetInnerHTML={{__html: this.title}}></div>
                                    {/* <div className='LastUpdate'>{ this.last_update }{ this.last_update? ' - ' : '' }{ this.perm_id }</div> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )

        }
    }
}

export default FolderName

