import React, { Component } from 'react'
import './css/Startpage.css'
import AGB from './AGB_en'
import cover from './svg/cdap_logout.svg'
import cover_gg from '../../png/cda1024gg.png'
import CDAPHeader from './CDAPHeader'

class Logout extends Component {
  render(){
    let app = this.props.app
    let cname = app.cookie.name
    let anonym = Boolean(app.getCookie(cname)) ? '' : '' // '(anonym)'

    let cdap_success_icon = cover
    if ( window.getURLParameter('icon') === 'preregconfirmed' ) cdap_success_icon = cover_gg // doppelter haken

    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          {
            <CDAPHeader lang='en'/>
          }
          <p>
          <b>Corona Presence</b> logs your presence compliant with data protection regulations.
          </p>
   
          <img src={cdap_success_icon} id='cover' alt='cover'></img>

          <p style={{fontSize:'var(--normal)', marginBottom: 0}}>
            Your current entry {anonym} in <br/><b>{this.props.app.displayTitle}</b>:<br/> <span className='lastLogin' >{app.getCookie('login_'+app.pid)}</span>
          </p>
          

            <div className='introButton button' style={{ backgroundColor: 'rgb(0, 143, 255)' }} onClick={ async (event)=> {
              event.preventDefault()

                    let msg
                    let clm = app.getCookie( 'logoutmessage_'+app.pid)
                    try {
                        msg = JSON.parse(clm)
                    } catch (err) {
                        msg = {subject: 'Failed to JSON.parse( logoutmessage ): ', body: (clm ? clm : 'empty string') }
                    }
                    await app.postJsonMessages2EDP(msg)


                    app.setCookie( 'logoutmessage_' + app.pid,'', 0)
                    app.setCookie( 'message_parent_id_' + app.pid, '', 0)
                    app.setCookie( 'login_' + app.pid,'', -2)

                    window.location.reload()
                }
            }> Checkout <br/> </div> 

            <AGB/>


        </div>
      </div>
    )
  }
}

export default Logout
