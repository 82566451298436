import React, { Component } from 'react'
// import {log} from '../helpers/PrintHTMLDebug'

class Note extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
        show: false
    }    

    this.show = this.show.bind(this)
  }

  componentDidMount(){
    this.props.setNoteRef( this.props.name, this ) //
  }

  show(){
    if( !this.state.show ){
        let self = this
        self.setState({ show: true})
        setTimeout(function(){
            self.setState({ show: false})
        }, 1500)
    }   
  }

  render() {
    let height = this.state.show ? '80px' : 0
    let padding = this.state.show ? '20px' : 0
    return (
        <div className='Note' style={{height:height, paddingTop:padding}}>
            {this.props.content}
        </div>
      )
  }
}  

export default Note


