/* import React, { Component } from 'react'
import './css/AGB.css'


class AGB extends Component {
  render(){ 
     
    return (
      <p className="agbContainer" >
        Mit <b>Und los geht's</b> bestätigen Sie, dass Sie unsere <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_Igf2SQq0zE' id='datenschutz'  rel="noopener noreferrer">Datenschutzerklärung</a> und <a target='_blank' href='https://edp.makrolog.de/edpmcm/mcm/_0JrTXgcudm' id='agb' rel="noopener noreferrer">Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben.
      </p>
    )
  }
}

export default AGB
 */



import React, { Component } from 'react'
import './css/AGB.css'
import attention from '../../common/svg/attention.svg'


class AGB extends Component {
  render(){
     
    return (
      <p className="agbContainer" >
        <img id='img' src={attention} alt='!'/>
        <span id='txt'>
        Mit <b>Speichern</b> bestätigen Sie, dass Sie unsere <a href='https://www.dig-sie.de/Impressum-und-AGB/Datenschutz/' id='datenschutz' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a> und <a href='https://www.dig-sie.de/.cm4all/uproc.php/0/PDF-Dateien/2019-07-01_AGB%20der%20dig-Sie.de%20UG.pdf?_=17117107a00&cdp=a' id='agb' target='_blank' rel='noopener noreferrer'>Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben.
        </span>
      </p>
    )
  }
}

export default AGB

