
/**
 * Hilfsklasse für server logs
 */

export default class Server
{
    init( app_instance )
    {
        this.app_instance = app_instance
        this.error_url = '/errlor'
    }

    log( str )
    {
        if ( this.app_instance )
        {
            if ( !arguments.length )
                return
    
            let outstr = ''
    
            if ( arguments.length === 1 )
            {
                outstr = str
            } else
            {
                let substitution
                
                /* 
                    check for substitution strings
                */
                if ( typeof str === 'string' )
                {
                    outstr = str
                    substitution = str.match( /%o|%i|%s|%f/ )
                }else{
                    outstr = JSON.stringify( str )
                }
    
                /* 
                    merge arguments into string
                */
                for ( let i = 1; i < arguments.length; i++ )
                {
                    if ( substitution && substitution[ i-1 ] )
                    {

                        /* 
                            replace %x
                        */
                        let subi = substitution[ i-1 ]
                        outstr = outstr.replace( subi, arguments[ i ] )
    
                    } else
                    {
                        /* 
                            concat inputs
                        */
                        outstr += ' '

                        if( typeof arguments[ i ] === 'object' )
                            outstr += JSON.stringify( arguments[ i ] )
                        else
                            outstr += arguments[ i ]
                    }
                }
            }

            /* 
                post error message to server
            */
            this.app_instance.post( '/error', {
                code: this.app_instance.pid,
                error: outstr
            })
        }
    }
}