import React, { Component } from 'react'
import './css/Startpage.css'
import AGB from './AGB_en'
import cover from './svg/cdap_start2.svg'
import CDAPHeader from './CDAPHeader'

class Startpage extends Component {
  render(){

    let {app} = this.props
    let showLoginAgain = app.getCookie('showLoginAgain_'+app.pid) === 'true'
    let logoutMessage = window.getURLParameter('logoutmessage')

    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          {
            <CDAPHeader lang='en'/>
          }
          <p>
            <b>Corona Presence</b> logs your presence at an event or venue in compliance with data protection regulations. 

          </p>

          {
            app.image_startpage ? app.image_startpage : <img src={cover} id='cover' alt='cover'></img>
          }
          

          {
            logoutMessage ? 
              <p style={{fontSize:'var(--normal)', marginBottom: 0}}>
                { logoutMessage }
              </p>
            :
            ''
          }

          <p style={{fontSize:'var(--normal)', marginBottom: 0}}>
            { showLoginAgain ? '' : ''} This list is for <br/><b>{app.displayTitle}</b>
          </p>
          

          {this.props.menu}
          
          { app.mode.INTRO ? 
              <div>
                <div id='aigo' className='button'  onClick={() =>{
                  let goto = window.getURLParameter('goto')
                  window.location.href = goto ? goto : this.props.goto }
                } >{"Und los geht's"}</div>
              </div>
              :
              ''
          }
            <AGB/>


        </div>
      </div>
    )
  }
}

export default Startpage
