import React, { Component } from 'react'

const BASEDOMAIN = window.location.hostname.split('.')[0]
const LOCALDEV = BASEDOMAIN.includes('192') || BASEDOMAIN.includes('localhost')
const UQRC_DEPLOYMENT = BASEDOMAIN.includes('uqrc')

const FORCE_RC = window.getURLParameter('forcerc') === 'true'
const RC = UQRC_DEPLOYMENT || LOCALDEV || FORCE_RC ? '&rc' : ''



class Menu extends Component {

    render() {
        let { url, query, app } = this.props
        let { VB, WD, CDAP } = app.type
        let { PROTOCOL, PREREG } = app.mode
        let lang = app.lang

        let { href } = window.location
        let wd = () => href.refresh('http://www.whatsdown.com')
        let neu = () => href.append('mode=checklist').refresh()
        let neucdap = () => href.append({ mode: 'checklist' }).refresh()
        let login = () => url.VB_FBAUTH.append(query, RC).refresh()
        let dfvb = () => url.VB_FBAUTH.append(query, RC).refresh()
        // let preregfn = () => href.append({ mode: 'checklist' }).refresh()
        // let dfcdap = function () { window.location.href = url.CDAP_FBAUTH + query + RC }

        let eintragen = 'Eintragen'
        if( app.confirmbuttons[ window.STARTPAGE ].length )
            eintragen = 'Mit Corona-Presence einchecken'

        let msg_pid = app.getCookie( 'message_parent_id_' + app.pid )
        if( msg_pid.includes('PRE:') )
            eintragen = 'Vorregistrierung einlösen'

        let wdBtn = WD ? <div className='introButton button' style={{ backgroundColor: '#ff7500' }} onClick={wd}><b>WhatsDown</b>-Störungsmelder öffnen</div> : null
        let logBtn = WD ? <div className='introButton button' style={{ backgroundColor: '#00ab63' }} onClick={login}><b>Arbeitsplatz</b>-Checkliste durchführen</div> : null
        let neuBtn = VB ? <div className='introButton button' style={{ backgroundColor: 'rgb(49, 127, 67)' }} onClick={neu}><b>Neuer Eintrag</b> in das Verbandsbuch <br /> <b>{app.displayTitle}</b></div> : null
        let neucdapBtn = CDAP ? <div className='introButton button' id='intro_eintragen_button' style={{ backgroundColor: 'rgb(0, 143, 255)' }} onClick={neucdap}> {
            eintragen
            } <br /> </div> : null
        let dfvbBtn = VB ? <div className='introButton button' onClick={dfvb}><b>Verbandsbucheinträge</b> ansehen</div> : null
        let dfcdapBtn = CDAP ? <a className='userLogin' href={url.CDAP_FBAUTH.append(query, RC)}>Login für Besitzer der Anwesenheitsliste</a> : null
        let novbBtn = VB ? <div className='introButton button' style={{ backgroundColor: '#bdbdbd' }}>Noch keine <b>Verbandsbucheinträge</b> vorhanden</div> : null
        let nocdapBtn = CDAP ? <a className='userLogin' href={url.CDAP_FBAUTH.append(query, RC)}>Login für Besitzer der Anwesenheitsliste</a> : null

        if (lang === 'EN') {
            dfcdapBtn = CDAP ? <a className='userLogin' href={url.CDAP_FBAUTH.append(query, RC)}>Login for the owner of the list</a> : null
            nocdapBtn = CDAP ? <a className='userLogin' href={url.CDAP_FBAUTH.append(query, RC)}>Login for the owner of the list</a> : null
            neucdapBtn = CDAP ? <div className='introButton button' style={{ backgroundColor: 'rgb(0, 143, 255)', opacity: 0 }} onClick={neucdap}> {/* in <b>{app.displayTitle}</b> */} Checkin<br /> </div> : null
        }


        let post_refuse = app.post_refuse_text || "Ein erneuter Checkin ist daher zur Zeit nicht möglich."
        if( app.login_refuse() )
            return <div>
                <div id='too_many_logins'>ist noch nicht lange genug her.<br/><br/><br/><span dangerouslySetInnerHTML={{ __html: post_refuse }} ></span></div>
                    <div id={'weitere_personen'} className='introButton button' style={{ color: 'rgb(0, 143, 255)', backgroundColor: 'transparent' }} onClick={() => {
                        window.location.href.append({mode:'checklist',fromcookie:'ignore'}).refresh()
                    }
                    }> Weitere Personen einchecken <br /> 
                </div>
            </div>
            
            


        return (
            PREREG ?
                ''
                :
                <div>
                    {wdBtn}
                    {neuBtn}
                    {neucdapBtn}
                    {logBtn}
                    {PROTOCOL ? dfvbBtn : novbBtn}
                    {PROTOCOL ? dfcdapBtn : nocdapBtn}
                </div>
        )
    }
}

export default Menu
