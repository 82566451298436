import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import LocalDevIcon from './LocalDevIcon'

class Error extends Component {

    render() {
       let { app, meta } = this.props

        return (
            <DocumentMeta {...meta} >
                <LocalDevIcon />
                <div key={0} className='red' onLoad={app.hideLoader()}>Failure: Missing QR Code ID<br /> URL incomplete </div>
            </DocumentMeta>
        )
    }
}

export default Error
