import React, { Component } from 'react'
import Note from './Note'

class Input extends Component {
  constructor (props, context) {
    super(props, context)

    this.setNoteRef = (i, t) => {
      this.qrCodeNoteRef = t
    }
  
    this.qrCodeNoteElem = <Note content='Bitte QR-Code scannen um Rundgang zu starten!' setNoteRef={this.setNoteRef}/>
  }
 
  componentDidMount(){
    if( this.props.id === 'deviceName' )
      this.refs.devNameInput.focus()
  }

  render() {

    let value = this.props.value
    let type  = this.props.type
    let id    = this.props.id
    let name  = this.props.name

    let lcss   = {color: '#b8b8b8'}
    let incss  = {color: '#535353', borderBottom: 'none'}

    let border = value ? '' : <span className='border'></span>
    let input  = value || this.props.disabled ? 
                <input 
                  type={ type } 
                  id={ id } 
                  placeholder='&nbsp;' 
                  value={ value } 
                  style={ incss } 
                  readOnly='readonly' 
                  onFocus={ () => {if( this.props.disabled ) this.qrCodeNoteRef.show()} } />
                :
                <input 
                  type={ type } 
                  id={ id } 
                  placeholder='&nbsp;'  
                  onBlur={ this.props.saveCurrent } 
                  onKeyUp={ this.props.onKeyUp } 
                  onFocus={ () => {if( this.props.disabled ) this.qrCodeNoteRef.show()} }/>

    if( this.props.id === 'deviceName' ) // WD Setup
      input = <input 
        type={ type } 
        id={ id } 
        placeholder='&nbsp;'
        ref='devNameInput' 
        onBlur={ this.props.saveCurrent } 
        onKeyUp={ this.props.onKeyUp }/>

    return (
      <div>
        <div style={{ opacity: this.props.disabled ? 0.25 : 1.0 }} >
          <label htmlFor={ id } className='inp'>
            {input}
            <span className='label' style={ lcss }>{ name }</span>
            {border}
          </label>
        </div>
        {this.qrCodeNoteElem}
      </div>

        
      )
  }
}  

export default Input


