import React, { Component } from 'react'
import './css/Startpage.css'
import AGB from '../../common/AGB'

class Startpage extends Component {
  render(){
    let {app} = this.props
    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          
          <p id='big'>
            <b>WhatsDown</b> - einfach - flexibel und immer dabei!
          </p>
          {
            app.image_startpage
          }
          <p>
            Willkommen auf der Startseite von <b>WhatsDown</b>.
          </p>
          <p>
            Mit <b>WhatsDown</b> kann man seine wichtigsten Informationen über den QR-Code immer abrufbar und griffbereit halten.
          </p>
          <p>
            Eine gute Entscheidung, denn einfach ist besser.
          </p>
          

          {!this.props.menu ? 
            (<div>
              <AGB/>
              <div id='aigo' className='button'  onClick={() =>{
                let goto = window.getURLParameter('goto')
                window.location.href = goto ? goto : this.props.goto }
              } >{"Und los geht's"}</div>
            </div>)
            :
            this.props.menu
          }


          
          <p id='kontakt'>
            <a id="ailink" href="http://dig-sie.de/KONTAKT/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Kontakt
              </span>
            </a><br/>
            <a id="ailink" href="http://dig-sie.de/Impressum-und-AGB/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Impressum
              </span>
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default Startpage
