module.exports = `# Allgemeine Geschäftsbedingungen für Corona-Assist (Stand: 01.06.2021)


## 0.Grundsätzliches


Mithilfe des Dienstes können Informationen und Inhalte über die Anwesenheit von Teilnehmern/(Gästen erfasst und gespeichert werden.

### 1\. Geltung der AGB, Veränderungen der Plattform


1.1. Diese AGB gelten für jede Form der Nutzung des Dienstes. Sie gelten in gleicher Weise für den reinen Abruf von Informationen sowie für das Einstellen von Informationen, unabhängig von Zahl und Menge der eingestellten Informationen und unabhängig von der unter 1.2. aufgeführten Art der Nutzung.

1.2. Erlaubte Nutzungen sind:

(1) als (registrierter) untenentgeltlicher Nutzer, der eine Anwensenheitsliste führen möchte,

(2) als untentgeltlicher Nutzer, der sich in eine von einem Betreiber geführte Anwesenheitsliste eintragen möchte.

1.3. Der Makrolog AG (im folgenden Betreiber) steht es frei, den Dienst und die Funktionen des Dienstes jederzeit zu verändern. Die Nutzer können aus einer Veränderung des Dienstes keine Rechte gegen den Betreiber herleiten. Die Nutzer haben insbesondere keinen Vertrauensschutz hinsichtlich eines bestimmten jeweiligen technischen und/oder inhaltlichen Zustandes des Dienstes.

1.4. Der Betreiber behält sich vor, die vorliegenden Bedingungen jederzeit zu ändern oder zu ergänzen. Änderungen der Bedingungen werden den Nutzern vier Wochen vor Inkrafttreten mitgeteilt bzw. für anonyme Nutzer innerhalb dieser Frist aktuell auf der Website [https://www.Corona-Presence.com](https://www.corona-presence.com/) zum Abruf bereitgestellt. Hierzu ist statt der Beifügung des Volltextes ein Verweis auf die Adresse im Internet, unter der die aktuelle Fassung abrufbar ist, hinreichend. Wird den Änderungen nicht binnen eines Monats ab Bekanntgabe widersprochen, so gelten diese als angenommen. Erfolgen die Änderungen zu Ungunsten der Nutzer, so können diese das Vertragsverhältnis innerhalb eines Monats nach Zugang der Änderungsmitteilung fristlos kündigen.

### 2\. Registrierung

2.1. Für bestimmte Nutzungsarten des Dienstes ist eine vorherige Registrierung des Nutzers (z.B. durch Angabe seiner Telefonnummer) erforderlich.

2.2. Der Nutzer sichert zu, dass sämtliche bei der Registrierung gemachten Angaben zutreffend und wahr sind.

2.3. Eine evt. bei der Registrierung gewählte sichtbare Kennung darf nicht gegen Namens- und Markenrechte verstoßen und darf nicht gegen Gesetze oder Rechtsvorschriften verstoßen.

2.4. Der Nutzer muss das gewählte Passwort (und ihm ggf. zugesandte Bestätigungscodes) geheim halten und darf diese nicht an weitere Nutzer oder an weitere Personen seines Unternehmens oder an Dritte weitergeben.

2.5. Der Anbieter behält sich nach erfolgter Registrierung des Nutzers die Entscheidung über die dauerhafte Freischaltung vor. Die Freischaltung soll nur aufgrund berechtigter Interessen des Anbieters oder aufgrund bestehender sicherheitstechnischer Bedenken verweigert werden. Die Registrierung darf vom Anbieter widerrufen werden, wenn eine missbräuchliche oder gegen diese AGB verstoßende Nutzung des Dienstes festgestellt wird.

### 3\. Beschreibung des Angebots der Dienste

3.1. Die Teilnahme an dem Dienst ermöglicht im Rahmen der oben unter 1.2 dargestellten Nutzungsformen die Nutzung der angebotenen Funktionen. Diese Nutzung beinhaltet zunächst die Nutzung zu eigenen Zwecken.

3.2. Der Betreiber stellt den Nutzern die Dienste zur Verfügung, ist inhaltlich aber nicht für die eingestellten Dateien, Inhalte und Informationen verantwortlich und unterzieht diese grundsätzlich keiner Prüfung vor dem Einstellen. Der Betreiber verwertet die eingestellten Dateien, Inhalte oder Informationen auch nicht wirtschaftlich. Die Nutzer haben dafür Sorge zu tragen, nur rechtlich zulässige und nicht rechtsverletzende Dateien, Inhalte und Informationen einzustellen. Erhält der Betreiber von einem rechtsverletzenden/rechtswidrigen Inhalt Kenntnis, wird er diesen unverzüglich entfernen und sperren. Die Dienste bieten eine Möglichkeit, den Betreiber über Rechtsverstöße in Kenntnis zu setzen.

3.3. Der Betreiber kann für einzelne Inhalte technisch eine Funktion für die Übermittlung von Nutzermitteilungen (Kommentare, Meldungen, Notizen, Logeinträge, Protokolleinträge etc.) anbieten. Der Betreiber verwertet die eingestellten Mitteilungen jedoch nicht wirtschaftlich. Für den Inhalt etwaiger Mitteilungen sind alleine die Nutzer verantwortlich; der Betreiber prüft die Mitteilungen grundsätzlich nicht. Erhält der Betreiber von einer rechtsverletzenden/rechtswidrigen Mitteilung Kenntnis, wird er diese unverzüglich entfernen und sperren.

### 4\. Verfügbarkeit der Dienste

Die Verfügbarkeit des Dienstes ist abhängig von der Verfügbarkeit eines Internetzugangs. Insoweit kann der Plattformbetreiber nicht auf die Netzverfügbarkeiten seitens der verschiedenen Netzanbieter Einfluss nehmen. Mit anderen Worten: funktioniert das Internet nicht, kann der Dienst nicht genutzt werden.

Im Falle von höherer Gewalt, Stromausfall und Ausfall von Kommunikationsverbindungen kann der Betreiber die Verfügbarkeit des Dienstes nicht sicherstellen.

Der Dienst ist grundsätzlich rund um die Uhr verfügbar. Gleichwohl kann der Betreiber aus technischen Gründen keine Gewähr dafür übernehmen, dass das Angebot zu bestimmten Zeiten zur Verfügung steht. Insbesondere übernimmt der Betreiber daher keine Gewähr im Falle von Störungen, Unterbrechungen oder eines etwaigen Ausfalls des Dienstes.

### 4.a. Besondere zusätzliche Regelungen für im Aufbau befindliche Plattformen („Beta-Versionen&#34;)

Der Betreiber entwickelt den Dienst ständig weiter und baut ggf. neue Varianten des Dienstes auf. Solche im Aufbau befindliche Varianten haben ggf. vorgeschaltete Testphasen, in denen keine Gewähr für das Funktionieren des Dienstes bzw. keine Gewähr für das Erreichen bestimmter gewünschter Zwecke mit ihnen übernommen werden kann. Der Betreiber kennzeichnet diese Dienste in der Regel durch den Zusatz „Beta-Version&#34; oder vergleichbare Zusätze. Diese oben beschriebenen Dienste können von dem Betreiber zu jeder Zeit ohne Angabe von Gründen eingestellt und beendet werden, ohne dass den Nutzern hieraus irgendwelche Rechte gegen den Betreiber erwachsen.

### 5\. Gewährleistung

Die Gewährleistung des Betreibers bestimmt sich nach den gesetzlichen Vorschriften, sofern nachfolgend nichts Abweichendes bestimmt wird.

Der Betreiber weist darauf hin, dass keine Gewähr dafür übernommen wird, dass die Inhalte des Dienstes den Erwartungen des Nutzers entsprechen oder mit dem Angebot ein bestimmtes, vom Nutzer verfolgtes Ziel erreicht wird.

Im Übrigen gelten die sich aus Punkt 0. bis 2. dieser AGB ergebenden Einschränkungen und besonderen Hinweise.

Sofern physikalische Objekte aus der realen Welt z.B. über QR-Codes mit den Diensten verbunden werden, wird keine Gewährleistung dafür übernommen, dass der mit dem QR-Code ggf. verfolgte oder gewünschte Zweck erreicht wird. Der Plattformbetreiber hat auch nicht dafür einzustehen, dass der QR-Code ggf. zu anderen, nicht vertraglich vorgesehenen Zwecken eingesetzt wird. Der Plattformbetreiber übernimmt auch keine Gewährleistung hinsichtlich des physikalischen Objektes, das der Nutzer im Rahmen des Dienstes gebraucht.

Der Nutzer darf seine Berechtigung zur Nutzung der Dienste nicht ohne Zustimmung des Betreibers auf einen Dritten übertragen. Dies gilt auch für den Fall, dass er den physikalischen Gegenstand, auf den sich der Dienst bezieht, an einen Dritten verkauft, verleiht, verschenkt oder sonst wie weitergibt. Der Nutzer ist dafür verantwortlich, dass mit dem Gegenstand ggf. verknüpfte Daten oder Rechte gelöscht bzw. beendet werden. Der Nutzer ist verpflichtet den Dritten, dem er den Gegenstand weitergibt, über sämtliche aktivierten und deaktivierten Dienste in Kenntnis setzen.

Im Falle der Weitergabe des Gegenstandes an einen Dritten hat der Nutzer über die Regelungen in Nr. 13 dieser Bedingungen hinaus das Recht den Vertrag mit einer Frist von 6 Wochen zu kündigen. Kündigt der Nutzer vor Ende der ursprünglichen Laufzeit des Vertrages, wird ihm der dafür anteilig entrichtete Preis nicht erstattet.

### 6\. Laufzeit des Angebots

6.1. Die mit dem Dienst verbundenen Leistungen werden vom Betreiber zunächst unbefristet angeboten. Sie können jederzeit ohne Begründung mit einer Ankündigungsfrist von 6 Monaten zum Monatsende eingestellt werden. Der Betreiber wird die Nutzer rechtzeitig vor Einstellung des Dienstes per E-Mail benachrichtigen bzw. über den Zeitpunkt der Einstellung auch auf der jeweiligen Plattform informieren. Es obliegt dem Benutzer sicherzustellen, dass die zum Registrierungszeitpunkt angegebene E-Mail-Adresse bzw. Telefonnummer per SMS erreichbar ist bzw. dass dem Betreiber eine andere erreichbare E-Mail-Adresse mitgeteilt wird.

6.2. Auf Punkt 4.a. dieser Bedingungen wird verwiesen.

### 7\. Widerrrufsbelehrung

Verbrauchern steht bei Fernabsatzverträgen ein Widerrufsrecht gemäß § 355 zu. Fernabsatzverträge sind Verträge, bei denen der Unternehmer und der Verbraucher für die Vertragsverhandlungen und den Vertragsschluss ausschließlich Fernkommunikationsmittel verwenden, es sei denn, dass der Vertragsschluss nicht im Rahmen eines für den Fernabsatz organisierten Vertriebs- oder Dienstleistungssystems erfolgt. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.

Widerrufsrecht

Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.

Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Makrolog AG, Patrickstraße 43, 65191 Wiesbaden, Tel.: 0611-95782-0, Fax: 0611-95782-28, E-Mail: zentrale&#64;makrolog.de) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das unter  abrufbare Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.

Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

Folgen des Widerrufs

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.

Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.

### 8\. Pflichten der Nutzer

8.1. Der Nutzer darf keine Daten, Informationen oder Inhalte auf der Plattform bereitstellen, die gegen gesetzliche Vorschriften verstoßen oder die gesetzlich z.B. durch das Urheber-, Marken-, Patent- oder Gebrauchsmusterrecht geschützt sind. Er darf die bereitgestellten Informationen nicht zu rechtswidrigen oder gesetzwidrigen Zwecken abrufen oder nutzen und darf die so gewonnenen Informationen nicht an Dritte weitergeben.

8.2. Der Nutzer darf den Dienst nicht dazu nutzen, unerlaubte Werbung zu versenden oder andere im Sinne des § 7 UWG durch Zusendungen unzumutbar zu belästigen. Der Nutzer darf den Dienst allgemein nicht zur Vornahme wettbewerbswidriger Handlungen oder unerlaubter Werbemaßnahmen nutzen.

8.3. Der Dienst darf nicht als Ersatz für Notruf- und Notdienste verstanden werden und darf nicht als Ersatz für derartige Notdienste eingesetzt werden. Unser Dienst stellt keine Verbindung zu Notfallrettern dar und darf nicht für medizinische Zwecke eingesetzt werden. Im Falle eines derartigen Einsatzes entgegen dieser Bestimmung lehnt der Betreiber des Dienstes jegliche Haftung und Verantwortung ab.

8.4. Der Nutzer muss - unabhängig von etwaigen diesbezüglichen Maßnahmen des Betreibers - durch eigene angemessene Vorkehrungen sicherstellen, dass keine infizierten, virenverseuchten oder sonst sicherheitsgefährdende Dateien oder Inhalte auf die Plattformen oder Systeme des Betreibers gelangen.

8.5. Der Nutzer ist zu einer rechtskonformen Nutzung verpflichtet. Bei einer nicht rechtskonformen oder einer gegen diese AGB verstoßenden Nutzung können der weitere Zugang zu dem Dienst oder der weitere Zugang zu Inhalten / Informationen gesperrt werden.8.6. Der Nutzer ist zu einem Backup etwaiger hochgeladener Inhalte und Dateien auf einem eigenen Speichermedium außerhalb der Plattform des Anbieters verpflichtet. Insoweit wird auf Punkt 3.3 dieser AGB verwiesen.

### 9\. Rechte der Nutzer

9.1 Die Nutzer haben das Recht die Plattform im Rahmen der vorliegenden AGB zu nutzen, insbesondere auch Kommentare zu Inhalten / Informationen abzugeben, soweit die Systeme/Plattform des Betreibers diese Möglichkeit vorsehen.

9.2. Dem Anbieter und den Nutzern ist bewusst, dass eine 100%ige Verfügbarkeit der Dienste nicht gewährleistet werden kann. Der Betreiber bemüht sich, die Dienste möglichst unterbrechungsfrei verfügbar zu halten. Wartungsarbeiten, Sicherheitsbelange, Netzauslastung, Kapazitätsgründe oder vom Betreiber nicht zu beeinflussende Ereignisse können aber dazu führen, dass die Verfügbarkeit der Dienste kurzzeitig gestört ist oder der Betrieb der Dienste vorübergehend eingestellt wird.

### 10\. Sperrung von Inhalten

10.1. Der Betreiber ist für die eingestellten Inhalte nicht verantwortlich und führt auch keine Vorabprüfung dieser Inhalte durch. Sofern der Betreiber von rechtswidrigen Inhalten Kenntnis erlangt, wird er unverzüglich den Zugang zu diesen Inhalten sperren bzw. die rechtswidrigen Inhalte entfernen.

10.2. Der Dienst sieht ggf. eine Möglichkeit vor, den Betreiber über festgestellte rechtswidrige oder rechtsverletzende Inhalte zu informieren.

### 11\. Rechteeinräumung

11.1. Die Nutzer räumen dem Betreiber Nutzungsrechte nur in dem Umfang ein, wie dies zu dem vorgesehenen Zweck, d.h. zum Betrieb des Dienstes bzw. zum Betrieb der einzelnen Funktionen des Dienstes erforderlich ist.

11.2. Sofern im Rahmen der Nutzung das Einstellen von Informationen zulässig ist, ist das einstellende Unternehmen damit einverstanden, dass die nach § 5 TMG erforderlichen Daten des Unternehmens zum Zwecke der Erfüllung der Impressumspflicht aus § 5 TMG übermittelt und verwendet werden.

11.3. Im Rahmen der unentgeltlichen Nutzung erklärt sich der Benutzer damit einverstanden vom Betreiber zu Marketingzwecken namentlich genannt zu werden (z. B. durch Aufnahme in Referenzlisten oder durch den Hinweis auf öffentlich zugängliche Profile des Nutzers auf Internet-Platfformen wie z. B. Facebook, Instagram,. XING, Linkedin, etc.) 

### 12\. Haftung

12.1. Der Betreiber übernimmt keinerlei Verantwortung dafür, dass die mit der Einstellung der Inhalte oder mit dem Abruf von Informationen verfolgten Zwecke oder Absichten erfüllt werden können oder tatsächlich erfüllt werden, insbesondere nicht die Gewähr, dass die Datenerfassung den gesetzlichen Vorgaben des jeweiligen Nutzers, die sich z. B. aus den Corona-Verordnungen ergeben, genügt. Der Benutzer hat sich hierüber im Einzelfall unter genauer Prüfung der Funktionalität der Plattform in Verbindung mit den für ihn geltenden gesetzlichen Vorgaben selbst zu überzeugen.

12.2. Der Betreiber haftet, gleich aus welchem Rechtsgrund, für Sach- und Vermögensschäden nur, wenn der Betreiber oder seine Erfüllungsgehilfen durch Vorsatz, grobe Fahrlässigkeit oder fahrlässige eine Verletzung vertragswesentlicher Pflichten (Kardinalpflichten) verursacht haben. Im Falle einer fahrlässigen Pflichtverletzung des Plattformbetreibers ist die Haftung für Sach- und Vermögensschäden auf den vorhersehbaren, vertragstypischen, unmittelbaren Durchschnittsschaden begrenzt. Die Haftung nach dem Produkthaftungsgesetz für zugesicherte Eigenschaften und Garantien bleibt hiervon unberührt. Für zurechenbare Schäden aufgrund einer Verletzung von Leben, Körper und Gesundheit haftet der Betreiber unbeschränkt.

12.3. Im Falle einer unentgeltlichen Bereitstellung des Dienstes hat der Betreiber nur Vorsatz und grobe Fahrlässigkeit zu vertreten.

12.4. Sofern physikalische Objekte aus der realen Welt z.B. über QR-Codes mit der Plattform verbunden werden, haftet der Betreiber nicht dafür, dass der mit dem QR-Code ggf. verfolgte oder gewünschte Zweck erreicht wird. Der Plattformbetreiber haftet auch nicht, sofern der QR-Code ggf. zu anderen, nicht vertraglich vorgesehenen Zwecken eingesetzt wird. Der Betreiber übernimmt auch keine Haftung hinsichtlich des physikalischen Objektes, das der Nutzer im Rahmen des Dienstes gebraucht.

12.5. Der Dienst darf nicht als Ersatz für Notruf- und Notdienste verstanden werden und dürfen nicht als Ersatz für derartige Notdienste eingesetzt werden. Unser Dienst stellt keine Verbindung zu Notfallrettern dar und darf nicht für medizinische Zwecke eingesetzt werden. Im Falle eines derartigen Einsatzes entgegen dieser Bestimmung lehnt der Betreiber des Dienstes jegliche Haftung und Verantwortung ab.

12.6. Die Nutzer sind für eine Sicherung ihrer Daten, Informationen und Inhalte selbst verantwortlich. Hierzu stellt der Betreiber ein Tool zur Verfügung, mit dessen Hilfe die hochgeladenen Inhalte auf eigene Speichermedien des Nutzers kopiert werden können. Der Betreiber haftet daher für einen dennoch eingetretenen Datenverlust nur bei Vorsatz oder grober Fahrlässigkeit.

12.7. Der Nutzer stellt den Betreiber von sämtlichen Ansprüchen, einschließlich Schadensersatzansprüchen, frei, die andere Nutzer oder sonstige Dritte gegen den Betreiber wegen einer von dem Nutzer zu vertretenden Verletzung ihrer Rechte durch die vom Nutzer eingestellten Inhalte/Kommentare geltend machen. Der Nutzer übernimmt alle dem Betreiber aufgrund einer Verletzung von Rechten anderer Nutzer oder Dritter entstehenden angemessenen Kosten, einschließlich der für die Rechtsverteidigung notwendigen Kosten. Weitergehende Rechte und weitere Schadensersatzansprüche des Betreibers bleiben unberührt. Dem Nutzer steht das Recht zu nachzuweisen, dass dem Betreiber tatsächlich geringere Kosten entstanden sind.

### 13\. Vertragsverhältnisse

13.1. Mit der Freischaltung des Nutzers kommt ein unentgeltlicher Nutzungsvertrag zwischen dem Betreiber und dem Nutzer zustande.

13.2. Der unentgeltliche Nutzungsvertrag berechtigt zur Teilnahme an dem Dienst und zu einer Nutzung entsprechend den Festlegungen in diesen AGB. Auf die Regelung in 1.2 wird Bezug genommen.

13.3. Der unentgeltliche Nutzungsvertrag läuft bis zum Ende des Monats, in dem die Freischaltung erfolgte, und verlängert sich um je einen weiteren Monat, sofern der Vertrag nicht spätestens 14 Tage vor Monatsende schriftlich gekündigt wird. Die Kündigung aus wichtigem Grund bleibt hiervon unberührt.

13.6. Aus wichtigem Grund können die Vertragsparteien kündigen, wenn die Fortsetzung des Vertragsverhältnisses bis zum nächsten ordentlichen Kündigungstermin unter Berücksichtigung der Umstände des Einzelfalls und unter Abwägung der beteiligten Interessen unzumutbar erscheint.

### 14\. Datenschutz

Eine Erhebung, Verarbeitung und/oder Nutzung personenbezogener Daten (Daten zu Produktlieferungen, erbrachten Dienstleistungen, Verträgen, Adressdaten, Rechnungsdaten) findet nur statt, wenn der Nutzer wirksam eingewilligt hat oder dies durch Gesetz erlaubt ist.

Die Speicherung des URL der Seite, von der aus der Kunde zu den Diensten gelangt ist, die IP-Adresse, Uhrzeit des Besuches der Dienste unterliegen einer strengen Zweckbindung. Diese Daten werden ausschließlich verwendet, um den Betrieb des Systems zu ermöglichen. Diese Daten werden routinemäßig gespeichert und innerhalb eines angemessenen Zeitraumes gelöscht. Die Daten werden nicht personenbezogen, sondern allenfalls zu statistischen Zwecken ausgewertet.

Im Übrigen wird auf die Datenschutzerklärung verwiesen.

### 15\. Anwendbares Recht

Es gilt deutsches Recht unter Ausschluss des Internationalen Privatrechts und des UN-Kaufrechts.

### 16\. Schlussklauseln

16.1. Sollten einzelne Regelungen dieser AGB unwirksam sein oder werden, wird dadurch die Wirksamkeit der übrigen Regelungen nicht berührt.

16.2. Erfüllungsort ist der Sitz des Betreibers.

16.3. Gerichtsstand für Kaufleute im Sinne des Handelsgesetzbuches (HGB) ist der Sitz des Betreibers.

16.4. Diese AGB werden ggf. auch in anderen Sprachen angeboten. Bei Auslegungsfragen bezüglich der AGB ist immer ihre deutsche Fassung maßgeblich.

### 17\. Impressum

Diensteanbieter und Ansprechpartner für alle Fragen zum Angebot ist:

Makrolog AG

Patrickstr. 43

D-65191 Wiesbaden

Tel.: 0611-95 78 20

Fax: 0611-95 78 228

Vorstand: Andreas Herberger

Vorsitzender des Aufsichtsrats: Michael Fanning

Registergericht: Amtsgericht Wiesbaden HRB 21424

Umsatzsteuer-Identifikationsnummer: DE813409840`