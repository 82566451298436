import React, { Component } from 'react'
import no from './svg/checkboxUnchecked.svg'
import yes from './svg/checkboxChecked.svg'

var server

class CheckBox extends Component {
    constructor(props, context) {
        super(props, context)
        this.fromCookieIgnore = window.getURLParameter('fromcookie') === 'ignore'
        this.state = {
            checked: this.props.init && !this.fromCookieIgnore
        }
    }

    componentDidMount() {
        server = window.server
        
        let self = this
        
        try {
            self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
        } catch (error) {
            let trySetData = setInterval(function(){

                if(self && self.refs && self.refs.thisElement ){
                    self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
                    clearInterval(trySetData)
                }
            }, 250)
        }
    }

    render() {
        let { section } = this.props
        let classname = 'CheckBoxContainer ' + section

        let { text, callback } = this.props
        let checked = this.state.checked

        return this.fromCookieIgnore ? '' : (
            <div className={classname} onClick={() => { callback(!checked); this.setState({ checked: !checked }) }} ref='thisElement'>
                <img className='CheckBox' src={checked ? yes : no} alt='checkbox' />
                <span className='CheckBoxText' >{text}</span>
            </div >
        )
    }
}

export default CheckBox
