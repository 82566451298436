import React, { Component } from 'react'


class Back extends Component
{
    constructor(props, context) {
        super(props, context)

        this.state = {
        }
    }

    render()
    {
        let { e, app } = this.props
        let { icon, title, css } = e

        // return <div className='back' style={ css } onClick={ () => app.popLastElementClassFromSelectorHistory() }>{ title }</div>

        return <div className='back' style={ css } onClick={ () => app.popLastElementClassFromSelectorHistory() } style={  css  }>
            <table>
                <tr>
                    <td style={{width: "0%"}}>
                        <img src={icon} style={{height: "3rem", verticalAlign: "middle"/* , opacity: "0.5" */}}/>
                    </td>
                    <td className='wordwrap'>
                        <div style={{textAlign: "left", padding: "0 1rem"}} >{title}</div>
                    </td>
                </tr>
            </table>
        </div>
    }
}

export default Back


