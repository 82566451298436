import React, { Component } from 'react'
import Loader from './Loader'


class PhotoDisplay extends Component {
    constructor(props, context) {
      super(props, context)

      this.createFileSource = ( file, host ) => {
        if( file ){
            if( file.indexOf('http') === 0 ){
                return file
            }else{
                return host + '/img?f=' + file + '&cache=' + window.getURLParameter('cache' )
            }
        }else{
            return ''
        }
      }

      this.app = this.props.app
      this.type = this.app.type
      this.mode = this.app.mode
      this.service = this.type.SERVICE
      this.name = this.mode.VIEW ? this.props.e.post : (this.props.e.name || this.props.e.post)
      this.file = this.createFileSource( this.props.e.file, this.app.helper ) 

      this.state = {
          zoom: false,
          collapsed: false
      }

      

      this.toggleCollapse = () => {
        this.setState({collapsed: !this.state.collapsed} )
      }

      this.toggleZoom = () => {
        this.setState({zoom: !this.state.zoom} )
      }
    }


    render() {
        let zoomPhoto = this.state.zoom ? { /* width: '93vw' */ } : {}
        let maxHeight = this.state.collapsed ? { maxHeight: '0' } : { maxHeight: '100vh' }
        let margin    = this.state.collapsed ? { marginBottom: '0', transitionDelay: '.4s' } : { marginBottom: '20px', transitionDelay: '.0s' }
        let row_flex = this.mode.VIEW ? { display: 'block' } : {}

        return (
            <div className="checkListItemRow" style={row_flex} data-service={this.service} >
              <div className="photoName" onClick={ ()=>this.toggleCollapse() } style={margin} >
                {this.name || ''}
              </div>
              <Loader ref='loader'/>
              <div className='photoViewContainer' style={maxHeight}>
                <img 
                    alt='Bild von mir'
                    src={this.file} 
                    className='photoView'
                    style={ zoomPhoto }
                    onLoad={ () => this.refs.loader.hide() }  
                    onClick={ () => this.toggleZoom() }
                 /> 
              </div>
            </div>
        )
    }
}

export default PhotoDisplay


