import React, { Component } from 'react'
import './css/AGB.css'
import attention from './svg/attention.svg'


class AGB extends Component {
  render(){
     
    return (
      <p className="agbContainer" >
        <img id='img' src={attention} alt='!'/>
        <span id='txt'>
        Mit <b>{'Speichern'}</b> bestätigen Sie, dass Sie unsere <a href='https://www.whatsdown.com/web/dsgvo' id='datenschutz' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a> und <a href='https://edp.makrolog.de/edpmcm/mcm/show?id=493527' id='agb' target='_blank' rel='noopener noreferrer'>Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben.
        </span>
      </p>
    )
  }
}

export default AGB
